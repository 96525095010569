import React from "react";
import { Navigate } from "react-router-dom";

import ForgotPassword from "../pages/auth/forgot-password";
import NewMember from "../pages/auth/new-member";
import NewGuest from "../pages/auth/new-guest";
import AuthLogin from "../pages/auth/login";

import Dashboard from "../pages/others/dashboard";
import Perfil from "../pages/others/perfil";
import ClassesView from "../pages/others/classes/view";
import PerfilView from "../pages/others/perfil/view"

import AdminPlans from "../pages/admin/plans";
import AdminBranches from "../pages/admin/branches";
import AdminMembers from "../pages/admin/members/members";
import AdminUnits from "../pages/admin/units";
import AdminUsers from "../pages/admin/users";
import AdminLevels from "../pages/admin/levels";
import AdminMembersApprovals from "../pages/admin/members/approvals";

import Events from "../pages/events";

import MarketingSponsors from "../pages/marketing/sponsors";
import MarketingAdverts from "../pages/marketing/adverts";
import MarketingClasses from "../pages/marketing/classes";
import MarketingArticles from "../pages/marketing/articles";

import Logout from "../pages/auth/logout";
import EditPassword from "../pages/members/edit-password"

import MembersSearch from "../pages/others/members";
import MembersScheduleMeetingsList from "../pages/others/members/schedule-meetingsa-list";
import MembersScoresLaunchList from "../pages/others/members/scores-launch";
import MembersRulesScoresList from "../pages/others/members/rules-scores";
import MembersInvitationsList from "../pages/others/members/invitations";
import MembersPayments from "../pages/others/members/payments";
import MembersLeads from "../pages/others/members/leads";

import MembersScoresList from "../pages/others/members/scores";
import MembersIndications from "../pages/others/members/indications";
import MembersBusiness from "../pages/others/members/business";

import AllPoints from "../pages/others/points";

import AllEvents from "../pages/others/events";
import AllEventsView from "../pages/others/events/view";

const authProtectedRoutes = [

  // membros

  { path: "/members/search", exact: true, component: <MembersSearch/> }, 
  { path: "/members/schedule-meetings", exact: true, component: <MembersScheduleMeetingsList/> }, // membros list de agendamento reunioes
  { path: "/members/register-guests", exact: true, component: <MembersInvitationsList/> }, // membros cadastrar convidados
  { path: "/members/scores", exact: true, component: <MembersScoresList/> }, // membros lançar pontuacao 
  { path: "/members/business", exact: true, component: <MembersBusiness/> }, 
  { path: "/members/indications", exact: true, component: <MembersIndications/> }, 
  { path: "/members/payments", exact: true, component: <MembersPayments/> }, // membros pagamentos
  { path: "/members/rules-scores", exact: true, component: <MembersRulesScoresList/> }, // membros regras pontuacao

  { path: "/events", exact: true, component: <Events/> }, // encontros
  
  // admin

  { path: "/admin/configs/branches", exact: true, component: <AdminBranches/> }, // ramos
  { path: "/admin/configs/plans", exact: true, component: <AdminPlans/> }, // planos
  { path: "/admin/configs/users", exact: true, component: <AdminUsers/> },
  { path: "/admin/configs/levels", exact: true, component: <AdminLevels/> },

  { path: "/admin/marketing/sponsors", exact: true, component: <MarketingSponsors/> }, // patrocinadores  
  { path: "/admin/marketing/adverts", exact: true, component: <MarketingAdverts/> }, // anuncios
  { path: "/admin/marketing/classes", exact: true, component: <MarketingClasses/> }, // aulas
  { path: "/admin/marketing/articles", exact: true, component: <MarketingArticles/> }, // artigos

  { path: "/admin/units", exact: true, component: <AdminUnits/> }, // unidades
  { path: "/admin/events", exact: true, component: <Events/> }, // encontros

  { path: "/admin/members", exact: true, component: <AdminMembers/> }, // membros 
  { path: "/admin/members/consults", exact: true, component: <AdminMembers/> }, // membros
  { path: "/admin/members/approvals", exact: true, component: <AdminMembersApprovals/> }, // membros aprovacoes
  { path: "/admin/members/guests", exact: true, component: <MembersInvitationsList/> }, // membros cadastrar convidados
  { path: "/admin/members/payments", exact: true, component: <MembersPayments/> }, // membros pagamentos
  { path: "/admin/members/control-scores", exact: true, component: <MembersScoresLaunchList/> }, // membros controle pontuacao
  { path: "/admin/members/rules-scores", exact: true, component: <MembersRulesScoresList/> }, // membros regras pontuacao
  { path: "/admin/members/leads", exact: true, component: <MembersLeads/> }, 

  // incomum 

  { path: "/dashboard", exact: true, component: <Dashboard/> },
  { path: "/classes/view/:id", component: <ClassesView/> }, 

  { path: "/all/events", exact: true, component: <AllEvents/> }, 
  { path: "/all/events/:id", exact: true, component: <AllEventsView/> }, 

  { path: "/all/points", exact: true, component: <AllPoints/> }, 

  { path: "/m/:slug", component: <Perfil/> },
  { path: "/members/page/edit", component: <PerfilView/> },

  // perfil

  { path: "/user/edit-password", exact: true, component: <EditPassword/> },
  { path: "/logout", exact: true, component: <Logout/> },

  // restritos default

  {
    path: "/",
    exact: true,
    component: <Navigate to="/login" />,
  },
  { 
    path: "*",
    exact: true,
    component: <Navigate to="/login" />,
  },
]

const publicRoutes = [
  { path: "/login", component: <AuthLogin/> },
  { path: "/forgot-password", component: <ForgotPassword/> },
  { path: "/new-member", component: <NewMember/> },
  { path: "/new-guest", component: <NewGuest/> },
]

export { authProtectedRoutes, publicRoutes }

