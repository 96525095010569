
export const CkeditorOptionsEditorConfiguration = {
    toolbar: [
        'undo',
        'redo',
        '|',
        'bold',
        'italic',
        // 'underline',
        '|',

        'bulletedList',
        'numberedList',
        'blockQuote',
        '|',
        'link',
        'mediaEmbed',
        '|',
        'insertTable',
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        // 'code'
    ]
};

// toolbar: {
//     items: [
//         'alignment',  // Displaying the proper UI element in the toolbar.
//         'heading',
//         '|',
//         'bold',
//         'italic',
//         'link',
//         'bulletedList',
//         'numberedList',
//         '|',
//         'outdent',
//         'indent',
//         '|',
//         'imageUpload',
//         'blockQuote',
//         'insertTable',
//         'mediaEmbed',
//         'undo',
//         'redo'
//     ]
// },
// language: 'en',
// image: {
//     toolbar: [
//         'imageTextAlternative',
//         'toggleImageCaption',
//         'imageStyle:inline',
//         'imageStyle:block',
//         'imageStyle:side'
//     ]
// },
// table: {
//     contentToolbar: [
//         'tableColumn',
//         'tableRow',
//         'mergeTableCells'
//     ]
// }
