import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import {
  Row,
  Col,
  Button,
  Table,
  Spinner,
  FormGroup,
  Input,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import LayoutHeader from "../../../components/header";
import {
  DateToData,
  formatarMilharReais
} from '../../../helpers/mascarasParaCampos';
import ModalInterviewAdminMembersPaymentsChange from "../../admin/members/members/modal/paymentsChange";
import ModalInterviewAdminMembersPaymentsRenegotiate from "../../admin/members/members/modal/paymentsRenegotiate";
import ModalInterviewAdminMembersPaymentsView from "../../admin/members/members/modal/paymentsView";
import SwalDeleteItem from "../../../helpers/swal";

const MembersPayments = props => {

  let NameModule = 'Pagamentos';

  document.title = NameModule + " | Empresários SA";

  const [Loading, setLoading] = useState(true);
  const [RowData, setRowData] = useState([]);

  const [userType, setUserType] = useState('');

  const [Pages, setPages] = useState([]);
  const [PaginationTotalItems, setPaginationTotalItems] = useState(1);
  const [PaginationPageActual, setPaginationPageActual] = useState(1);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [id, setModalId] = useState();

  var month = new Date().getMonth() + 1;
  if (month < 10) {
    month = '0' + month;
  }
  var year = new Date().getFullYear();

  const [FormData, setFormData] = useState({
    "competence": year + '-' + month
  });

  function LoadData(Page) {

    setLoading(true);
    setRowData([]);

    try {
      const Data = {
        "competence": FormData.competence || '',
        "status": FormData.status || '',
        "search": FormData.search || '',
      }
      setPaginationPageActual(Page);
      api.post('/api/charges/filter?page=' + Page, Data).then(response => {
        return response;
      }).then(data => {
        setUserType(localStorage.getItem('UserType'));
        setRowData(data.data);
        setPaginationTotalItems(data.data.total);
        listItems(data.data.total, data.data.per_page);
        setLoading(false);
      }).catch(error => {
        // console.log(error);
      });
    } catch (error) {
      // console.log(error);
    }
  }

  useEffect(() => {
    LoadData(1);
  }, []);

  function listItems(total, PorPage) {
    let totalPages = 1;
    let asdasdsa = [];
    if (PorPage < total) {
      totalPages = total / PorPage;
    }
    for (let a = 1; a < totalPages + 1; a++) {
      asdasdsa.push([a]);
    }
    setPages(asdasdsa);
  }

  function PaginationOpen(Page) {
    LoadData(Page);
  }

  function reloadData2() {
    setModalType('');
    setModalOpen(false);
    setModalId(0);
    LoadData(PaginationPageActual);
  }

  return (
    <React.Fragment>

      <LayoutHeader isAdmin={false} show={true} />

      {modalType === 'view' ? (<ModalInterviewAdminMembersPaymentsView
        toggle={() => setModalOpen(!modalOpen)}
        isOpen={modalOpen}
        modalId={id}
        size="md"
        title={NameModule}
        reloadData={reloadData2}
      />) : ''}

      {modalType === 'renegotiate' ? (<ModalInterviewAdminMembersPaymentsRenegotiate
        toggle={() => setModalOpen(!modalOpen)}
        isOpen={modalOpen}
        modalId={id}
        size="md"
        title={NameModule}
        reloadData={reloadData2}
      />) : ''}

      {modalType === 'change' ? (<ModalInterviewAdminMembersPaymentsChange
        toggle={() => setModalOpen(!modalOpen)}
        isOpen={modalOpen}
        modalId={id}
        size="md"
        title={NameModule}
        reloadData={reloadData2}
      />) : ''}

      {modalType === 'cancel' ? (<SwalDeleteItem
        PathApi="/api/charges/cancel/"
        MethodApi="patch"
        title="Cancelar"
        text="Tem certeza que deseja cancelar essa cobrança?"
        isConfirmedText="Cobrança cancelada com sucesso!"
        DeleteId={id}
        reloadData={reloadData2}
      />) : ''}

      <div className="box-container">
        <Row className="pt-3910201290">

          <Col md={12} className="content-box">
            <Row>
              <Col md={12} className="text-center">
                <div className="titulo-hr">
                  <span>{NameModule}</span>
                </div>
              </Col>

              <Col lg={12}>
                <Row>
                  <Col lg={2}>
                    <FormGroup floating>
                      <Input
                        id="competence"
                        className="competence"
                        name="competence"
                        type="month"
                        onChange={(event) => { setFormData({ ...FormData, 'competence': event.target.value }); }}
                        value={FormData.competence ? FormData.competence : ''}
                      />
                      <Label>
                        Competência
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg={2}>
                    <FormGroup floating>
                      <Input
                        id="status"
                        className="status"
                        name="status"
                        type="select"
                        onChange={(event) => {
                          setFormData({ ...FormData, 'status': event.target.value });
                        }}
                        value={FormData.type ? FormData.type : ''}
                      >
                        <option value=""> Todos </option>
                        <option value="0"> Em aberto </option>
                        <option value="1"> Vencido </option>
                        <option value="2"> Cancelado </option>
                        <option value="6"> Renegociado </option>
                        <option value="9"> Paga </option>
                      </Input>
                      <Label>
                        Status
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg={7}>
                    <FormGroup floating>
                      <Input
                        id="search"
                        className="search"
                        name="search"
                        type="search"
                        placeholder="Especilidade"
                        onChange={(event) => { setFormData({ ...FormData, 'search': event.target.value }); }}
                        value={FormData.search ? FormData.search : ''}
                      />
                      <Label>
                        Buscar
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg={1} className="text-center">
                    <Button
                      className="btnFilter"
                      onClick={() => {
                        LoadData();
                      }}
                    >
                      Filtrar
                    </Button>
                  </Col>
                </Row>
              </Col>

            </Row>
          </Col>
          <Col md={12} className="content-box">
            <Row className="mb-3">
              <Col md={12}>
                <div className="boxes">
                  <Table responsive size="sm" className="table">
                    <thead>
                      <th className=""></th>
                      <th className=""> Status </th>
                      <th className=""> Vencimento </th>
                      <th className=""> Data Pag. </th>
                      <th className=""> Patrocinador / Membro </th>
                      <th className=""> Valor </th>
                      <th className=""> Vl. Pago </th>
                      <th className=""> Parcela(s) </th>
                      <th className=""> Forma. Pag. </th>
                      <th className=""> ID </th>
                      <th className=""> Serviço </th>
                    </thead>
                    <tbody>

                      {Loading ? (
                        <tr>
                          <td colSpan={50} style={{ border: '0px' }}>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                              <Spinner color="info" className="mt-5 mb-2"></Spinner>
                              <br />
                              Carregando dados...
                            </div>
                          </td>
                        </tr>
                      ) : null}

                      {!Loading && RowData.results === 0 ? (
                        <tr>
                          <td colSpan={50} style={{ border: '0px', paddingTop: '30px', textAlign: 'center' }}>
                            Sem dados com esses filtros no momento.
                          </td>
                        </tr>
                      ) : null}

                      {RowData && !Loading ? (
                        RowData.data.map((row, index) => (
                          <tr key={index}>
                            <td style={{ width: '10px', textAlign: 'center' }}>
                              <UncontrolledDropdown
                                className="me-2"
                                direction="down"
                              >
                                <DropdownToggle
                                  caret
                                  color="dark"
                                  size="sm"
                                  style={{ padding: '1px 5px' }}
                                >
                                  Acões
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem header>
                                    Selecione uma ação
                                  </DropdownItem>
                                  <DropdownItem onClick={(event) => {
                                    setModalType('view');
                                    setModalOpen(true);
                                    setModalId(row.id);
                                  }}>
                                    Detalhes
                                  </DropdownItem>
                                  {userType !== 'affiliated' && userType !== 'member' ? (
                                    <>
                                      {row.status < 7 && row.status != 2 && row.status != 9 ? (
                                        <DropdownItem divider />
                                      ) : null}
                                      {row.status < 2 && row.status != 2 && row.status != 6 && row.status != 9 ? (
                                        <DropdownItem onClick={(event) => {
                                          setModalType('renegotiate');
                                          setModalOpen(true);
                                          setModalId(row.id);
                                        }}>
                                          Renegociar
                                        </DropdownItem>
                                      ) : null}
                                      {row.status < 7 && row.status != 2 && row.status != 9 ? (
                                        <DropdownItem onClick={(event) => {
                                          setModalType('change');
                                          setModalOpen(true);
                                          setModalId(row.id);
                                        }}>
                                          Foi pago
                                        </DropdownItem>
                                      ) : null}
                                      {row.status < 7 && row.status != 2 && row.status != 9 ? (
                                        <DropdownItem divider />
                                      ) : null}
                                      {row.status < 7 && row.status != 2 && row.status != 9 ? (
                                        <DropdownItem onClick={(event) => {
                                          setModalType('cancel');
                                          setModalOpen(true);
                                          setModalId(row.id);
                                        }}>
                                          Cancelar
                                        </DropdownItem>
                                      ) : null}
                                    </>
                                  ) : null}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                            <td>
                              {row.status == 0 ? 'Em aberto' : ''}
                              {row.status == 1 ? 'Vencido' : ''}
                              {row.status == 2 ? 'Cancelado' : ''}
                              {row.status == 6 ? 'Renegociado' : ''}
                              {row.status == 9 ? 'Pago' : ''}
                            </td>
                            <td>
                              {row.due_date ? DateToData(row.due_date, "/") : '-'}
                            </td>
                            <td>
                              {row.payday ? DateToData(row.payday, "/") : '-'}
                            </td>
                            <td>
                              {row.sponsors ? row.sponsors.name : ''}
                              {row.members ? row.members.name : ''}
                            </td>
                            <td>
                              {row.value ? 'R$ ' + formatarMilharReais(row.value) : '-'}
                            </td>
                            <td>
                              {row.amount_paid ? 'R$ ' + formatarMilharReais(row.amount_paid) : '-'}
                            </td>
                            <td>
                              {row.total_parcels ? row.parcel + '/' + row.total_parcels : '-'}
                            </td>
                            <td>
                              {row.methods_payments ? row.methods_payments.name : '-'}
                            </td>
                            <td>
                              {row.id ? row.id : '-'}
                            </td>
                            <td>
                              {row.services ? row.services.name : '-'}
                            </td>
                          </tr>
                        ))
                      ) : null}

                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col md={9}>
                <div className="TablePagination">
                  Mostrando página <strong>{PaginationPageActual}</strong> de <strong>1</strong>. | Total de registros é de <strong>{PaginationTotalItems}</strong>.
                </div>
              </Col>
              <Col md={3}>
                <FormGroup row className="text-right">
                  <Label
                    for="exampleEmail"
                    sm={4}
                  >
                    Página
                  </Label>
                  <Col sm={8}>
                    <Input
                      className=""
                      autoComplete={false}
                      name="companie_taxation"
                      type="select"
                      placeholder="Tributação"
                      onChange={(event) => { PaginationOpen(event.target.value); }}
                    >
                      {Pages.map((row) => (
                        <option key={row} value={row} > {row} </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default MembersPayments;