import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CkeditorOptionsEditorConfiguration } from '../../../../../components/ckeditor-options';
import {
    Row,
    Col,
    Form,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Label,
} from "reactstrap";
import api from "../../../../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    MaskCellPhone,
    MaskCep,
    MaskCpf,
    MaskCnpj,
    formatarMilharReais,
} from '../../../../../helpers/mascarasParaCampos';
import {
    showToastArray,
} from '../../../../../helpers/generals'; 
import { useNavigate } from 'react-router-dom';

export default function ModalAddAdminMembersForm({
    modalId,
    reloadData,
    blockInputs,
    toggle2,
    textButtonSubmit = "Adicionar",
    disabledBtnClosed,
    disabledInputSituation
}) {

    const navigate = useNavigate();

    const [FormData, setFormData] = useState({});
    const [CountriesData, setCountriesData] = useState([]);
    const [StatesData, setStatesData] = useState([]);
    const [CitiesData, setCitiesData] = useState([])
    const [BranchesData, setBranchesData] = useState([]);
    const [UnitsData, setUnitsData] = useState([]);
    const [PlansData, setPlansData] = useState([]);;

    const [Loading, setLoading] = useState(true);

    const LoadDataShow = async => {
        if (modalId > 0) {
            try {
                api.get('/api/users/show/' + modalId).then(response => {
                    return response;
                }).then(data => {

                    const formData = { ...FormData };

                    if (data.data.data) {

                        const item = data.data.data;

                        if (item.name) formData.name = item.name;
                        if (item.cpf) formData.cpf = MaskCpf(item.cpf);
                        if (item.birth) formData.birth = item.birth;
                        if (item.cell_phone) formData.cell_phone = MaskCellPhone(item.cell_phone);
                        if (item.email) formData.email = item.email;
                        if (item.nickname) formData.nickname = item.nickname;
                        if (item.description) formData.description = item.description;

                        if (item.address) formData.address = item.address;
                        if (item.number) formData.number = item.number;
                        if (item.complement) formData.complement = item.complement;
                        if (item.district) formData.district = item.district;
                        if (item.zip_code) formData.zip_code = MaskCep(item.zip_code);

                        if (item.countries_id) formData.countries_id = item.countries_id;
                        if (item.states_id) {
                            LoadStates(item.countries_id);
                            formData.states_id = item.states_id;
                        }
                        if (item.cities_id) {
                            LoadCities(item.states_id);
                            formData.cities_id = item.cities_id;
                        }

                        if (item.branches_id) formData.branches_id = item.branches_id;
                        if (item.units_id) formData.units_id = item.units_id;

                        if (item.companie_name) formData.companie_name = item.companie_name;
                        if (item.companie_cnpj) formData.companie_cnpj = item.companie_cnpj;
                        if (item.func_companie) formData.func_companie = item.func_companie;
                        if (item.time_atuation) formData.time_atuation = item.time_atuation;
                        if (item.companie_description) formData.companie_description = item.companie_description;
                        if (item.companie_invoicing) formData.companie_invoicing = item.companie_invoicing;
                        if (item.amount_employees) formData.amount_employees = item.amount_employees;
                        if (item.list_partnerships_clients) formData.list_partnerships_clients = item.list_partnerships_clients;
                        if (item.group_expectation) formData.group_expectation = item.group_expectation;
                        if (item.specific_activity) formData.specific_activity = item.specific_activity;
                        if (item.allows_contact) formData.allows_contact = item.allows_contact;
                        if (item.urgency_schedule) formData.urgency_schedule = item.urgency_schedule;
                        if (item.subscription_plan) formData.subscription_plan = item.subscription_plan;

                        if (item.time_i_1) formData.time_i_1 = item.time_i_1;
                        if (item.time_i_2) formData.time_i_2 = item.time_i_2;
                        if (item.time_i_3) formData.time_i_3 = item.time_i_3;
                        if (item.time_i_4) formData.time_i_4 = item.time_i_4;
                        if (item.time_i_5) formData.time_i_5 = item.time_i_5;

                        if (item.time_f_1) formData.time_f_1 = item.time_f_1;
                        if (item.time_f_2) formData.time_f_2 = item.time_f_2;
                        if (item.time_f_3) formData.time_f_3 = item.time_f_3;
                        if (item.time_f_4) formData.time_f_4 = item.time_f_4;
                        if (item.time_f_5) formData.time_f_5 = item.time_f_5;

                        if (item.is_active) formData.is_active = item.is_active;

                    }

                    setFormData(formData);
                    setLoading(false);

                }).catch(error => {
                    // console.log(error);
                });
            } catch (error) {
                // console.log(error);
            }
        }
    };

    const LoadCountries = async => {
        setCountriesData([]);
        setStatesData([]);
        setCitiesData([]);
        api.get('/api/countries/active').then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setCountriesData(data.data);
                setLoading(false);
            }
        }).catch(error => {
            // console.log(error);
        })
    };

    function LoadStates(id) {
        setStatesData([]);
        setCitiesData([]);
        api.get('/api/states/active/countries/' + id).then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setStatesData(data.data);
            }
        }).catch(error => {
            // console.log(error);
        })
    };

    function LoadCities(id) {
        setCitiesData([]);
        api.get('/api/cities/active/states/' + id).then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setCitiesData(data.data);
            }
        }).catch(error => {
            // console.log(error);
        })
    };

    function LoadBranches() {
        setBranchesData([]);
        api.get('/api/branches/active').then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setBranchesData(data.data);
            }
        }).catch(error => {
            // console.log(error);
        })
    };

    function LoadUnits() {
        setUnitsData([]);
        api.get('/api/units/active').then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setUnitsData(data.data);
            }
        }).catch(error => {
            // console.log(error);
        })
    };

    function LoadPlans() {
        setPlansData([]);
        api.get('/api/plans/active').then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setPlansData(data.data);
            }
        }).catch(error => {
            // console.log(error);
        })
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: FormData.name || '',
            email: FormData.email || '',
            nickname: FormData.nickname || '',
            birth: FormData.birth || '',
            cpf: FormData.cpf || '',
            description: FormData.description || '',
            cell_phone: FormData.cell_phone || '',

            companie_name: FormData.companie_name || '',
            companie_cnpj: FormData.companie_cnpj || '',
            companie_description: FormData.companie_description || '',
            companie_invoicing: FormData.companie_invoicing || '', // faturamento

            amount_employees: FormData.amount_employees || '', // quantidade de empregados
            list_partnerships_clients: FormData.list_partnerships_clients || '', // Qual o tipo de cliente ou parceria você busca, Cite pelo menos três
            group_expectation: FormData.group_expectation || '', // O que você achou do nosso encontro e qual sua expectativa em relação ao Grupo
            specific_activity: FormData.specific_activity || '', // Qual atividade especifica pretende bloquear, trazer a resposta do ramo opção de incluir outro em texto
            allows_contact: FormData.allows_contact || '', // Você permite passar seu contato para pessoas que tem interesse em fazer negócios com você?
            urgency_schedule: FormData.urgency_schedule || '', // Qual área você tem urgência em agendar atendimento?

            time_atuation: FormData.time_atuation || '',
            func_companie: FormData.func_companie || '',

            branches_id: FormData.branches_id || '',
            units_id: FormData.units_id || '',
            subscription_plan: FormData.subscription_plan || '',

            countries_id: FormData.countries_id || '',
            states_id: FormData.states_id || '',
            cities_id: FormData.cities_id || '',
            address: FormData.address || '',
            number: FormData.number || '',
            complement: FormData.complement || '',
            district: FormData.district || '',
            zip_code: FormData.zip_code || '',

            is_active: disabledInputSituation ? 1 : (FormData.is_active || ''),

            time_i_1: FormData.time_i_1 || '',
            time_f_1: FormData.time_f_1 || '',

            time_i_2: FormData.time_i_2 || '',
            time_f_2: FormData.time_f_2 || '',

            time_i_3: FormData.time_i_3 || '',
            time_f_3: FormData.time_f_3 || '',

            time_i_4: FormData.time_i_4 || '',
            time_f_4: FormData.time_f_4 || '',

            time_i_5: FormData.time_i_5 || '',
            time_f_5: FormData.time_f_5 || '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Digite esse campo."),
            name: Yup.string().required("Digite esse campo."),
            nickname: Yup.string().required("Digite esse campo."),
            birth: Yup.string().required("Digite esse campo."),
            // cpf: Yup.string().required("Digite esse campo."),
            description: Yup.string().required("Digite esse campo."),

            companie_name: Yup.string().required("Digite esse campo."),
            companie_cnpj: Yup.string().required("Digite esse campo."),
            companie_description: Yup.string().required("Digite esse campo."),
            companie_invoicing: Yup.string().required("Digite esse campo."),

            amount_employees: Yup.string().required("Digite esse campo."),
            list_partnerships_clients: Yup.string().required("Digite esse campo."),
            group_expectation: Yup.string().required("Digite esse campo."),
            specific_activity: Yup.string().required("Digite esse campo."),
            allows_contact: Yup.string().required("Digite esse campo."),
            urgency_schedule: Yup.string().required("Digite esse campo."),
            branches_id: Yup.string().required("Selecione uma opção."),
            units_id: Yup.string().required("Selecione uma opção."),
            subscription_plan: Yup.string().required("Selecione uma opção."),

            time_atuation: Yup.string().required("Selecione uma opção."),
            func_companie: Yup.string().required("Selecione uma opção."),

            countries_id: Yup.string().required("Selecione uma opção."),
            states_id: Yup.string().required("Selecione uma opção."),
            cities_id: Yup.string().required("Selecione uma opção."),
            address: Yup.string().required("Digite esse campo."),
            number: Yup.string().required("Digite esse campo."),
            complement: Yup.string().required("Digite esse campo."),
            district: Yup.string().required("Digite esse campo."),
            zip_code: Yup.string().required("Digite esse campo."),

            is_active: Yup.string().required("Selecione uma opção."),            
        }),
        onSubmit: (values) => {
            setLoading(true);

            if (disabledInputSituation) {
                api.post('/api/register', values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success == true) {
                        showToastArray("", "Sucesso", data.message);
                        setFormData({});
                        navigate("/login");
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });
            } else {
                if (modalId > 0 && !blockInputs) {
                    api.put('/api/users/update/' + modalId, values).then(response => {
                        return response.data;
                    }).then(data => {
                        if (data.success == true) {
                            showToastArray("", "Sucesso", "Cadastro atualizado com sucesso!");
                            setLoading(false);
                            reloadData();
                        }
                    }).catch(error => {
                        if (error) {
                            let message = 'Erro ao enviar o registro!';
                            if (error.response.data.message) {
                                message = error.response.data.message;
                            }
                            showToastArray("error", "Erro!", message);
                            setLoading(false);
                        }
                    });
                } else {
                    api.post('/api/users/save', values).then(response => {
                        return response.data;
                    }).then(data => {
                        if (data.success == true) {
                            showToastArray("", "Sucesso", "Cadastro inserido com sucesso!");
                            setLoading(false);
                            reloadData();
                        }
                    }).catch(error => {
                        if (error) {
                            let message = 'Erro ao enviar o registro!';
                            if (error.response.data.message) {
                                message = error.response.data.message;
                            }
                            showToastArray("error", "Erro!", message);
                            setLoading(false);
                        }
                    });
                }
            }
        },
    });

    useEffect(() => {
        LoadCountries();
        LoadBranches();
        LoadUnits();
        LoadPlans();
        LoadDataShow();
    }, [modalId]);

    return (
        <Form
            className="form-horizontal"
            onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                // // console.log('validation', validation.values);
                return false;
            }}
        >
            <Row>

                <Col sm="9">
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            autoComplete={false}
                            name="name"
                            type="text"
                            maxLength={255}
                            onChange={(event) => { setFormData({ ...FormData, 'name': event.target.value }); }}
                            value={FormData.name ? FormData.name : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.name && validation.errors.name ? true : false
                            }
                        />
                        {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                        ) : null}
                        <Label>
                            Nome Completo
                        </Label>
                    </FormGroup>
                </Col>
                {!disabledInputSituation ? (
                    <Col sm="3">
                        <FormGroup floating>
                            <Input
                                className=""
                                disabled={blockInputs ? true : false}
                                autoComplete={false}
                                name="is_active"
                                type="select"
                                placeholder="Situação"
                                onChange={(event) => { setFormData({ ...FormData, 'is_active': event.target.value }); }}
                                value={FormData.is_active ? FormData.is_active : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.is_active && validation.errors.is_active ? true : false
                                }
                            >
                                {validation.touched.is_active && validation.errors.comis_activeanie_taxation ? (
                                    <FormFeedback type="invalid">{validation.errors.is_active}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                <option value="1"> Ativo </option>
                                <option value="2"> Inativo  </option>
                            </Input>
                            <Label>
                                Situação
                            </Label>
                        </FormGroup>
                    </Col>
                ) : null}
                <Col sm="4">
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            autoComplete={false}
                            name="cpf"
                            type="text"
                            maxLength={255}
                            onChange={(event) => { setFormData({ ...FormData, 'cpf': MaskCpf(event.target.value) }); }}
                            value={FormData.cpf ? FormData.cpf : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.cpf && validation.errors.cpf ? true : false
                            }
                        />
                        {validation.touched.cpf && validation.errors.cpf ? (
                            <FormFeedback type="invalid">{validation.errors.cpf}</FormFeedback>
                        ) : null}
                        <Label>
                            CPF
                        </Label>
                    </FormGroup>
                </Col>
                <Col sm="4">
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            autoComplete={false}
                            name="birth"
                            type="date"
                            onChange={(event) => { setFormData({ ...FormData, 'birth': event.target.value }); }}
                            value={FormData.birth ? FormData.birth : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.birth && validation.errors.birth ? true : false
                            }
                        />
                        {validation.touched.birth && validation.errors.birth ? (
                            <FormFeedback type="invalid">{validation.errors.birth}</FormFeedback>
                        ) : null}
                        <Label>
                            Nascimento
                        </Label>
                    </FormGroup>
                </Col>
                <Col sm="4">
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            autoComplete={false}
                            name="cell_phone"
                            type="text"
                            maxLength={255}
                            onChange={(event) => { setFormData({ ...FormData, 'cell_phone': MaskCellPhone(event.target.value) }); }}
                            value={FormData.cell_phone ? FormData.cell_phone : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.cell_phone && validation.errors.cell_phone ? true : false
                            }
                        />
                        {validation.touched.cell_phone && validation.errors.cell_phone ? (
                            <FormFeedback type="invalid">{validation.errors.cell_phone}</FormFeedback>
                        ) : null}
                        <Label>
                            Celular
                        </Label>
                    </FormGroup>
                </Col>
                <Col sm="12">
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            autoComplete={false}
                            name="email"
                            type="email"
                            maxLength={255}
                            onChange={(event) => { setFormData({ ...FormData, 'email': event.target.value }); }}
                            value={FormData.email ? FormData.email : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.email && validation.errors.email ? true : false
                            }
                        />
                        {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                        <Label>
                            E-mail
                        </Label>
                    </FormGroup>
                </Col>
                <Col sm="12">
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            autoComplete={false}
                            name="nickname"
                            type="text"
                            maxLength={255}
                            onChange={(event) => { setFormData({ ...FormData, 'nickname': event.target.value }); }}
                            value={FormData.nickname ? FormData.nickname : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.nickname && validation.errors.nickname ? true : false
                            }
                        />
                        {validation.touched.nickname && validation.errors.nickname ? (
                            <FormFeedback type="invalid">{validation.errors.nickname}</FormFeedback>
                        ) : null}
                        <Label>
                            Como deseja ser chamado
                        </Label>
                    </FormGroup>
                </Col>
                <Col sm="12">
                    <FormGroup>
                        <Label>
                            Descrição (Apresentação pessoal/profissional)
                        </Label>
                        {blockInputs ? (
                            <Row>
                                <Col sm="12">
                                    <div style={{ width: '100%', height: '150px', padding: '20px', borderRadius: '8px', background: '#fff', overflowX: 'hidden', overflowY: 'scroll' }}>
                                        {FormData.description ? <p dangerouslySetInnerHTML={{ __html: FormData.description }}></p> : '-'}
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            <>
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={CkeditorOptionsEditorConfiguration}
                                    data={FormData.description ? FormData.description : ''}
                                    onReady={editor => {
                                        // Allows you to store the editor instance and use it later.
                                        // // console.log('11', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        // // console.log({ event, editor, data });
                                        setFormData({ ...FormData, 'description': (data) });
                                    }}
                                    onBlur={(event, editor) => {
                                        // // console.log('22', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        // // console.log('333', editor);
                                    }}
                                />
                                {validation.touched.description && validation.errors.description ? (
                                    <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                ) : null}
                            </>
                        )}
                    </FormGroup>
                </Col>

                <Col md={12} className="text-center mt-2">
                    <div className="titulo-hr">
                        <span>Endereço</span>
                    </div>
                </Col>
                <Col sm="9">
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            autoComplete={false}
                            name="address"
                            type="text"
                            maxLength={255}
                            onChange={(event) => { setFormData({ ...FormData, 'address': event.target.value }); }}
                            value={FormData.address ? FormData.address : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.address && validation.errors.address ? true : false
                            }
                        />
                        {validation.touched.address && validation.errors.address ? (
                            <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                        ) : null}
                        <Label>
                            Endereço
                        </Label>
                    </FormGroup>
                </Col>
                <Col sm="3">
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            autoComplete={false}
                            name="number"
                            type="text"
                            maxLength={25}
                            onChange={(event) => { setFormData({ ...FormData, 'number': event.target.value }); }}
                            value={FormData.number ? FormData.number : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.number && validation.errors.number ? true : false
                            }
                        />
                        {validation.touched.number && validation.errors.number ? (
                            <FormFeedback type="invalid">{validation.errors.number}</FormFeedback>
                        ) : null}
                        <Label>
                            Número
                        </Label>
                    </FormGroup>
                </Col>
                <Col sm="4">
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            autoComplete={false}
                            name="district"
                            type="text"
                            placeholder="Bairro"
                            onChange={(event) => { setFormData({ ...FormData, 'district': event.target.value }); }}
                            value={FormData.district ? FormData.district : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.district && validation.errors.district ? true : false
                            }
                        />
                        {validation.touched.district && validation.errors.district ? (
                            <FormFeedback type="invalid">{validation.errors.district}</FormFeedback>
                        ) : null}
                        <Label>
                            Bairro
                        </Label>
                    </FormGroup>
                </Col>
                <Col sm="4">
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            autoComplete={false}
                            name="complement"
                            type="text"
                            maxLength={25}
                            placeholder="Complemento"
                            onChange={(event) => { setFormData({ ...FormData, 'complement': event.target.value }); }}
                            value={FormData.complement ? FormData.complement : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.complement && validation.errors.complement ? true : false
                            }
                        />
                        {validation.touched.complement && validation.errors.complement ? (
                            <FormFeedback type="invalid">{validation.errors.complement}</FormFeedback>
                        ) : null}
                        <Label>
                            Complemento
                        </Label>
                    </FormGroup>
                </Col>
                <Col sm="4">
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            autoComplete={false}
                            name="zip_code"
                            type="text"
                            onChange={(event) => { setFormData({ ...FormData, 'zip_code': MaskCep(event.target.value) }); }}
                            value={FormData.zip_code ? FormData.zip_code : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.zip_code && validation.errors.zip_code ? true : false
                            }
                        />
                        {validation.touched.zip_code && validation.errors.zip_code ? (
                            <FormFeedback type="invalid">{validation.errors.zip_code}</FormFeedback>
                        ) : null}
                        <Label>
                            CEP
                        </Label>
                    </FormGroup>
                </Col>
                <Col lg={4}>
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            id="countries_id"
                            name="countries_id"
                            type="select"
                            onChange={(event) => {
                                setFormData({ ...FormData, 'countries_id': event.target.value });
                                LoadStates(event.target.value);
                            }}
                            value={FormData.countries_id ? FormData.countries_id : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.countries_id && validation.errors.countries_id ? true : false
                            }
                        >
                            {validation.touched.countries_id && validation.errors.countries_id ? (
                                <FormFeedback type="invalid">{validation.errors.countries_id}</FormFeedback>
                            ) : null}
                            <option value=""> Selecione... </option>
                            {CountriesData.map((row, index) => (
                                <option key={index} value={row.id}>{row.name}</option>
                            ))}
                        </Input>
                        <Label>
                            País
                        </Label>
                    </FormGroup>
                </Col>
                <Col lg={4}>
                    <FormGroup floating>
                        <Input
                            disabled={(FormData.countries_id && CountriesData.length) && !blockInputs > 0 ? false : true}
                            id="states_id"
                            className="states_id"
                            name="states_id"
                            type="select"
                            onChange={(event) => {
                                setFormData({ ...FormData, 'states_id': event.target.value });
                                LoadCities(event.target.value);
                            }}
                            value={FormData.states_id ? FormData.states_id : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.states_id && validation.errors.states_id ? true : false
                            }
                        >
                            {validation.touched.states_id && validation.errors.states_id ? (
                                <FormFeedback type="invalid">{validation.errors.states_id}</FormFeedback>
                            ) : null}
                            <option value=""> Selecione... </option>
                            {StatesData.map((row, index) => (
                                <option key={index} value={row.id}>{row.name}</option>
                            ))}
                        </Input>
                        <Label>
                            Estado
                        </Label>
                    </FormGroup>
                </Col>
                <Col lg={4}>
                    <FormGroup floating>
                        <Input
                            disabled={(FormData.states_id && StatesData.length) && !blockInputs > 0 ? false : true}
                            id="cities_id"
                            className="cities_id"
                            name="cities_id"
                            type="select"
                            onChange={(event) => { setFormData({ ...FormData, 'cities_id': event.target.value }); }}
                            value={FormData.cities_id ? FormData.cities_id : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.cities_id && validation.errors.cities_id ? true : false
                            }
                        >
                            {validation.touched.cities_id && validation.errors.cities_id ? (
                                <FormFeedback type="invalid">{validation.errors.cities_id}</FormFeedback>
                            ) : null}
                            <option value=""> Selecione... </option>
                            {CitiesData.map((row, index) => (
                                <option key={index} value={row.id}>{row.name}</option>
                            ))}
                        </Input>
                        <Label>
                            Cidade
                        </Label>
                    </FormGroup>
                </Col>

                <Col md={12} className="text-center mt-2">
                    <div className="titulo-hr">
                        <span>Dados da empresa</span>
                    </div>
                </Col>
                <Col sm="12">
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            autoComplete={false}
                            name="companie_name"
                            type="text"
                            maxLength={255}
                            onChange={(event) => { setFormData({ ...FormData, 'companie_name': event.target.value }); }}
                            value={FormData.companie_name ? FormData.companie_name : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.companie_name && validation.errors.companie_name ? true : false
                            }
                        />
                        {validation.touched.companie_name && validation.errors.companie_name ? (
                            <FormFeedback type="invalid">{validation.errors.companie_name}</FormFeedback>
                        ) : null}
                        <Label>
                            Nome da Sua Empresa
                        </Label>
                    </FormGroup>
                </Col>
                <Col sm="6">
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            autoComplete={false}
                            name="companie_cnpj"
                            type="text"
                            maxLength={255}
                            onChange={(event) => { setFormData({ ...FormData, 'companie_cnpj': MaskCnpj(event.target.value) }); }}
                            value={FormData.companie_cnpj ? FormData.companie_cnpj : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.companie_cnpj && validation.errors.companie_cnpj ? true : false
                            }
                        />
                        {validation.touched.companie_cnpj && validation.errors.companie_cnpj ? (
                            <FormFeedback type="invalid">{validation.errors.companie_cnpj}</FormFeedback>
                        ) : null}
                        <Label>
                            CNPJ da sua empresa
                        </Label>
                    </FormGroup>
                </Col>
                <Col sm="6">
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            autoComplete={false}
                            name="branches_id"
                            type="select"
                            onChange={(event) => { setFormData({ ...FormData, 'branches_id': event.target.value }); }}
                            value={FormData.branches_id ? FormData.branches_id : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.branches_id && validation.errors.branches_id ? true : false
                            }
                        >
                            {validation.touched.branches_id && validation.errors.branches_id ? (
                                <FormFeedback type="invalid">{validation.errors.branches_id}</FormFeedback>
                            ) : null}
                            <option value=""> Selecione... </option>
                            {BranchesData.map((row, index) => (
                                <option key={index} value={row.id}>{row.name}</option>
                            ))}
                        </Input>
                        <Label>
                            Qual o Ramo da Sua empresa?
                        </Label>
                    </FormGroup>
                </Col>
                <Col sm="6">
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            autoComplete={false}
                            name="func_companie"
                            type="text"
                            maxLength={255}
                            onChange={(event) => { setFormData({ ...FormData, 'func_companie': event.target.value }); }}
                            value={FormData.func_companie ? FormData.func_companie : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.func_companie && validation.errors.func_companie ? true : false
                            }
                        />
                        {validation.touched.func_companie && validation.errors.func_companie ? (
                            <FormFeedback type="invalid">{validation.errors.func_companie}</FormFeedback>
                        ) : null}
                        <Label>
                            Qual sua função na Empresa?
                        </Label>
                    </FormGroup>
                </Col>
                <Col sm="6">
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            autoComplete={false}
                            name="time_atuation"
                            type="text"
                            maxLength={255}
                            onChange={(event) => { setFormData({ ...FormData, 'time_atuation': event.target.value }); }}
                            value={FormData.time_atuation ? FormData.time_atuation : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.time_atuation && validation.errors.time_atuation ? true : false
                            }
                        />
                        {validation.touched.time_atuation && validation.errors.time_atuation ? (
                            <FormFeedback type="invalid">{validation.errors.time_atuation}</FormFeedback>
                        ) : null}
                        <Label>
                            Quanto tempo você tem de atuação no seu mercado?
                        </Label>
                    </FormGroup>
                </Col>

                <Col sm="12">
                    <FormGroup>
                        <Label>
                            Explique em sua e com poucas palavras o que sua empresa atua?
                        </Label>
                        {blockInputs ? (
                            <Row>
                                <Col sm="12">
                                    <div style={{ width: '100%', height: '150px', padding: '20px', borderRadius: '8px', background: '#fff', overflowX: 'hidden', overflowY: 'scroll' }}>
                                        {FormData.companie_description ? <p dangerouslySetInnerHTML={{ __html: FormData.companie_description }}></p> : '-'}
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            <>
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={CkeditorOptionsEditorConfiguration}
                                    data={FormData.companie_description ? FormData.companie_description : ''}
                                    onReady={editor => {
                                        // Allows you to store the editor instance and use it later.
                                        // // console.log('11', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        // // console.log({ event, editor, data });
                                        setFormData({ ...FormData, 'companie_description': (data) });
                                    }}
                                    onBlur={(event, editor) => {
                                        // // console.log('22', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        // // console.log('333', editor);
                                    }}
                                />
                                {validation.touched.companie_description && validation.errors.companie_description ? (
                                    <FormFeedback type="invalid">{validation.errors.companie_description}</FormFeedback>
                                ) : null}
                            </>
                        )}
                    </FormGroup>
                </Col>
                <Col sm="12">
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            autoComplete={false}
                            name="companie_invoicing"
                            type="text"
                            maxLength={255}
                            onChange={(event) => { setFormData({ ...FormData, 'companie_invoicing': formatarMilharReais(event.target.value) }); }}
                            value={FormData.companie_invoicing ? FormData.companie_invoicing : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.companie_invoicing && validation.errors.companie_invoicing ? true : false
                            }
                        />
                        {validation.touched.companie_invoicing && validation.errors.companie_invoicing ? (
                            <FormFeedback type="invalid">{validation.errors.companie_invoicing}</FormFeedback>
                        ) : null}
                        <Label>
                            Qual o faturamento médio anual de sua empresa?
                        </Label>
                    </FormGroup>
                </Col>
                <Col sm="12">
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            autoComplete={false}
                            name="amount_employees"
                            type="select"
                            onChange={(event) => { setFormData({ ...FormData, 'amount_employees': event.target.value }); }}
                            value={FormData.amount_employees ? FormData.amount_employees : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.amount_employees && validation.errors.amount_employees ? true : false
                            }
                        >
                            {validation.touched.amount_employees && validation.errors.amount_employees ? (
                                <FormFeedback type="invalid">{validation.errors.amount_employees}</FormFeedback>
                            ) : null}
                            <option value=""> Selecione... </option>
                            <option value="1"> 0 </option>
                            <option value="2"> 1 à 5 </option>
                            <option value="3"> 6 à 15 </option>
                            <option value="4"> 16 à 30 </option>
                            <option value="5"> acima de 31 </option>
                        </Input>
                        <Label>
                            Sua empresa possui quantos funcionários?
                        </Label>
                    </FormGroup>
                </Col>
                <Col sm="12">
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            autoComplete={false}
                            name="list_partnerships_clients"
                            type="text"
                            maxLength={255}
                            onChange={(event) => { setFormData({ ...FormData, 'list_partnerships_clients': event.target.value }); }}
                            value={FormData.list_partnerships_clients ? FormData.list_partnerships_clients : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.list_partnerships_clients && validation.errors.list_partnerships_clients ? true : false
                            }
                        />
                        {validation.touched.list_partnerships_clients && validation.errors.list_partnerships_clients ? (
                            <FormFeedback type="invalid">{validation.errors.list_partnerships_clients}</FormFeedback>
                        ) : null}
                        <Label>
                            Qual o tipo de cliente ou parceria você busca? <span style={{ color: 'red' }}>Cite pelo menos três e separa por vírgulas</span>
                        </Label>
                    </FormGroup>
                </Col>
                <Col sm="12">
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            autoComplete={false}
                            name="group_expectation"
                            type="text"
                            maxLength={255}
                            onChange={(event) => { setFormData({ ...FormData, 'group_expectation': event.target.value }); }}
                            value={FormData.group_expectation ? FormData.group_expectation : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.group_expectation && validation.errors.group_expectation ? true : false
                            }
                        />
                        {validation.touched.group_expectation && validation.errors.group_expectation ? (
                            <FormFeedback type="invalid">{validation.errors.group_expectation}</FormFeedback>
                        ) : null}
                        <Label>
                            O que você achou do nosso encontro e qual sua expectativa em relação ao Grupo?
                        </Label>
                    </FormGroup>
                </Col>
                <Col sm="12">
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            autoComplete={false}
                            name="specific_activity"
                            type="text"
                            maxLength={255}
                            onChange={(event) => { setFormData({ ...FormData, 'specific_activity': event.target.value }); }}
                            value={FormData.specific_activity ? FormData.specific_activity : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.specific_activity && validation.errors.specific_activity ? true : false
                            }
                        />
                        {validation.touched.specific_activity && validation.errors.specific_activity ? (
                            <FormFeedback type="invalid">{validation.errors.specific_activity}</FormFeedback>
                        ) : null}
                        <Label>
                            Qual atividade especifica pretende bloquear?
                        </Label>
                    </FormGroup>
                </Col>
                <Col sm="12">
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            autoComplete={false}
                            name="allows_contact"
                            type="select"
                            onChange={(event) => { setFormData({ ...FormData, 'allows_contact': event.target.value }); }}
                            value={FormData.allows_contact ? FormData.allows_contact : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.allows_contact && validation.errors.allows_contact ? true : false
                            }
                        >
                            {validation.touched.allows_contact && validation.errors.allows_contact ? (
                                <FormFeedback type="invalid">{validation.errors.allows_contact}</FormFeedback>
                            ) : null}
                            <option value=""> Selecione... </option>
                            <option value="1"> Sim </option>
                            <option value="2"> Não </option>
                        </Input>
                        <Label>
                            Você permite passar seu contato para pessoas que tem interesse em fazer negócios com você?
                        </Label>
                    </FormGroup>
                </Col>
                <Col sm="12">
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            autoComplete={false}
                            name="urgency_schedule"
                            type="select"
                            onChange={(event) => { setFormData({ ...FormData, 'urgency_schedule': event.target.value }); }}
                            value={FormData.urgency_schedule ? FormData.urgency_schedule : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.urgency_schedule && validation.errors.urgency_schedule ? true : false
                            }
                        >
                            {validation.touched.urgency_schedule && validation.errors.urgency_schedule ? (
                                <FormFeedback type="invalid">{validation.errors.urgency_schedule}</FormFeedback>
                            ) : null}
                            <option value=""> Selecione... </option>
                            <option value="1">Financeiro </option>
                            <option value="2">Contábil </option>
                            <option value="3">Juridico </option>
                            <option value="4">Contábil </option>
                            <option value="5">Gestão Emocional/Espiritual </option>
                            <option value="6">Marketing digital/rede social </option>
                        </Input>
                        <Label>
                            Qual área você tem urgência em agendar atendimento?
                        </Label>
                    </FormGroup>
                </Col>
                <Col sm="12">
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            autoComplete={false}
                            name="units_id"
                            type="select"
                            onChange={(event) => { setFormData({ ...FormData, 'units_id': event.target.value }); }}
                            value={FormData.units_id ? FormData.units_id : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.units_id && validation.errors.units_id ? true : false
                            }
                        >
                            {validation.touched.units_id && validation.errors.units_id ? (
                                <FormFeedback type="invalid">{validation.errors.units_id}</FormFeedback>
                            ) : null}
                            <option value=""> Selecione... </option>
                            {UnitsData.map((row, index) => (
                                <option key={index} value={row.id}>{row.name}</option>
                            ))}
                        </Input>
                        <Label>
                            Unidade
                        </Label>
                    </FormGroup>
                </Col>
                <Col sm="12">
                    <FormGroup floating>
                        <Input
                            className=""
                            disabled={blockInputs ? true : false}
                            autoComplete={false}
                            name="subscription_plan"
                            type="select"
                            onChange={(event) => { setFormData({ ...FormData, 'subscription_plan': event.target.value }); }}
                            value={FormData.subscription_plan ? FormData.subscription_plan : ''}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.subscription_plan && validation.errors.subscription_plan ? true : false
                            }
                        >
                            {validation.touched.subscription_plan && validation.errors.subscription_plan ? (
                                <FormFeedback type="invalid">{validation.errors.subscription_plan}</FormFeedback>
                            ) : null}
                            <option value=""> Selecione... </option>
                            {PlansData.map((row, index) => (
                                <option key={index} value={row.id}>{row.name}</option>
                            ))}
                        </Input>
                        <Label>
                            Qual plano de assinatura você prefere?
                        </Label>
                    </FormGroup>
                </Col>

                <Col md={12} className="text-center mt-2">
                    <div className="titulo-hr">
                        <span>Qual o melhor horário para agendar reuniões individuais com outros membros</span>
                    </div>
                </Col>
                <Col sm="12" className="mt-4 mb-4">

                    <Row>
                        <Col sm="5" className="text-right">
                            <Row>
                                <Col sm="12" className="mt-1">
                                    Segunda-feira
                                </Col>
                                <Col sm="12">
                                    <span style={{ fontSize: '11px' }}>Horários para agendamento</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm="7">
                            <Row>
                                <Col sm="3">
                                    <FormGroup floating>
                                        <Input
                                            autoComplete={false}
                                            type="time"
                                            disabled={blockInputs ? true : false}
                                            onChange={(event) => { setFormData({ ...FormData, 'time_i_1': event.target.value }); }}
                                            value={FormData.time_i_1 ? FormData.time_i_1 : ''}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.time_i_1 && validation.errors.time_i_1 ? true : false
                                            }
                                        />
                                        <Label>
                                            Horário Inicial
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col sm="3">
                                    <FormGroup floating>
                                        <Input
                                            autoComplete={false}
                                            type="time"
                                            disabled={blockInputs ? true : false}
                                            onChange={(event) => { setFormData({ ...FormData, 'time_f_1': event.target.value }); }}
                                            value={FormData.time_f_1 ? FormData.time_f_1 : ''}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.time_f_1 && validation.errors.time_f_1 ? true : false
                                            }
                                        />
                                        <Label>
                                            Horário Final
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="5" className="text-right">
                            <Row>
                                <Col sm="12" className="mt-1">
                                    Terça-feira
                                </Col>
                                <Col sm="12">
                                    <span style={{ fontSize: '11px' }}>Horários para agendamento</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm="7">
                            <Row>
                                <Col sm="3">
                                    <FormGroup floating>
                                        <Input
                                            autoComplete={false}
                                            type="time"
                                            disabled={blockInputs ? true : false}
                                            onChange={(event) => { setFormData({ ...FormData, 'time_i_2': event.target.value }); }}
                                            value={FormData.time_i_2 ? FormData.time_i_2 : ''}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.time_i_2 && validation.errors.time_i_2 ? true : false
                                            }
                                        />
                                        <Label>
                                            Horário Inicial
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col sm="3">
                                    <FormGroup floating>
                                        <Input
                                            autoComplete={false}
                                            type="time"
                                            disabled={blockInputs ? true : false}
                                            onChange={(event) => { setFormData({ ...FormData, 'time_f_2': event.target.value }); }}
                                            value={FormData.time_f_2 ? FormData.time_f_2 : ''}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.time_f_2 && validation.errors.time_f_2 ? true : false
                                            }
                                        />
                                        <Label>
                                            Horário Final
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="5" className="text-right">
                            <Row>
                                <Col sm="12" className="mt-1">
                                    Quarta-feira
                                </Col>
                                <Col sm="12">
                                    <span style={{ fontSize: '11px' }}>Horários para agendamento</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm="7">
                            <Row>
                                <Col sm="3">
                                    <FormGroup floating>
                                        <Input
                                            autoComplete={false}
                                            type="time"
                                            disabled={blockInputs ? true : false}
                                            onChange={(event) => { setFormData({ ...FormData, 'time_i_3': event.target.value }); }}
                                            value={FormData.time_i_3 ? FormData.time_i_3 : ''}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.time_i_3 && validation.errors.time_i_3 ? true : false
                                            }
                                        />
                                        <Label>
                                            Horário Inicial
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col sm="3">
                                    <FormGroup floating>
                                        <Input
                                            autoComplete={false}
                                            type="time"
                                            disabled={blockInputs ? true : false}
                                            onChange={(event) => { setFormData({ ...FormData, 'time_f_3': event.target.value }); }}
                                            value={FormData.time_f_3 ? FormData.time_f_3 : ''}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.time_f_3 && validation.errors.time_f_3 ? true : false
                                            }
                                        />
                                        <Label>
                                            Horário Final
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="5" className="text-right">
                            <Row>
                                <Col sm="12" className="mt-1">
                                    Quinta-feira
                                </Col>
                                <Col sm="12">
                                    <span style={{ fontSize: '11px' }}>Horários para agendamento</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm="7">
                            <Row>
                                <Col sm="3">
                                    <FormGroup floating>
                                        <Input
                                            autoComplete={false}
                                            type="time"
                                            disabled={blockInputs ? true : false}
                                            onChange={(event) => { setFormData({ ...FormData, 'time_i_4': event.target.value }); }}
                                            value={FormData.time_i_4 ? FormData.time_i_4 : ''}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.time_i_4 && validation.errors.time_i_4 ? true : false
                                            }
                                        />
                                        <Label>
                                            Horário Inicial
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col sm="3">
                                    <FormGroup floating>
                                        <Input
                                            autoComplete={false}
                                            type="time"
                                            disabled={blockInputs ? true : false}
                                            onChange={(event) => { setFormData({ ...FormData, 'time_f_4': event.target.value }); }}
                                            value={FormData.time_f_4 ? FormData.time_f_4 : ''}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.time_f_4 && validation.errors.time_f_4 ? true : false
                                            }
                                        />
                                        <Label>
                                            Horário Final
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="5" className="text-right">
                            <Row>
                                <Col sm="12" className="mt-1">
                                    Sexta-feira
                                </Col>
                                <Col sm="12">
                                    <span style={{ fontSize: '11px' }}>Horários para agendamento</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm="7">
                            <Row>
                                <Col sm="3">
                                    <FormGroup floating>
                                        <Input
                                            autoComplete={false}
                                            type="time"
                                            disabled={blockInputs ? true : false}
                                            onChange={(event) => { setFormData({ ...FormData, 'time_i_5': event.target.value }); }}
                                            value={FormData.time_i_5 ? FormData.time_i_5 : ''}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.time_i_5 && validation.errors.time_i_5 ? true : false
                                            }
                                        />
                                        <Label>
                                            Horário Inicial
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col sm="3">
                                    <FormGroup floating>
                                        <Input
                                            autoComplete={false}
                                            type="time"
                                            disabled={blockInputs ? true : false}
                                            onChange={(event) => { setFormData({ ...FormData, 'time_f_5': event.target.value }); }}
                                            value={FormData.time_f_5 ? FormData.time_f_5 : ''}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.time_f_5 && validation.errors.time_f_5 ? true : false
                                            }
                                        />
                                        <Label>
                                            Horário Final
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Col>
                <Col sm="12">

                </Col>
            </Row>

            {!blockInputs ? (
                <Row className="mt-3 mb-2">
                    <Col xs="6">
                        <Button type="submit" color="success" disabled={Loading ? true : false}>
                            {modalId > 0 ? 'Atualizar' : (
                                textButtonSubmit ? textButtonSubmit : 'Adicionar'
                            )}
                        </Button>
                    </Col>
                    {!disabledBtnClosed ? (
                        <Col sm="6" className="text-right pb-2">
                            <button type="button" className="btn btn-secondary" onClick={toggle2} disabled={Loading ? true : false}>Fechar</button>
                        </Col>
                    ) : null}
                </Row>
            ) : null}

        </Form>
    );
}

ModalAddAdminMembersForm.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    blockInputs: PropTypes.bool.isRequired,
    toggle2: PropTypes.func.isRequired,
    textButtonSubmit: PropTypes.string,
    disabledBtnClosed: PropTypes.string,
    disabledInputSituation: PropTypes.string
}
