import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Form,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Label,
} from "reactstrap";
import ModalBase from '../../../components/modal/modal';
import api from "../../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    showToastArray,
} from '../../../helpers/generals';
import { MaskCellPhone, MaskCpf } from '../../../helpers/mascarasParaCampos';

export default function ModalUsers({
    modalId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [FormData, setFormData] = useState({});
    const [Loading, setLoading] = useState(false);

    const [LevelsData, setLevelsData] = useState([]);    
    const [UnitsData, setUnitsData] = useState([]);

    function LoadUnits() {
        setUnitsData([]);
        api.get('/api/units/active').then(response => {
            return response.data
        }).then(data => {
            if (data.success) {
                setUnitsData(data.data);
            }
        }).catch(error => {
            // console.log(error);
        })
    };

    function LoadLevels() {
        setLevelsData([]);
        api.get('/api/levels/active').then(response => {
            return response.data
        }).then(data => {
            if (data.success) {
                setLevelsData(data.data);
            }
        }).catch(error => {
            // console.log(error);
        })
    };

    useEffect(() => {
        if (modalId > 0) {
            try {
                api.get('/api/users/show/' + modalId).then(response => {
                    return response;
                }).then(data => {                  
                    if (data.data.success) {
                        
                        const formData = { ...FormData };
                        
                        if (data.data.data) {
                            
                            const item = data.data.data;  

                            if (item.type) formData.type = item.levels.slug; 
                            if (item.cpf) formData.cpf = MaskCpf(item.cpf);  
                            if (item.cell_phone) formData.cell_phone = MaskCellPhone(item.cell_phone);  

                            formData.name = item.name;       
                            formData.birth = item.birth;  
                            formData.email = item.email;  
                            formData.is_active = item.is_active;  
                            formData.units_id = item.units_id;  

                        }

                        setFormData(formData);
                        setLoading(false);
                    
                    }    
                }).catch(error => {
                    // console.log(error);
                });
            } catch (error) {
                // console.log(error);
            }
        }
        LoadLevels();
        LoadUnits();
    }, [modalId]);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: FormData.email || '',

            name: FormData.name || '',
            cpf: FormData.cpf || '',
            birth: FormData.birth || '',
            cell_phone: FormData.cell_phone || '',

            type: FormData.type || '',
            units_id: FormData.units_id || '',

            is_active: FormData.is_active || '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Digite esse campo."), 
            name: Yup.string().required("Digite esse campo."),
            // cpf: Yup.string().required("Digite esse campo."),
            birth: Yup.string().required("Digite esse campo."),
            cell_phone: Yup.string().required("Digite esse campo."),
            
            type: Yup.string().required("Selecione um dado."),
            units_id: Yup.string().required("Selecione um dado."),

            is_active: Yup.string().required("Selecione uma opção."),
        }),
        onSubmit: (values) => {
            setLoading(true);
            if (modalId) {
                api.put('/api/users/admin/update/' + modalId, values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success) {
                        showToastArray("", "Sucesso", "Cadastro atualizado com sucesso!");
                        setLoading(false);
                        reloadData();
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });
            } else {
                api.post('/api/users/admin/save', values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success) {
                        showToastArray("", "Sucesso", "Cadastro atualizado com sucesso!");
                        setLoading(false);
                        reloadData();
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });
            }
        },
    });

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={modalId > 0 ? 'Editar | ' + title : 'Adicionar | ' + title}
        >
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Row>
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="is_active"
                                type="select"
                                placeholder="Situação"
                                onChange={(event) => { setFormData({ ...FormData, 'is_active': event.target.value }); }}
                                value={FormData.is_active ? FormData.is_active : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.is_active && validation.errors.is_active ? true : false
                                }
                            >
                                {validation.touched.is_active && validation.errors.is_active ? (
                                    <FormFeedback type="invalid">{validation.errors.is_active}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                <option value="1"> Ativo </option>
                                <option value="0"> Inativo  </option>
                                <option value="8"> Suspenso  </option>
                            </Input>
                            <Label>
                                Situação
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col lg={12}>
                        <FormGroup floating>
                            <Input
                                id="type"
                                className="type"
                                name="type"
                                type="select"
                                onChange={(event) => {
                                    setFormData({ ...FormData, 'type': event.target.value });
                                }}
                                value={FormData.type ? FormData.type : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.type && validation.errors.type ? true : false
                                }
                            >
                                {validation.touched.type && validation.errors.type ? (
                                    <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                {LevelsData.map((row, index) => (
                                    row.slug != 'member' ? (<option key={index} value={row.slug}>{row.name}</option>) : null
                                ))}
                            </Input>
                            <Label>
                                Nível
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col lg="12">
                        <FormGroup floating>
                            <Input
                                id="units_id"
                                name="units_id"
                                type="select"
                                onChange={(event) => {
                                    setFormData({ ...FormData, 'units_id': event.target.value });
                                }}
                                value={FormData.units_id ? FormData.units_id : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.units_id && validation.errors.units_id ? true : false
                                }
                            >
                                {validation.touched.units_id && validation.errors.units_id ? (
                                    <FormFeedback type="invalid">{validation.errors.units_id}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                {UnitsData.map((row, index) => (
                                    <option key={index} value={row.id}>{row.name}</option>
                                ))}
                            </Input>
                            <Label>
                                Unidade
                            </Label>
                        </FormGroup>
                    </Col>                    
                    <Col md={12} className="text-center mt-2">
                        <div className="titulo-hr">
                            <span> Dados do registro </span>
                        </div>
                    </Col>
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="email"
                                type="email"
                                maxLength={255}
                                onChange={(event) => { setFormData({ ...FormData, 'email': event.target.value }); }}
                                value={FormData.email ? FormData.email : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.email && validation.errors.email ? true : false
                                }
                            />
                            {validation.touched.email && validation.errors.email ? (
                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                            <Label>
                                Email
                            </Label>
                        </FormGroup>
                    </Col>                       
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="name"
                                type="text"
                                maxLength={255}
                                onChange={(event) => { setFormData({ ...FormData, 'name': event.target.value }); }}
                                value={FormData.name ? FormData.name : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.name && validation.errors.name ? true : false
                                }
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                            ) : null}
                            <Label>
                                Nome
                            </Label>
                        </FormGroup>
                    </Col>   
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="cpf"
                                type="text"
                                maxLength={255}
                                onChange={(event) => { setFormData({ ...FormData, 'cpf': MaskCpf(event.target.value) }); }}
                                value={FormData.cpf ? FormData.cpf : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.cpf && validation.errors.cpf ? true : false
                                }
                            />
                            {validation.touched.cpf && validation.errors.cpf ? (
                                <FormFeedback type="invalid">{validation.errors.cpf}</FormFeedback>
                            ) : null}
                            <Label>
                                CPF
                            </Label>
                        </FormGroup>
                    </Col>    
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="birth"
                                type="date"
                                onChange={(event) => { setFormData({ ...FormData, 'birth': event.target.value }); }}
                                value={FormData.birth ? FormData.birth : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.birth && validation.errors.birth ? true : false
                                }
                            />
                            {validation.touched.birth && validation.errors.birth ? (
                                <FormFeedback type="invalid">{validation.errors.birth}</FormFeedback>
                            ) : null}
                            <Label>
                                Nascimento
                            </Label>
                        </FormGroup>
                    </Col> 
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="cell_phone"
                                type="text"
                                onChange={(event) => { setFormData({ ...FormData, 'cell_phone': MaskCellPhone(event.target.value) }); }}
                                value={FormData.cell_phone ? FormData.cell_phone : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.cell_phone && validation.errors.cell_phone ? true : false
                                }
                            />
                            {validation.touched.cell_phone && validation.errors.cell_phone ? (
                                <FormFeedback type="invalid">{validation.errors.cell_phone}</FormFeedback>
                            ) : null}
                            <Label>
                                Celular
                            </Label>
                        </FormGroup>
                    </Col>                                                          
                </Row>
                <Row className="mt-3 mb-2">
                    <Col xs="6">
                        <Button type="submit" color="success" disabled={Loading ? true : false}>
                            {modalId > 0 ? 'Atualizar' : 'Adicionar'}
                        </Button>
                    </Col>
                    <Col xs="6" className="text-right pb-2">
                        <button type="button" className="btn btn-secondary" onClick={toggle} disabled={Loading ? true : false}>Fechar</button>
                    </Col>
                </Row>
            </Form>
        </ModalBase>
    );
}

ModalUsers.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
