import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Form,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Label,
} from "reactstrap";
import ModalBase from '../../../../../components/modal/modal';
import api from "../../../../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    showToastArray,
} from '../../../../../helpers/generals';
import { DateToData, formatarMilharReais } from '../../../../../helpers/mascarasParaCampos';

export default function ModalInterviewAdminMembersPaymentsView({
    modalId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [Loading, setLoading] = useState(false);

    const [status, setStatus] = useState(0);
    const [contractCharges, setContractCharges] = useState([]);
    const [contract, setContract] = useState({});

    const LoadDataShow = async => {
        if (modalId > 0) {
            try {
                api.get('/api/charges/show/' + modalId).then(response => {
                    return response;
                }).then(data => {
                    if (data.data.success) {

                        const formData = { ...FormData };

                        if (data.data.data) {
                            console.log('eu', data.data.data);
                            const item = data.data.data;
                            setContract(item);
                            setContractCharges(item.contracts.charges);
                        }


                        setLoading(false);
                    }
                }).catch(error => {
                    // console.log(error);
                });
            } catch (error) {
                // console.log(error);
            }
        }
    };

    useEffect(() => {
        LoadDataShow();
    }, [modalId]);

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={'Detalhes | ' + title}
        >
            <Row>
                <Col sm="12" className="m-0 p-0">
                    <hr className="m-0 p-0" />
                </Col>
                <Col sm="12" className="mt-3 mb-2">
                    <Col sm="12">
                        <Row>
                            <Col sm="6" className="mb-2">
                                Plano:<br />
                                <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                    {contract && contract.plans ? contract.plans.name : '-'}
                                </div>
                            </Col>
                            <Col sm="6" className="mb-2">
                                Forma de Pagamento:<br />
                                <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                    {contract && contract.methods_payments ? contract.methods_payments.name : '-'}
                                </div>
                            </Col>
                            <Col sm="6" className="mb-2">
                                Serviço:<br />
                                <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                    {contract && contract.services ? contract.services.name : '-'}
                                </div>
                            </Col>
                            <Col sm="6" className="mb-2">
                                Valor total:<br />
                                <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                    {contract && contract.amount ? 'R$ ' + formatarMilharReais(contract.amount) : '-'}
                                </div>
                            </Col>
                        </Row>
                    </Col>

                </Col>
                <Col sm="12" className="m-0 p-0">
                    <hr className="m-0 p-0" />
                </Col>
                <Col sm="12" className="mt-3 mb-2">
                    <h6>Mensalidade(s):</h6>
                </Col>
                {contractCharges && contractCharges.length > 0 ? (
                    contractCharges.map((row, index) => (
                        <Col key={index} sm="12" className="mb-2">
                            <Row>
                                <Col sm="1">
                                    {row.parcel ? row.parcel + '/' + row.total_parcels : '-'}
                                </Col>
                                <Col sm="11">
                                    <Row>
                                        <Col sm="3">
                                            {row.due_date ? DateToData(row.due_date, "/") : '-'}
                                        </Col>
                                        <Col sm="3">
                                            {row.value ? 'R$ ' + formatarMilharReais(row.value) : 'R$ -'}
                                        </Col>
                                        <Col sm="3">
                                            {row.payday ? DateToData(row.payday, "/") : '-'}
                                        </Col>
                                        <Col sm="3">
                                            {row.amount_paid ? 'R$ ' + formatarMilharReais(row.amount_paid) : 'R$ -'}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    ))
                ) : null}
            </Row>
        </ModalBase>
    );
}

ModalInterviewAdminMembersPaymentsView.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
