import PropTypes from "prop-types";
import React, { useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Spinner } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link } from 'react-router-dom';
import Logo from '../../../assets/images/logo.png';
import api from "../../../services/api";

import { showToastArray, showToastErrorDefault } from '../../../helpers/generals';

const ForgotPassword = props => {

  document.title = "Esqueci minha senha | Empresários SA";

  const [Send, setSend] = useState(false);
  const [FormData, setFormData] = useState({});

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: FormData.email || '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Por favor digite o seu e-mail")
    }),
    onSubmit: (values) => {
      setSend(true);
      api.post('/api/forgot-password', values).then(response => {
        return response.data;
      }).then(data => {
        if(data.success == true) {
          setSend(false);
          setFormData({});
          showToastArray("", "Sucesso!", "Sua nova senha enviada para seu e-mail.");
        } 
      }).catch(error => {
        if (error) {
          let message = 'Erro ao enviar o registro!';
          setSend(false);
          if (error.response) {
            if (error.response.data) message = error.response.data.message;
          }
          showToastErrorDefault(message);
        }
      });
    }
  });


  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-softbg-soft-primary">
                  <Row>
                    <Col xs={12} className="mb-1 mt-5 text-center">
                      <img src={Logo} alt="" height="40px" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2 pt-5">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">E-mail</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Digite seu e-mail"
                          type="email"
                          onChange={(event) => { setFormData({ ...FormData, 'email': event.target.value }); }}
                          value={FormData.email ? FormData.email : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>                    
                      <Row className="mb-3">
                        <Col className="text-end">
                          {Send ? (
                            <Spinner color="info"></Spinner>
                          ) : (
                            <button
                              className="btn btn-primary w-md "
                              type="submit"
                            >
                              Resetar senha
                            </button>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Voltar para tela de{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ForgotPassword;