import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col, CardBody, Card, Container, Spinner } from "reactstrap";
import Logo from '../../../assets/images/logo.png';
import api from "../../../services/api";

const Logout = props => {

  const navigate = useNavigate();

  document.title = "Logout | Empresários SA";

  useEffect(() => {
    api.get('/api/users/logout').then(response => {
      return response.data;
    }).then(data => {
      localStorage.removeItem("Token");
      localStorage.removeItem("UserAvatar");
      localStorage.removeItem("UserLogo");
      localStorage.removeItem("Email");
      localStorage.removeItem("UserName");
      localStorage.removeItem("UserID");
      localStorage.removeItem("UserType");
      localStorage.removeItem("authUser");
      localStorage.removeItem("UserEnter");
      localStorage.removeItem("UserTheme");
      localStorage.removeItem("UserLevel");
      localStorage.removeItem("I18N_LANGUAGE");
      localStorage.removeItem("i18nextLng");
      navigate("/login");
    }).catch(error => {
      setTimeout(() => {
        localStorage.removeItem("Token");
        localStorage.removeItem("UserAvatar");
        localStorage.removeItem("UserLogo");
        localStorage.removeItem("Email");
        localStorage.removeItem("UserName");
        localStorage.removeItem("UserID");
        localStorage.removeItem("UserType");
        localStorage.removeItem("authUser");
        localStorage.removeItem("UserEnter");
        localStorage.removeItem("UserTheme");
        localStorage.removeItem("UserLevel");
        localStorage.removeItem("I18N_LANGUAGE");
        localStorage.removeItem("i18nextLng");
        navigate("/login");
      }, 1000);
    });
  }, []);

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft">
                  <Row>
                    <Col xs={12} className="mb-5 mt-5 text-center">
                      <img src={Logo} alt="" height="40px" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="pt-3 pb-4 text-center">
                    <Spinner></Spinner>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Logout;