import React, { useState, useEffect } from "react";
import {
  useNavigate,
} from 'react-router-dom';
import api from "../../../../services/api";
import {
  Row,
  Col,
  Container,
  Button,
  Table,
  Spinner,
  Badge,
  FormGroup,
  Input,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ContentLoader from "react-content-loader";
import LayoutHeader from "../../../../components/header";
import ModalAddAdminMembers from "./modal/add";
import {
  formatDataToDate
} from '../../../../helpers/mascarasParaCampos';
import SwalDeleteItem from "../../../../helpers/swal";
import ModalInterviewAdminMembers from "./modal/interview";
import ModalInterviewAdminMembersPayments from "./modal/payments";
import AdminMembersApprovals from "../approvals";
import ModalInterviewAdminMembersView from "../approvals/modal/view";

const AdminMembers = props => {

  const navigate = useNavigate();

  let NameModule = 'Membros';

  document.title = NameModule + " | Empresários SA";

  const [Loading, setLoading] = useState(true);

  const [RowData, setRowData] = useState([]);
  const [BranchesData, setBranchesData] = useState([]);
  const [UnitsData, setUnitsData] = useState([]);
  const [PlansData, setPlansData] = useState([]);

  const [Pages, setPages] = useState([]);
  const [PaginationTotalItems, setPaginationTotalItems] = useState(1);
  const [PaginationPageActual, setPaginationPageActual] = useState(1);

  var month = new Date().getMonth() + 1;
  if (month < 10) {
    month = '0' + month;
  }
  var year = new Date().getFullYear();

  const [FormData, setFormData] = useState({
    "competence": year + '-' + month
  });

  const [SwalOpen, setSwalOpen] = useState('');  
  const [ModalOpen, setModalOpen] = useState(false);
  const [Modal, setModal] = useState('');
  const [ModalId, setModalId] = useState(0);

  function LoadData(Page) {

    setLoading(true);
    setRowData([]);

    try {
      const Data = {
        "status": FormData.status || '',
        "branches": FormData.branches || '',
        "units": FormData.units || '', 
        "plans": FormData.plans || '',
        "search": FormData.search || '',
      }
      setPaginationPageActual(Page);
      api.post('/api/users/filter?page=' + Page, Data).then(response => {
        return response;
      }).then(data => {
        setRowData(data.data);
        setPaginationTotalItems(data.data.total);
        listItems(data.data.total, data.data.per_page);
        setLoading(false);
      }).catch(error => {
        // console.log(error);
      });
    } catch (error) {
      // console.log(error);
    }
  }

  function LoadBranches() {
    setBranchesData([]);
    api.get('/api/branches/active').then(response => {
      return response.data
    }).then(data => {
      if (data.success === true) {
        setBranchesData(data.data);
      }
    }).catch(error => {
      // console.log(error);
    })
  };

  function LoadUnits() {
    setUnitsData([]);
    api.get('/api/units/active').then(response => {
      return response.data
    }).then(data => {
      if (data.success === true) {
        setUnitsData(data.data);
      }
    }).catch(error => {
      // console.log(error);
    })
  };

  function LoadPlans() {
    setPlansData([]);
    api.get('/api/plans/active').then(response => {
      return response.data
    }).then(data => { 
      if (data.success === true) {
        setPlansData(data.data);
      }
    }).catch(error => {
      // console.log(error);
    })
  };  

  useEffect(() => {
    LoadData(1);
    LoadBranches();
    LoadUnits();
    LoadPlans();
  }, []);

  function reloadData() {
    LoadData(1);
    LoadBranches();
    LoadUnits();
    LoadPlans();
    setModalOpen(false);
    setModal('');
    setSwalOpen('');
    setModalId(0);
  }

  function listItems(total, PorPage) {
    let totalPages = 1;
    let asdasdsa = [];
    if (PorPage < total) {
      totalPages = total / PorPage;
    }
    for (let a = 1; a < totalPages + 1; a++) {
      asdasdsa.push([a]);
    }
    setPages(asdasdsa); 
  }

  function PaginationOpen(Page) {
    LoadData(Page);
  }

  return (
    <React.Fragment>

      <LayoutHeader isAdmin={true} show={true} />

      {Modal == 'add' || Modal == 'ver' ? (<ModalAddAdminMembers
        toggle={() => setModalOpen(!ModalOpen)}
        isOpen={ModalOpen}
        modalId={ModalId}
        size="xl"
        title={NameModule}
        reloadData={reloadData}
      />) : ''}

      {Modal == 'entrevista' ? (<ModalInterviewAdminMembers
        toggle={() => setModalOpen(!ModalOpen)}
        isOpen={ModalOpen}
        modalId={ModalId}
        size=""
        title={NameModule}
        reloadData={reloadData}
      />) : ''}      

      {Modal == 'ver-entrevista' ? (<ModalInterviewAdminMembersView
          toggle={() => setModalOpen(!ModalOpen)}
          isOpen={ModalOpen}
          membersId={ModalId}
          size="xl"
          title="Aprovação/Entrevista"
          reloadData={reloadData}
      />) : ''}            

      {Modal == 'pagamentos' ? (<ModalInterviewAdminMembersPayments
        toggle={() => setModalOpen(!ModalOpen)}
        isOpen={ModalOpen}
        modalId={ModalId}
        size="" 
        title={NameModule}
        reloadData={reloadData}
      />) : ''}      

      {SwalOpen == 'cancelar' ? (<SwalDeleteItem
        PathApi="/api/users/cancel/"
        DeleteId={ModalId}
        title = "Você quer realmente CANCELAR esse membro?"
        text = "Atenção! Está ação não tem mais volta."
        isConfirmedTitle = "Membro cancelado"
        isConfirmedText = "Esse membro foi cancelado da sua base."
        reloadData={reloadData}
      />) : ''}

      {SwalOpen == 'AtivarInativa' ? (<SwalDeleteItem
        PathApi="/api/units/activation/"
        DeleteId={ModalId}
        title = "Você quer realmente ATIVAR esse membro?"
        text = "Atenção! Está ação não tem mais volta."
        isConfirmedTitle = "Membro ativado"
        isConfirmedText = "Esse membro ativado em sua base."
        reloadData={reloadData}
      />) : ''}      

      <div className="px-3 pt-1 pb-5">
        <Row className="pt-3910201290">

          <Col md={12} className="content-box">
            <Row>
              <Col md={12} className="text-center">
                <div className="titulo-hr">
                  <span>{NameModule}</span>
                </div>
              </Col>

              <Col lg={12}>
                <Row>
                  <Col lg={2}>
                    <FormGroup floating>
                      <Input
                        id="status"
                        className="status"
                        name="status"
                        type="select"
                        onChange={(event) => {
                          setFormData({ ...FormData, 'status': event.target.value });
                        }}
                        value={FormData.status ? FormData.status : ''}
                      >
                        <option value=""> Todos </option>
                        <option value="1111">Criado Externo</option>
                        <option value="1">Criado</option>
                        <option value="2">Para entrevistar</option>
                        <option value="3">Entrevistado</option>
                        <option value="4">Aprovado</option>
                        <option value="6">Ativo e em dia</option>
                        <option value="7">Inadimplente</option>
                        <option value="9">Cancelado</option>
                      </Input>
                      <Label>
                        Status
                      </Label>
                    </FormGroup>
                  </Col>                  
                  <Col lg={2}>
                    <FormGroup floating>
                      <Input
                        id="branches"
                        className="branches"
                        name="branches"
                        type="select"
                        onChange={(event) => {
                          setFormData({ ...FormData, 'branches': event.target.value });
                        }}
                        value={FormData.branches ? FormData.branches : ''}
                      >
                        <option value=""> Todos </option>
                        {BranchesData.map((row, index) => (
                          <option key={index} value={row.id}>{row.name}</option>
                        ))}
                      </Input>
                      <Label>
                        Ramo de Nengócio
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg={2}>
                    <FormGroup floating>
                      <Input
                        id="units"
                        className="units"
                        name="units"
                        type="select"
                        onChange={(event) => {
                          setFormData({ ...FormData, 'units': event.target.value });
                        }}
                        value={FormData.units ? FormData.units : ''}
                      >
                        <option value=""> Todos </option>
                        {UnitsData.map((row, index) => (
                          <option key={index} value={row.id}>{row.name}</option>
                        ))}
                      </Input>
                      <Label>
                        Unidade
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg={2}>
                    <FormGroup floating>
                      <Input
                        id="plans"
                        className="plans"
                        name="plans"
                        type="select"
                        onChange={(event) => {
                          setFormData({ ...FormData, 'plans': event.target.value });
                        }}
                        value={FormData.plans ? FormData.plans : ''}
                      >
                        <option value=""> Todos </option>
                        {PlansData.map((row, index) => (
                          <option key={index} value={row.id}>{row.name}</option>
                        ))}
                      </Input>
                      <Label>
                         Plano
                      </Label>
                    </FormGroup>
                  </Col>                  
                  <Col lg={2}>
                    <FormGroup floating>
                      <Input
                        id="search"
                        className="search"
                        name="search"
                        type="search"
                        placeholder="Especilidade"
                        onChange={(event) => { setFormData({ ...FormData, 'search': event.target.value }); }}
                        value={FormData.search ? FormData.search : ''}
                      />
                      <Label>
                        Buscar
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg={1} className="text-center">
                    <Button
                      className="btnFilter"
                      onClick={() => {
                        LoadData();
                      }}
                    >
                      Filtrar
                    </Button>
                  </Col>
                  <Col lg={1} className="text-center">
                    <Button
                      className="btnFilter"
                      color="success"
                      onClick={() => {
                        setModal('add');
                        setModalOpen(true);
                        setSwalOpen('');
                        setModalId(0);
                      }}
                    >
                      Adicionar
                    </Button>
                  </Col>
                </Row>
              </Col>

            </Row>
          </Col>
          <Col md={12} className="content-box">
            <Row className="mb-3">
              <Col md={12}>
                <div className="boxes">
                  <Table responsive size="sm" className="table">
                    <thead>
                      <th></th>
                      <th> Status s </th>
                      <th> Nome </th>
                      <th> CPF </th>
                      <th> Celular </th>                      
                      <th> E-mail </th> 
                      <th> Unidade </th>
                      <th> Plano </th>
                      <th> Pontuação </th>
                      <th> </th>
                      <th> ID </th>
                      <th> Cadastro </th>
                      <th> Ativação </th>
                    </thead>
                    <tbody>

                      {Loading ? (
                        <tr>
                          <td colSpan={50} style={{ border: '0px' }}>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                              <Spinner color="info" className="mt-5 mb-2"></Spinner>
                              <br />
                              Carregando dados...
                            </div>
                          </td>
                        </tr>
                      ) : null}

                      {!Loading && RowData.results === 0 ? (
                        <tr>
                          <td colSpan={50} style={{ border: '0px', paddingTop: '30px', textAlign: 'center' }}>
                            Sem dados com esses filtros no momento.
                          </td>
                        </tr>
                      ) : null}

                      {RowData && !Loading ? (
                        RowData.data.map((row, index) => (
                          <tr key={index}>
                            <td style={{ width: '10px', textAlign: 'center' }}>
                              <UncontrolledDropdown
                                className="me-2"
                                direction="down"
                              >
                                <DropdownToggle
                                  caret
                                  color="dark"
                                  size="sm"
                                  style={{ padding: '1px 5px' }}
                                >
                                  Acões
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem header>
                                    Selecione uma ação
                                  </DropdownItem>
                                  <DropdownItem onClick={(event) => {
                                    setModal('ver');
                                    setModalOpen(true);                                    
                                    setSwalOpen('');
                                    setModalId(row.id);
                                  }}>
                                    Ver / Editar
                                  </DropdownItem>
                                  <DropdownItem onClick={(event) => {
                                    window.open('/m/' + row.slug, "_blank")
                                  }}>
                                    Acessar página membro
                                  </DropdownItem>                                     
                                  <DropdownItem divider />  
                                  {row.status < 2 || row.status > 1000 ? (
                                    <DropdownItem onClick={(event) => {
                                      setModal('entrevista');
                                      setModalOpen(true);                                        
                                      setSwalOpen('');
                                      setModalId(row.id);
                                    }}>
                                      Para entrevistar 
                                    </DropdownItem>  
                                  ) : null} 
                                  {row.status > 1 && row.status != 1111 ? (                                  
                                    <DropdownItem onClick={(event) => {
                                      setModal('ver-entrevista');
                                      setModalOpen(true);                                      
                                      setSwalOpen('');
                                      setModalId(row.id);
                                    }}>
                                      Ver entrevista 
                                    </DropdownItem>
                                  ) : null} 
                                  {row.status > 3 && row.status < 9 ? (
                                      <>
                                        <DropdownItem divider />                                                                 
                                          <DropdownItem onClick={(event) => { 
                                            setModal('pagamentos');
                                            setModalOpen(true);                                         
                                            setSwalOpen('');
                                            setModalId(row.id);
                                          }}>
                                            Pagamento(s)
                                        </DropdownItem>    
                                      </>                                  
                                  ) : null}                                                                 
                                  <DropdownItem divider />
                                  <DropdownItem onClick={(event) => {
                                    setModal('');
                                    setModalOpen(false);
                                    setSwalOpen('cancelar');
                                    setModalId(row.id);
                                  }}>
                                    Cancelar
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                            <td>
                              {row.status == 1111 ? 'Criado Externo' : ''}
                              {row.status == 0 || row.status == 1 ? 'Criado Interno' : ''}
                              {row.status == 2 ? 'Para entrevistar' : ''}
                              {row.status == 3 ? 'Entrevistado' : ''}
                              {row.status == 4 ? 'Aprovado' : ''}
                              {row.status == 6 ? 'Ativo e em dia' : ''}
                              {row.status == 7 ? 'Inadimplente' : ''}
                              {row.status == 9 ? 'Cancelado' : ''}
                            </td>                            
                            <td>
                              {row.name ? row.name : '-' }
                            </td>
                            <td>
                              {row.cpf ? row.cpf : '-' }
                            </td>
                            <td>
                              {row.cell_phone ? row.cell_phone : '-' }
                            </td>
                            <td>
                              {row.email ? row.email : '-' }
                            </td>
                            <td>
                              {row.units ? row.units.name : '-' }
                            </td>
                            <td>
                              {row.plans ? row.plans.name : '-' }
                            </td>
                            <td>
                              0
                            </td>
                            <td>
                              {row.is_active == 1 ? (
                                <Badge color="success">
                                  <i class='bx bx-like'></i>
                                </Badge>
                              ) : (
                                <Badge color="danger">
                                  <i class='bx bx-dislike'></i>
                                </Badge>
                              )}
                            </td>
                            <th scope="row" style={{ width: '30px' }} >
                              {row.id ? row.id : '-' }
                            </th>
                            <td>
                              {row.created_at ? row.created_at : '-' }
                            </td>
                            <td>
                              {row.activation_at ? row.activation_at : '-' }
                            </td>
                          </tr>
                        ))
                      ) : null}

                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col md={9}>
                <div className="TablePagination">
                  Mostrando página <strong>{PaginationPageActual}</strong> de <strong>1</strong>. | Total de registros é de <strong>{PaginationTotalItems}</strong>.
                </div>
              </Col>
              <Col md={3}>
                <FormGroup row className="text-right">
                  <Label
                    for="exampleEmail"
                    sm={4}
                  >
                    Página
                  </Label>
                  <Col sm={8}>
                    <Input
                      className=""
                      autoComplete={false}
                      name="companie_taxation"
                      type="select"
                      placeholder="Tributação"
                      onChange={(event) => { PaginationOpen(event.target.value); }}
                    >
                      {Pages.map((row) => (
                        <option key={row} value={row} > {row} </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default AdminMembers;