import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Form,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Label,
    Spinner,
} from "reactstrap";
import ModalBase from '../../../../../components/modal/modal';
import api from "../../../../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    showToastArray,
} from '../../../../../helpers/generals';

export default function AdminMembersApprovalsReschedule({
    modalId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [FormData, setFormData] = useState({});
    const [Loading, setLoading] = useState(false);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            date: FormData.date || '',
            time: FormData.time || '',
        },
        validationSchema: Yup.object({
            date: Yup.string().required("Digite uma data."),          
            time: Yup.string().required("Digite um horário."),          
        }),
        onSubmit: (values) => {
            setLoading(true);
            api.put('/api/interviews/reschedule/' + modalId, values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success == true) {
                        showToastArray("", "Sucesso", "Cadastro atualizado com sucesso!");
                        setLoading(false);
                        reloadData();
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });

        },
    });

    useEffect(() => {

    }, [modalId]); 

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={'Remarcar | ' + title}
        >
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Row>
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="date"
                                type="date"
                                onChange={(event) => { setFormData({ ...FormData, 'date': event.target.value }); }}
                                value={FormData.date ? FormData.date : ''}
                                onBlur={validation.handleBlur}
                                min={new Date().toJSON().slice(0, 10)}
                                invalid={
                                    validation.touched.date && validation.errors.date ? true : false
                                }
                            />
                            {validation.touched.date && validation.errors.date ? (
                                <FormFeedback type="invalid">{validation.errors.date}</FormFeedback>
                            ) : null}                                
                            <Label>
                                Data
                            </Label>    
                        </FormGroup>
                    </Col>  
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="time"
                                type="time"
                                onChange={(event) => { setFormData({ ...FormData, 'time': event.target.value }); }}
                                value={FormData.time ? FormData.time : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.time && validation.errors.time ? true : false
                                }
                            />
                            {validation.touched.time && validation.errors.time ? (
                                <FormFeedback type="invalid">{validation.errors.time}</FormFeedback>
                            ) : null}                                
                            <Label>
                                Horário
                            </Label>    
                        </FormGroup>
                    </Col>                                         
                </Row>

                <Row className="mt-3 mb-2">
                    <Col sm="6">
                        <Button type="submit" color="success" disabled={Loading ? true : false }>
                            {Loading ? <Spinner size="sm"/> : 'Atualizar' }
                        </Button>
                    </Col>                    
                    <Col xs="6" className="text-right pb-2">
                        <button type="button" className="btn btn-secondary" onClick={toggle} disabled={Loading ? true : false }>Fechar</button>
                    </Col>
                </Row>
            </Form>
        </ModalBase>
    );
}

AdminMembersApprovalsReschedule.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
