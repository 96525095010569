import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Form,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Label,
} from "reactstrap";
import ModalBase from '../../../../../components/modal/modal';
import api from "../../../../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
   showToastArray, 
} from '../../../../../helpers/generals';

export default function ModalInterviewAdminMembersInterview({
    modalId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [FormData, setFormData] = useState({});
    const [UsersData, setUsersData] = useState([]);
    const [Loading, setLoading] = useState(false);

    function LoadUsers() {
        setUsersData([]);
        api.get('/api/users/active/to/interview').then(response => {
          return response.data
        }).then(data => {
          if (data.success === true) {
            setUsersData(data.data);
          }
        }).catch(error => {
          // console.log(error);
        })
    };  

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            users: FormData.users || '',
        },
        validationSchema: Yup.object({
            users: Yup.string().required("Selecione um usuário."),          
        }),
        onSubmit: (values) => {
            setLoading(true);

                api.put('/api/interviews/to/interview/' + modalId, values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success == true) {
                        showToastArray("", "Sucesso", "Cadastro atualizado com sucesso!");
                        setLoading(false);
                        reloadData();
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });

        },
    });

    useEffect(() => {
        LoadUsers();
      }, []); 

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={ 'Para entrevistar | ' + title }
        >
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Row>
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="users"
                                type="select"
                                onChange={(event) => { setFormData({ ...FormData, 'users': event.target.value }); }}
                                value={FormData.users ? FormData.users : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.users && validation.errors.users ? true : false
                                }
                            >
                                {validation.touched.users && validation.errors.users ? (
                                    <FormFeedback type="invalid">{validation.errors.users}</FormFeedback>
                                ) : null}                                
                                <option value=""> Selecione... </option>
                                {UsersData.map((row, index) => (
                                    <option key={index} value={row.id}>{row.name}</option>
                                ))}
                            </Input>
                            <Label>
                                Selecione o usuário para realizar a entrevista:			        
                            </Label>    
                        </FormGroup>
                    </Col>                     
                </Row>

                <Row className="mt-3 mb-2">
                    <Col sm="6">
                        <Button type="submit" color="success" disabled={Loading ? true : false }>
                            {modalId > 0 ? 'Atualizar' : 'Adicionar' }
                        </Button>
                    </Col>                    
                    <Col xs="6" className="text-right pb-2">
                        <button type="button" className="btn btn-secondary" onClick={toggle} disabled={Loading ? true : false }>Fechar</button>
                    </Col>
                </Row>
            </Form>
        </ModalBase>
    );
}

ModalInterviewAdminMembersInterview.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
