import PropTypes from 'prop-types';
import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
} from 'reactstrap';
import { Link } from "react-router-dom";
import api from '../services/api';

export default function LayoutSideBar({
    show,
    isAdmin,
}) {

    const [RowDataEvents, setRowDataEvents] = useState([]);
    const [RowDataUsers, setRowDataUsers] = useState([]);
    const [RowDataPoints, setRowDataPoints] = useState([]);

    const [Loading, setLoading] = useState(true);

    const LoadDataEvents = async => {
        setRowDataEvents([]);
        const res = api.get('/api/events/last').then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setRowDataEvents(data.data);
                setLoading(false);
            }
        }).catch(error => {
            // console.log(error);
        })
    };

    const LoadDataUsers = async => {
        setRowDataUsers([]);
        const res = api.get('/api/users/ranking').then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setRowDataUsers(data.data);
                setLoading(false);
            }
        }).catch(error => {
            // console.log(error);
        })
    };

    const LoadDataPoints = async => {
        setRowDataPoints([]);
        const res = api.get('/api/points/last').then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setRowDataPoints(data.data);
            }
        }).catch(error => {
            // console.log(error);
        })
    };    

    useEffect(() => {
        LoadDataEvents();
        LoadDataPoints();
        LoadDataUsers();
    }, []);

    return (
        <React.Fragment>
            <>
                <Col md={12}>
                    <div className="boxes sidebar-agenda">
                        <Row>
                            <Col md={12}>
                                <h2>Próximos Eventos</h2>
                            </Col>
                            <Col md={12} className="sidebar-box">
                                <hr />
                            </Col>
                            {RowDataEvents.map((row, index) => (
                                <>
                                    <Col md={12} key={index}>
                                        <Row>
                                            <Col xs={3}>
                                                <div className="agenda-data-box">
                                                    <div className="agenda-data-dia">{row.day}</div>
                                                    <div className="agenda-data-mes">{row.month}</div>
                                                </div>
                                            </Col>
                                            <Col xs={9}>
                                                <Row>
                                                    <Col md={12}>
                                                        <Link to={`/all/events/${row.id}`}>                                                     
                                                            <h3>{row.name}</h3>
                                                        </Link>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Link to={`/all/events/${row.id}`}>                                                        
                                                            <p>{row.local}</p>
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12} className="sidebar-box">
                                        <hr />
                                    </Col>
                                </>
                            ))}
                            <Col md={12} className="text-center">
                                <Link to="/all/events">
                                    Ver mais 
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col md={12}>
                    <div className="boxes sidebar-pontuacao">
                        <Row>
                            <Col md={12}>
                                <h2>Pontuação</h2>
                            </Col>
                            <Col md={12} className="sidebar-box">
                                <hr />
                            </Col>
                            {RowDataPoints && RowDataPoints.length > 0 ? (
                                RowDataPoints.map((row, index) => (
                                    <>
                                        <Col md={12} className="sidebar-box" key={index}>
                                            <Row>
                                                <Col xs={3}>
                                                    <img alt="" class="sidebar-avatar" src={"https://api.empresariossa.com/storage/images/avatar/" + row.avatar} />
                                                </Col>
                                                <Col xs={9}>
                                                    <Row>
                                                        <Col md={12}>
                                                            <h3>{row.name}</h3>
                                                        </Col>
                                                        <Col md={12}>
                                                            <p>{row.totalPoints} ponto(s)</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={12} className="sidebar-box">
                                            <hr />
                                        </Col>
                                    </>
                                ))
                            ) : null}
                            <Col md={12} className="text-center">
                                <Link to="/all/points">
                                    Ver mais
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </>

        </React.Fragment>
    );
}

LayoutSideBar.propTypes = {
    show: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
}
