import React, { useState, useEffect } from "react";
import {
  useNavigate,
} from 'react-router-dom';
import api from "../../../../services/api";
import {
  Row,
  Col,
  Button,
  Table,
  Spinner,
  Badge,
  FormGroup,
  Input,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import LayoutHeader from "../../../../components/header";

import AdminMembersApprovalsSchedule from "./modal/schedule";
import AdminMembersApprovalsReschedule from "./modal/reschedule";
import AdminMembersApprovalsCancel from "./modal/cancel";
import ModalInterviewAdminMembersInterview from "./modal/interview";
import AdminMembersApprovalsChangeInterviewer from "./modal/change-interviewer";
import AdminMembersApprovalsApprove from "./modal/approve";
import { DateToData } from "../../../../helpers/mascarasParaCampos";
import ModalInterviewAdminMembersView from "./modal/view";

const AdminMembersApprovals = props => {

  const navigate = useNavigate();

  let NameModule = 'Aprovação/Entrevista';

  document.title = NameModule + " | Empresários SA";

  const [Loading, setLoading] = useState(true);

  const [RowData, setRowData] = useState([]);
  const [BranchesData, setBranchesData] = useState([]);
  const [UnitsData, setUnitsData] = useState([]);
  const [PlansData, setPlansData] = useState([]);

  const [Pages, setPages] = useState([]);
  const [PaginationTotalItems, setPaginationTotalItems] = useState(1);
  const [PaginationPageActual, setPaginationPageActual] = useState(1);

  var month = new Date().getMonth() + 1;
  if (month < 10) {
    month = '0' + month;
  }
  var year = new Date().getFullYear();

  const [FormData, setFormData] = useState({
    "competence": year + '-' + month
  });

  const [ModalOpen, setModalOpen] = useState(false);
  const [Modal, setModal] = useState('');
  const [ModalId, setModalId] = useState(0);

  function LoadData(Page) {

    setLoading(true);
    setRowData([]);

    try {
      const Data = {
        "status": FormData.status || '',
        "branches": FormData.branches || '',
        "units": FormData.units || '',
        "plans": FormData.plans || '',
        "search": FormData.search || '',
      }
      setPaginationPageActual(Page);
      api.post('/api/interviews/filter?page=' + Page, Data).then(response => {
        return response;
      }).then(data => {
        setRowData(data.data);
        setPaginationTotalItems(data.data.total);
        listItems(data.data.total, data.data.per_page);
        setLoading(false);
      }).catch(error => {
        // console.log(error);
      });
    } catch (error) {
      // console.log(error);
    }
  }

  function LoadBranches() {
    setBranchesData([]);
    api.get('/api/branches/active').then(response => {
      return response.data
    }).then(data => {
      if (data.success === true) {
        setBranchesData(data.data);
      }
    }).catch(error => {
      // console.log(error);
    })
  };

  function LoadUnits() {
    setUnitsData([]);
    api.get('/api/units/active').then(response => {
      return response.data
    }).then(data => {
      if (data.success === true) {
        setUnitsData(data.data);
      }
    }).catch(error => {
      // console.log(error);
    })
  };

  function LoadPlans() {
    setPlansData([]);
    api.get('/api/plans/active').then(response => {
      return response.data
    }).then(data => {
      if (data.success === true) {
        setPlansData(data.data);
      }
    }).catch(error => {
      // console.log(error);
    })
  };

  useEffect(() => {
    LoadData(1);
    LoadBranches();
    LoadUnits();
    LoadPlans();
  }, []);

  function reloadData() {
    LoadData(1);
    LoadBranches();
    LoadUnits();
    LoadPlans();
    setModalOpen(false);
    setModal('');
    setModalId(0);
  }

  function listItems(total, PorPage) {
    let totalPages = 1;
    let asdasdsa = [];
    if (PorPage < total) {
      totalPages = total / PorPage;
    }
    for (let a = 1; a < totalPages + 1; a++) {
      asdasdsa.push([a]);
    }
    setPages(asdasdsa);
  }

  function PaginationOpen(Page) {
    LoadData(Page);
  }

  return (
    <React.Fragment>

      <LayoutHeader isAdmin={true} show={true} />

      <div className="px-3 pt-1 pb-5">
        <Row className="pt-3910201290">

          <Col md={12} className="content-box">
            <Row>
              <Col md={12} className="text-center">
                <div className="titulo-hr">
                  <span>{NameModule}</span>
                </div>
              </Col>

              <Col lg={12}>
                <Row>
                  <Col lg={2}>
                    <FormGroup floating>
                      <Input
                        id="status"
                        className="status"
                        name="status"
                        type="select"
                        onChange={(event) => {
                          setFormData({ ...FormData, 'status': event.target.value });
                        }}
                        value={FormData.status ? FormData.status : ''}
                      >
                        <option value=""> Todos </option>
                        <option value="2">Para entrevistar</option>
                        <option value="3">Entrevistado</option>
                        <option value="4">Aprovado</option>
                        <option value="9">Cancelado</option>
                      </Input>
                      <Label>
                        Status
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg={8}>
                    <FormGroup floating>
                      <Input
                        id="search"
                        className="search"
                        name="search"
                        type="search"
                        placeholder="Especilidade"
                        onChange={(event) => { setFormData({ ...FormData, 'search': event.target.value }); }}
                        value={FormData.search ? FormData.search : ''}
                      />
                      <Label>
                        Buscar
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg={1} className="text-center">
                    <Button
                      className="btnFilter"
                      onClick={() => {
                        LoadData();
                      }}
                    >
                      Filtrar
                    </Button>
                  </Col>
                  <Col lg={1} className="text-center">
                    <Button
                      className="btnFilter"
                      color="success"
                      onClick={() => {
                        setModal('add');
                        setModalOpen(true);
                        setModalId(0);
                      }}
                    >
                      Adicionar
                    </Button>
                  </Col>
                </Row>
              </Col>

            </Row>
          </Col>
          <Col md={12} className="content-box">
            <Row className="mb-3">
              <Col md={12}>
                <div className="boxes">
                  <Table responsive size="sm" className="table">
                    <thead>
                      <th></th>
                      <th> Status </th>
                      <th> Data </th>
                      <th> Horário </th>                       
                      <th> Nome </th>
                      <th> CPF </th>
                      <th> Celular </th>
                      <th> E-mail </th>
                      <th> Entrevistador </th>
                      <th> Unidade </th>
                      <th> Plano </th>
                      <th> Cadastro </th>
                      <th> Ativação </th>
                    </thead>
                    <tbody>

                      {Loading ? (
                        <tr>
                          <td colSpan={50} style={{ border: '0px' }}>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                              <Spinner color="info" className="mt-5 mb-2"></Spinner>
                              <br />
                              Carregando dados...
                            </div>
                          </td>
                        </tr>
                      ) : null}

                      {!Loading && RowData.results === 0 ? (
                        <tr>
                          <td colSpan={50} style={{ border: '0px', paddingTop: '30px', textAlign: 'center' }}>
                            Sem dados com esses filtros no momento.
                          </td>
                        </tr>
                      ) : null}

                      {RowData && !Loading ? (
                        RowData.data.map((row, index) => (
                          <tr key={index}>
                            <td style={{ width: '10px', textAlign: 'center' }}>
                              <UncontrolledDropdown
                                className="me-2"
                                direction="down"
                              >
                                <DropdownToggle
                                  caret
                                  color="dark"
                                  size="sm"
                                  style={{ padding: '1px 5px' }}
                                >
                                  Acões
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem header>
                                    Selecione uma ação
                                  </DropdownItem>
                                  <DropdownItem onClick={(event) => {
                                    setModal('view');
                                    setModalOpen(true);
                                    setModalId(row.id);
                                  }}>
                                    Ver
                                  </DropdownItem>

                                  <DropdownItem divider />

                                  {row.status == 0 ? (
                                    <DropdownItem onClick={(event) => {
                                      setModal('schedule');
                                      setModalOpen(true);
                                      setModalId(row.id);
                                    }}>
                                      Agendar entrevista
                                    </DropdownItem>
                                  ) : null}                                  
                                  {row.status == 1 || row.status == 2 ? (
                                    <DropdownItem onClick={(event) => {
                                      setModal('reschedule');
                                      setModalOpen(true);
                                      setModalId(row.id);
                                    }}>
                                      Remarcar entrevista
                                    </DropdownItem>
                                  ) : null}
                                  {row.status == 1 || row.status == 2 ? (
                                    <DropdownItem onClick={(event) => {
                                      setModal('cancel');
                                      setModalOpen(true);
                                      setModalId(row.id);
                                    }}>
                                      Cancelar entrevista
                                    </DropdownItem>
                                  ) : null}                                  
                                  {row.status == 1 || row.status == 2 ? (
                                    <>
                                      <DropdownItem onClick={(event) => {
                                        setModal('chanhe-interview');
                                        setModalOpen(true);
                                        setModalId(row.id);
                                      }}>
                                        Alterar entrevistador
                                      </DropdownItem>                                    
                                      <DropdownItem onClick={(event) => {
                                          setModal('interview');
                                          setModalOpen(true);
                                          setModalId(row.id);
                                        }}>
                                          Entrevistar
                                      </DropdownItem>                                  
                                    </>
                                  ) : null}  
                                  {row.status == 4 ? (                                                                     
                                    <DropdownItem onClick={(event) => {
                                      setModal('approve');
                                      setModalOpen(true);
                                      setModalId(row.id);
                                    }}>
                                      Aprovar
                                    </DropdownItem>
                                  ) : null}                                    
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                            <td>
                              {row && row.status == 1111 ? 'Criado Externo' : ''}
                              {row && row.status == 0 ? 'Para entrevistar' : ''}
                              {row && row.status == 1 ? 'Agendado' : ''}
                              {row && row.status == 2 ? 'Remarcado' : ''}
                              {row && row.status == 3 ? 'Cancelado' : ''}
                              {row && row.status == 4 ? 'Entrevistado' : ''}
                              {row && row.status == 5 ? 'Aprovado' : ''}
                            </td>
                            <td>
                              {row && row.date ? DateToData(row.date, "/") : '-'}
                            </td>
                            <td>
                              {row && row.time ? row.time : '-'}
                            </td>                                                        
                            <td>
                              {row && row.intervieweds && row.intervieweds.name ? row.intervieweds.name : '-'}
                            </td>
                            <td>
                             {row && row.intervieweds && row.intervieweds.cpf ? row.intervieweds.cpf : '-'}
                            </td>
                            <td>
                              {row && row.intervieweds && row.intervieweds.cell_phone ? row.intervieweds.cell_phone : '-'}
                            </td>
                            <td>
                              {row && row.intervieweds && row.intervieweds.email ? row.intervieweds.email : '-'}
                            </td>
                            <td>
                              {row && row.to_interview && row.to_interview.name ? row.to_interview.name : '-'}
                            </td>
                            <td>
                              {row && row.intervieweds && row.intervieweds.units ? row.intervieweds.units.name : '-'}
                            </td>
                            <td>
                              {row && row.intervieweds && row.intervieweds.plans ? row.intervieweds.plans.name : '-'}
                            </td>
                            <td>
                              {row && row.created_at ? row.created_at : '-'}
                            </td>
                            <td>
                              {row && row.intervieweds && row.intervieweds.activation_at ? row.intervieweds.activation_at : '-'}
                            </td>
                          </tr>
                        ))
                      ) : null}

                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col md={9}>
                <div className="TablePagination">
                  Mostrando página <strong>{PaginationPageActual}</strong> de <strong>1</strong>. | Total de registros é de <strong>{PaginationTotalItems}</strong>.
                </div>
              </Col>
              <Col md={3}>
                <FormGroup row className="text-right">
                  <Label
                    for="exampleEmail"
                    sm={4}
                  >
                    Página
                  </Label>
                  <Col sm={8}>
                    <Input
                      className=""
                      autoComplete={false}
                      name="companie_taxation"
                      type="select"
                      placeholder="Tributação"
                      onChange={(event) => { PaginationOpen(event.target.value); }}
                    >
                      {Pages.map((row) => (
                        <option key={row} value={row} > {row} </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
        
        {Modal == 'view' ? (<ModalInterviewAdminMembersView
          toggle={() => setModalOpen(!ModalOpen)}
          isOpen={ModalOpen}
          modalId={ModalId}
          size="xl"
          title={NameModule}
          reloadData={reloadData}
        />) : ''}        

        {Modal == 'schedule' ? (<AdminMembersApprovalsSchedule
          toggle={() => setModalOpen(!ModalOpen)}
          isOpen={ModalOpen}
          modalId={ModalId}
          size=""
          title={NameModule}
          reloadData={reloadData}
        />) : ''}

        {Modal == 'reschedule' ? (<AdminMembersApprovalsReschedule
          toggle={() => setModalOpen(!ModalOpen)}
          isOpen={ModalOpen}
          modalId={ModalId}
          size=""
          title={NameModule}
          reloadData={reloadData}
        />) : ''}

        {Modal == 'cancel' ? (<AdminMembersApprovalsCancel
          toggle={() => setModalOpen(!ModalOpen)}
          isOpen={ModalOpen}
          modalId={ModalId}
          size="lg"
          title={NameModule}
          reloadData={reloadData}
        />) : ''}

        {Modal == 'interview' ? (<ModalInterviewAdminMembersInterview
          toggle={() => setModalOpen(!ModalOpen)}
          isOpen={ModalOpen}
          modalId={ModalId}
          size="xl"
          title={NameModule}
          reloadData={reloadData}
        />) : ''}

        {Modal == 'chanhe-interview' ? (<AdminMembersApprovalsChangeInterviewer
          toggle={() => setModalOpen(!ModalOpen)}
          isOpen={ModalOpen}
          modalId={ModalId}
          size=""
          title={NameModule}
          reloadData={reloadData}
        />) : ''} 

        {Modal == 'approve' ? (<AdminMembersApprovalsApprove
          toggle={() => setModalOpen(!ModalOpen)}
          isOpen={ModalOpen}
          modalId={ModalId}
          size="lg"
          title={NameModule}
          reloadData={reloadData}
        />) : ''}                                        

      </div>
    </React.Fragment>
  );
};

export default AdminMembersApprovals;