import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    Row,
    Col,
    Form,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Label,
} from "reactstrap";
import ModalBase from '../../components/modal/modal';
import api from "../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    showToastArray,
} from '../../helpers/generals';
import { DateToData, formatDataToDate } from '../../helpers/mascarasParaCampos';

export default function Modal({
    modalId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [FormData, setFormData] = useState({});
    const [Loading, setLoading] = useState(false);

    const [UnitsData, setUnitsData] = useState([]);

    const LoadUnits = async => {
        setUnitsData([]);
        api.get('/api/units/active').then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setUnitsData(data.data);
                setLoading(false);
            }
        }).catch(error => {
            // console.log(error);
        })
    };

    useEffect(() => {
        LoadUnits();
        if (modalId > 0) {
            try {
                api.get('/api/events/show/' + modalId).then(response => {
                    return response;
                }).then(data => {
                    if (data.data.success) {
                        
                        const formData = { ...FormData };
                        
                        if (data.data.data) {
                            
                            const item = data.data.data;  

                            formData.name = item.name;   
                            
                            if (item.date) formData.date = formatDataToDate(item.date);

                            formData.time_i = item.time_i;
                            formData.time_f = item.time_f;
                            formData.local = item.local;
                            formData.description = item.description;
                            formData.units_id = item.units_id;
                            formData.is_active = item.is_active;  

                        }

                        setFormData(formData);
                        setLoading(false);
                    
                    }    
                }).catch(error => {
                    // console.log(error);
                });
            } catch (error) {
                // console.log(error);
            }
        }
    }, [modalId]);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: FormData.name || '',
            date: FormData.date || '',
            time_i: FormData.time_i || '',
            time_f: FormData.time_f || '',
            local: FormData.local || '',
            description: FormData.description || '',
            units_id: FormData.units_id || '',
            is_active: FormData.is_active || '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Digite esse campo."),
            date: Yup.string().required("Digite esse campo."),
            time_i: Yup.string().required("Digite esse campo."),
            time_f: Yup.string().required("Digite esse campo."),
            local: Yup.string().required("Digite esse campo."),
            description: Yup.string().required("Digite esse campo."),
            units_id: Yup.string().required("Selecione uma opção."),
            is_active: Yup.string().required("Selecione uma opção."),
        }),
        onSubmit: (values) => {
            setLoading(true);
            if (modalId) {
                api.put('/api/events/update/' + modalId, values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success == true) {
                        showToastArray("", "Sucesso", "Cadastro atualizado com sucesso!");
                        setLoading(false);
                        reloadData();
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });
            } else {
                api.post('/api/events/save', values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success == true) {
                        showToastArray("", "Sucesso", "Cadastro atualizado com sucesso!");
                        setLoading(false);
                        reloadData();
                    } 
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });
            }
        },
    });

    const editorConfiguration = {
        toolbar: [
            'undo',
            'redo',
            '|',
            'bold',
            'italic',
            'underline',
            '|',

            'bulletedList',
            'numberedList',
            'blockQuote',
            '|',
            'link',
            'mediaEmbed',
            '|',
            'insertTable',
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'code'
        ]
    };

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={modalId > 0 ? 'Editar | ' + title : 'Adicionar | ' + title}
        >
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Row>
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="is_active"
                                type="select"
                                placeholder="Situação"
                                onChange={(event) => { setFormData({ ...FormData, 'is_active': event.target.value }); }}
                                value={FormData.is_active ? FormData.is_active : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.is_active && validation.errors.is_active ? true : false
                                }
                            >
                                {validation.touched.is_active && validation.errors.is_active ? (
                                    <FormFeedback type="invalid">{validation.errors.is_active}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                <option value="1"> Ativo </option>
                                <option value="2"> Inativo  </option>
                            </Input>
                            <Label>
                                Situação
                            </Label>
                        </FormGroup>
                    </Col>

                    <Col md={12} className="text-center mt-2">
                        <div className="titulo-hr">
                            <span> Dados do registro </span>
                        </div>
                    </Col>
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="date"
                                type="date"
                                onChange={(event) => { setFormData({ ...FormData, 'date': event.target.value }); }}
                                value={FormData.date ? FormData.date : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.date && validation.errors.date ? true : false
                                }
                            />
                            {validation.touched.date && validation.errors.date ? (
                                <FormFeedback type="invalid">{validation.errors.date}</FormFeedback>
                            ) : null}
                            <Label>
                                Data
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="time_i"
                                type="time"
                                onChange={(event) => { setFormData({ ...FormData, 'time_i': event.target.value }); }}
                                value={FormData.time_i ? FormData.time_i : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.time_i && validation.errors.time_i ? true : false
                                }
                            />
                            {validation.touched.time_i && validation.errors.time_i ? (
                                <FormFeedback type="invalid">{validation.errors.time_i}</FormFeedback>
                            ) : null}
                            <Label>
                                Inicio
                            </Label>
                        </FormGroup>
                    </Col>                    
                    <Col sm="6">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="time_f"
                                type="time"
                                onChange={(event) => { setFormData({ ...FormData, 'time_f': event.target.value }); }}
                                value={FormData.time_f ? FormData.time_f : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.time_f && validation.errors.time_f ? true : false
                                }
                            />
                            {validation.touched.time_f && validation.errors.time_f ? (
                                <FormFeedback type="invalid">{validation.errors.time_f}</FormFeedback>
                            ) : null}
                            <Label>
                                Fim
                            </Label>
                        </FormGroup>
                    </Col>                     
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="name"
                                type="text"
                                maxLength={255}
                                onChange={(event) => { setFormData({ ...FormData, 'name': event.target.value }); }}
                                value={FormData.name ? FormData.name : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.name && validation.errors.name ? true : false
                                }
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                            ) : null}
                            <Label>
                                Nome
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="local"
                                type="text"
                                maxLength={255}
                                onChange={(event) => { setFormData({ ...FormData, 'local': event.target.value }); }}
                                value={FormData.local ? FormData.local : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.local && validation.errors.local ? true : false
                                }
                            />
                            {validation.touched.local && validation.errors.local ? (
                                <FormFeedback type="invalid">{validation.errors.local}</FormFeedback>
                            ) : null}
                            <Label>
                                Local
                            </Label>
                        </FormGroup>
                    </Col>  
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                autoComplete={false}
                                name="units_id"
                                type="select"
                                onChange={(event) => { setFormData({ ...FormData, 'units_id': event.target.value }); }}
                                value={FormData.units_id ? FormData.units_id : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.type && validation.errors.type ? true : false
                                }
                            >
                                {validation.touched.type && validation.errors.type ? (
                                    <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                {UnitsData.map((row, index) => (
                                    <option key={index} value={row.id}>{row.name}</option>
                                ))}
                            </Input>
                            <Label>
                                Unidade
                            </Label>
                        </FormGroup>
                    </Col>                       
                    <Col sm="12">
                        <FormGroup>
                            <Label>
                                Descrição
                            </Label>
                            <CKEditor
                                editor={ClassicEditor}
                                config={editorConfiguration}
                                data={FormData.description ? FormData.description : ''}
                                onReady={editor => {
                                    // Allows you to store the editor instance and use it later.
                                    // // console.log('11', editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData(); 
                                    // // console.log({ event, editor, data });
                                    setFormData({ ...FormData, 'description': (data) });
                                }}
                                onBlur={(event, editor) => {
                                    // // console.log('22', editor);
                                }}
                                onFocus={(event, editor) => {
                                    // // console.log('333', editor);
                                }}
                            />
                            {validation.touched.description && validation.errors.description ? (
                                <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>                                   
                </Row>
                <Row className="mt-3 mb-2">
                    <Col xs="6">
                        <Button type="submit" color="success" disabled={Loading ? true : false}>
                            {modalId > 0 ? 'Atualizar' : 'Adicionar'}
                        </Button>
                    </Col>
                    <Col xs="6" className="text-right pb-2">
                        <button type="button" className="btn btn-secondary" onClick={toggle} disabled={Loading ? true : false}>Fechar</button>
                    </Col>
                </Row>
            </Form>
        </ModalBase>
    );
}

Modal.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
