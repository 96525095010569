import React, { useState, useEffect } from "react";
import {
  useNavigate,
} from 'react-router-dom';
import api from "../../../services/api";
import {
  Row,
  Col,
  Container,
  Button,
} from "reactstrap";
import ContentLoader from "react-content-loader";
import LayoutHeader from "../../../components/header";
import LayoutSideBar from "../../../components/sidebar";
import LayoutBannerHeader from "../../../components/bannerHeader";
import SlideMovies from "../../../components/slideMovies";
import { DateToData } from "../../../helpers/mascarasParaCampos";

const Dashboard = props => {

  const navigate = useNavigate();

  const [Loading, setLoading] = useState(true);

  const [RowDataClasses, setRowDataClasses] = useState([]);
  const [RowDataNews, setRowDataNews] = useState([]);

  const LoadClasses = async => {
    api.get('/api/classes/active').then(response => {
      return response.data
    }).then(data => {
      if (data.success == true) {
        // console.log(data.data);
        setRowDataClasses(data.data);
      }
    }).catch(error => {
      // console.log(error)
    })
  };

  const LoadNews = async => {
    api.get('/api/news/timeline').then(response => {
      return response.data
    }).then(data => {
      if (data.success == true) {
        // console.log('news', data.data);
        setRowDataNews(data.data);
        setLoading(false);
      }
    }).catch(error => {
      // console.log(error)
    })
  };

  useEffect(() => {
    if (localStorage.getItem("UserEnter") > 0) {
      LoadClasses();
      LoadNews();
    } else {
      localStorage.setItem("UserEnter", 1);
      window.location.reload();
    }
  }, []);

  return (
    <React.Fragment>

      <LayoutHeader isAdmin={true} show={true} />

      <Container>
        <Row>

          <LayoutBannerHeader show={true} />

          <Col md={9}>
            <Row>

              <Col md={12} className="content-box p-0">
                <Row>
                  <Col md={12} className="text-center">
                    <div className="titulo-hr">
                      <span>Aulas</span>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col xs={12} className="content-box p-0">
                <SlideMovies show={true} title="Empreendedorismo" rows={RowDataClasses} />
              </Col>

              <Col xs={12} className="content-box p-0 mb-2">
                {/* <SlideMovies show={true} title="Network" rows={list2} /> */}
              </Col>

              <Col xs={12} className="content-box p-0">
                <Row>
                  <Col xs={12} className="text-center">
                    <div className="titulo-hr">
                      <span>Novidades</span>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col xs={12} className="content-box">
                <Row>
                  {!Loading ?
                    RowDataNews.map((rowItem) => (
                      <Col xs={12} key={rowItem.id}>
                        <div className="boxes">
                          <Row>
                            <Col xs={12} className="mb-0">
                              <div className="timeline-avatar">
                                <img class="avatar" src={"https://api.empresariossa.com/storage/images/avatar/" + rowItem.users_at.avatar} alt="" />
                              </div>
                              <div className="timeline-avatar-text">
                                <h3>{rowItem.users_at ? rowItem.users_at.name : null}</h3>
                                <h4>{rowItem.date ? DateToData(rowItem.date, "/") : null}</h4>
                              </div>
                            </Col>
                            <Col md={12} className="mt-3 mb-0 text-bold">
                              <h5>{rowItem.name ? rowItem.name : null}</h5>
                            </Col>
                            <Col md={12} className="img-destaque">
                              <img src={'https://api.empresariossa.com/storage/images/' + rowItem.thumb} alt="" />
                            </Col>
                            {/* <Col md={12}>
                              <Row className="mt-3">
                                <Col size={12}>
                                  <Button className="box-like">
                                    <i class='bx bx-like'></i> <span>Gostei</span>
                                  </Button>
                                  <Button className="box-like">
                                    <i class='bx bx-message-rounded-dots'></i> <span>Comentar</span>
                                  </Button>
                                </Col>
                              </Row>
                            </Col>                             */}
                            <Col md={12} className="mt-4 mb-0">
                              <p dangerouslySetInnerHTML={{ __html: rowItem.description }}></p>
                            </Col>
                          </Row>
                        </div>
                      </Col>))
                    : (
                      <Col md={12}>
                        <div className="boxes">
                          <Row>
                            <Col md={12} className="mb-3">
                              <Row>
                                <Col xs={1}>
                                  <ContentLoader
                                    speed={2}
                                    width="100%"
                                    height="40px"
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#ecebeb"
                                  >
                                    <circle cx="20" cy="20" r="20" width="100%" />
                                  </ContentLoader>
                                </Col>
                                <Col xs={11}>
                                  <Row>
                                    <Col md={12}>
                                      <ContentLoader
                                        speed={2}
                                        width="100%"
                                        height="20px"
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#ecebeb"
                                      >
                                        <rect x="0" y="0" width="100%" height="20px" />
                                      </ContentLoader>
                                    </Col>
                                    <Col md={12}>
                                      <ContentLoader
                                        speed={2}
                                        width="100%"
                                        height="20px"
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#ecebeb"
                                      >
                                        <rect x="0" y="5" width="30%" height="10px" />
                                      </ContentLoader>
                                    </Col>
                                    <Col md={12} className="mt-3 mb-0 text-bold">
                                      <ContentLoader
                                        speed={2}
                                        width="100%"
                                        height="20px"
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#ecebeb"
                                      >
                                        <rect x="0" y="0" width="80%" height="20px" />
                                      </ContentLoader>
                                    </Col>
                                    <Col md={12} className="mt-1 mb-0">
                                      <ContentLoader
                                        speed={2}
                                        width="100%"
                                        height="120px"
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#ecebeb"
                                      >
                                        <rect x="0" y="20" width="60%" height="15px" />
                                        <rect x="0" y="45" width="80%" height="15px" />
                                        <rect x="0" y="70" width="90%" height="15px" />
                                        <rect x="0" y="95" width="80%" height="15px" />
                                      </ContentLoader>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                            <Col md={12} className="img-destaque">
                              <ContentLoader
                                speed={2}
                                width="100%"
                                height="400px"
                                backgroundColor="#f3f3f3"
                                foregroundColor="#ecebeb"
                              >
                                <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                              </ContentLoader>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    )}
                </Row>
              </Col>

            </Row>
          </Col>

          <Col md={3}>
            <LayoutSideBar isAdmin={true} show={true} />
          </Col>

        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Dashboard;