import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Form,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Label,
    Spinner,
} from "reactstrap";
import ModalBase from '../../../../components/modal/modal';
import api from "../../../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    showToastArray,
} from '../../../../helpers/generals';
import { Link } from 'react-router-dom';

export default function ModalInvitationsChecked({
    modalId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [FormData, setFormData] = useState({});
    const [Loading, setLoading] = useState(false);

    const [FileName, setFileName] = useState();

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            checked: FormData.checked || '',
        },
        validationSchema: Yup.object({
            checked: Yup.string().required("Selecione uma opção."),
        }),
        onSubmit: (values) => {
            setLoading(true);
            if (modalId > 0) {
                api.put('/api/invitations/checked/' + modalId, values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success == true) {
                        showToastArray("", "Sucesso", "Sua solicitação foi atualizada com sucesso.");
                        setLoading(false);
                        reloadData();
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });
            }
        },
    });

    const LoadDataShow = async => {
        if (modalId > 0) {
            try {
                api.get('/api/invitations/show/' + modalId).then(response => {
                    return response;
                }).then(data => {
                    setFormData(data.data.data);
                    setLoading(false);
                }).catch(error => {
                    // console.log(error);
                });
            } catch (error) {
                // console.log(error);
            }
        }
    };    

    useEffect(() => {
        if (modalId > 0) {
            LoadDataShow();
        }
    }, []);

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={title}
        >
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Row>
                    <Col sm="12" className="mb-3 text-center">
                       <Link to={FormData.file ? "https://api.empresariossa.com/storage/files/" + FormData.file : null} target="_blank">Clique aqui para ver o comprovante anexado</Link>
                    </Col>                    
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                name="checked"
                                type="select"
                                autoComplete={false}
                                onChange={(event) => { setFormData({ ...FormData, 'checked': event.target.value }); }}
                                value={FormData.checked ? FormData.checked : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.checked && validation.errors.checked ? true : false
                                }
                            >
                                <option value=""> Selecione... </option>
                                <option value="2">Sim, está correto</option>
                                <option value="1">Não, comprovante incorreto</option>                                
                            </Input>
                            <Label>
                                Compravante está correto?
                            </Label>
                            {validation.touched.checked && validation.errors.checked ? (
                                <FormFeedback type="invalid">{validation.errors.checked}</FormFeedback>
                            ) : null}                            
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mt-3 mb-2">
                    <Col sm="6">
                        {Loading ? (
                            <Spinner />
                        ) : (
                            <Button type="submit" color="success" disabled={Loading ? true : false}>
                                Atualizar
                            </Button>
                        )}
                    </Col>
                    <Col xs="6" className="text-right pb-2">
                        <button type="button" className="btn btn-secondary" onClick={toggle} disabled={Loading ? true : false}>Fechar</button>
                    </Col>
                </Row>
            </Form>
        </ModalBase>
    );
}

ModalInvitationsChecked.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
