import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef, useCallback } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CkeditorOptionsEditorConfiguration } from '../../../components/ckeditor-options';
import {
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Button,
  FormFeedback,
  Label,
  Container,
} from "reactstrap";
import api from "../../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  MaskCellPhone,
  MaskCep,
  MaskCpf,
  MaskCnpj,
} from '../../../helpers/mascarasParaCampos';
import {
  showToastArray,
} from '../../../helpers/generals';
import ContentLoader from "react-content-loader";
import LayoutHeader from "../../../components/header";
import LayoutBannerHeader from "../../../components/bannerHeader";
import ModalCrop from '../../../components/crop/crop';
import ModalUploadImage from '../../../components/upload/image';
import { Link } from 'react-router-dom';

const buttonStyle = {
  border: '1px solid #ccc',
  padding: '5px 15px',
  fontSize: '16px',
  color: 'black',
  background: 'white',
  marginTop: '20px'
};
const PerfilView = props => {

  const cropper = React.createRef(null);

  const [FormData, setFormData] = useState({});
  const [CountriesData, setCountriesData] = useState([]);
  const [StatesData, setStatesData] = useState([]);
  const [CitiesData, setCitiesData] = useState([])
  const [BranchesData, setBranchesData] = useState([]);
  const [ContentLoaderGet, setContentLoaderGet] = useState(true);

  const [Loading, setLoading] = useState(true);

  let UserID = localStorage.getItem('UserID');

  const LoadDataShow = async => {

    try { 
      api.get('/api/users/show/' + UserID).then(response => {
        return response;
      }).then(data => {

        // const formData = { ...FormData };
                        
        // if (data.data.data) {
            
        //     const item = data.data.data;  

        //     if (item.name) formData.name = item.name;   
        //     if (item.cpf) formData.cpf = MaskCpf(item.cpf);
        //     if (item.birth) formData.birth = item.birth;
        //     if (item.cell_phone) formData.cell_phone = MaskCellPhone(item.cell_phone);
        //     if (item.email) formData.email = item.email;
        //     if (item.nickname) formData.nickname = item.nickname;
        //     if (item.description) formData.description = item.description;

        //     if (item.address) formData.address = item.address;
        //     if (item.number) formData.number = item.number;
        //     if (item.complement) formData.complement = item.complement;
        //     if (item.district) formData.district = item.district;
        //     if (item.zip_code) formData.zip_code = MaskCep(item.zip_code);

        //     if (item.countries_id) formData.countries_id = item.countries_id;
        //     if (item.states_id) {
        //         LoadStates(item.countries_id);
        //         formData.states_id = item.states_id;
        //     }
        //     if (item.cities_id) {
        //         LoadCities(item.states_id);
        //         formData.cities_id = item.cities_id;
        //     }
            
        //     if (item.branches_id) formData.branches_id = item.branches_id;
        //     if (item.units_id) formData.units_id = item.units_id;

        //     if (item.companie_name) formData.companie_name = item.companie_name;
        //     if (item.companie_cnpj) formData.companie_cnpj = item.companie_cnpj;
        //     if (item.func_companie) formData.func_companie = item.func_companie;
        //     if (item.time_atuation) formData.time_atuation = item.time_atuation;

        //     if (item.companie_invoicing) formData.companie_invoicing = item.companie_invoicing;
        //     if (item.amount_employees) formData.amount_employees = item.amount_employees;
        //     if (item.list_partnerships_clients) formData.list_partnerships_clients = item.list_partnerships_clients;
        //     if (item.group_expectation) formData.group_expectation = item.group_expectation;
        //     if (item.specific_activity) formData.specific_activity = item.specific_activity;
        //     if (item.allows_contact) formData.allows_contact = item.allows_contact;
        //     if (item.urgency_schedule) formData.urgency_schedule = item.urgency_schedule;
        //     if (item.subscription_plan) formData.subscription_plan = item.subscription_plan;

        //     if (item.time_i_1) formData.time_i_1 = item.time_i_1;
        //     if (item.time_i_2) formData.time_i_2 = item.time_i_2;
        //     if (item.time_i_3) formData.time_i_3 = item.time_i_3;
        //     if (item.time_i_4) formData.time_i_4 = item.time_i_4;
        //     if (item.time_i_5) formData.time_i_5 = item.time_i_5;                        

        //     if (item.time_f_1) formData.time_f_1 = item.time_f_1;
        //     if (item.time_f_2) formData.time_f_2 = item.time_f_2;
        //     if (item.time_f_3) formData.time_f_3 = item.time_f_3;
        //     if (item.time_f_4) formData.time_f_4 = item.time_f_4;
        //     if (item.time_f_5) formData.time_f_5 = item.time_f_5;                          

        //     if (item.is_active) formData.is_active = item.is_active;

        // }

        // setFormData(formData);
        // setLoading(false);        

        LoadCountries();
        if (data.data.data.countries) LoadStates(data.data.data.countries_id);
        if (data.data.data.states) LoadCities(data.data.data.states_id);
        setFormData(data.data.data);
        setLoading(false);
        setTimeout(() => {
          setContentLoaderGet(false);
        }, 1000);        
      }).catch(error => {
        // console.log(error);
      });
    } catch (error) {
      // console.log(error);
    }

  };

  const LoadCountries = async => {
    setCountriesData([]);
    setStatesData([]);
    setCitiesData([]);
    api.get('/api/countries/active').then(response => {
      return response.data
    }).then(data => {
      if (data.success === true) {
        setCountriesData(data.data);
        setLoading(false);
      }
    }).catch(error => {
      // console.log(error);
    })
  };

  function LoadStates(id) {
    setStatesData([]);
    setCitiesData([]);
    api.get('/api/states/active/countries/' + id).then(response => {
      return response.data
    }).then(data => {
      if (data.success === true) {
        setStatesData(data.data);
      }
    }).catch(error => {
      // console.log(error);
    })
  };

  function LoadCities(id) {
    setCitiesData([]);
    api.get('/api/cities/active/states/' + id).then(response => {
      return response.data
    }).then(data => {
      if (data.success === true) {
        setCitiesData(data.data);
      }
    }).catch(error => {
      // console.log(error);
    })
  };

  function LoadBranches() {
    setBranchesData([]);
    api.get('/api/branches/active').then(response => {
      return response.data
    }).then(data => {
      if (data.success === true) {
        setBranchesData(data.data);
      }
    }).catch(error => {
      // console.log(error);
    })
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: FormData.name || '',
      nickname: FormData.nickname || '',
      birth: FormData.birth || '',
      cpf: FormData.cpf || '',
      description: FormData.description || '',
      movie: FormData.movie || '',
      cell_phone: FormData.cell_phone || '',

      companie_name: FormData.companie_name || '',
      companie_cnpj: FormData.companie_cnpj || '',
      companie_description: FormData.companie_description || '',

      linkedIn: FormData.linkedIn || '',
      facebook: FormData.facebook || '',
      instagram: FormData.instagram || '',
      tiktok: FormData.tiktok || '',
      twitter: FormData.twitter || '',
      youtube: FormData.youtube || '',
      website: FormData.website || '',

      amount_employees: FormData.amount_employees || '', // quantidade de empregados
      list_partnerships_clients: FormData.list_partnerships_clients || '', // Qual o tipo de cliente ou parceria você busca, Cite pelo menos três
      group_expectation: FormData.group_expectation || '', // O que você achou do nosso encontro e qual sua expectativa em relação ao Grupo
      specific_activity: FormData.specific_activity || '', // Qual atividade especifica pretende bloquear, trazer a resposta do ramo opção de incluir outro em texto
      allows_contact: FormData.allows_contact || '', // Você permite passar seu contato para pessoas que tem interesse em fazer negócios com você?
      urgency_schedule: FormData.urgency_schedule || '', // Qual área você tem urgência em agendar atendimento?

      time_atuation: FormData.time_atuation || '',
      func_companie: FormData.func_companie || '',

      countries_id: FormData.countries_id || '',
      states_id: FormData.states_id || '', 
      cities_id: FormData.cities_id || '',
      address: FormData.address || '',
      number: FormData.number || '',
      complement: FormData.complement || '',
      district: FormData.district || '',
      zip_code: FormData.zip_code || '',

      time_i_1: FormData.time_i_1 || '',
      time_f_1: FormData.time_f_1 || '',

      time_i_2: FormData.time_i_2 || '',
      time_f_2: FormData.time_f_2 || '',

      time_i_3: FormData.time_i_3 || '',
      time_f_3: FormData.time_f_3 || '',

      time_i_4: FormData.time_i_4 || '',
      time_f_4: FormData.time_f_4 || '',

      time_i_5: FormData.time_i_5 || '',
      time_f_5: FormData.time_f_5 || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Digite esse campo."),
      nickname: Yup.string().required("Digite esse campo."),
      birth: Yup.string().required("Digite esse campo."),
      // cpf: Yup.string().required("Digite esse campo."),
      description: Yup.string().required("Digite esse campo."),

      companie_name: Yup.string().required("Digite esse campo."),
      companie_cnpj: Yup.string().required("Digite esse campo."),
      companie_description: Yup.string().required("Digite esse campo."),

      amount_employees: Yup.string().required("Digite esse campo."),
      list_partnerships_clients: Yup.string().required("Digite esse campo."),
      group_expectation: Yup.string().required("Digite esse campo."),
      specific_activity: Yup.string().required("Digite esse campo."),
      allows_contact: Yup.string().required("Digite esse campo."),
      urgency_schedule: Yup.string().required("Digite esse campo."),

      time_atuation: Yup.string().required("Selecione uma opção."),
      func_companie: Yup.string().required("Digite esse campo."),

      countries_id: Yup.string().required("Selecione uma opção."),
      states_id: Yup.string().required("Selecione uma opção."),
      cities_id: Yup.string().required("Selecione uma opção."),
      address: Yup.string().required("Digite esse campo."),
      number: Yup.string().required("Digite esse campo."),
      complement: Yup.string().required("Digite esse campo."),
      district: Yup.string().required("Digite esse campo."),
      zip_code: Yup.string().required("Digite esse campo."),

    }), onSubmit: (values) => {
      // console.log('values', values);
      setLoading(true);
      api.put('/api/users/page/update/' + UserID, values).then(response => {
        return response.data;
      }).then(data => {
        if (data.success == true) {
          showToastArray("", "Sucesso", "Cadastro atualizado com sucesso!");
          setLoading(false);
        }
      }).catch(error => {
        if (error) {
          let message = 'Erro ao enviar o registro!';
          if (error.response.data.message) {
            message = error.response.data.message;
          }
          showToastArray("error", "Erro!", message);
          setLoading(false);
        }
      });
    },
  });

  useEffect(() => {
    LoadCountries();
    LoadBranches();
    LoadDataShow();
  }, []);

  const [SwalOpen, setSwalOpen] = useState('');
  const [ModalOpen, setModalOpen] = useState(false);
  const [Modal, setModal] = useState('');
  const [ModalId, setModalId] = useState(0);

  function reloadData() {
    LoadDataShow();
    setModalOpen(false);
    setModal('');
    setSwalOpen('');
    setModalId(0);
  }

  return (
    <React.Fragment>

      <LayoutHeader isAdmin={false} show={true} />

      {Modal == 'avatar' ? (<ModalUploadImage
        toggle={() => setModalOpen(!ModalOpen)}
        isOpen={ModalOpen}
        id={UserID}
        size=""
        path="/api/users/avatar/"
        crop={true}
        title="Trocar avatar"
        reloadData={reloadData}
      />) : ''}

      {Modal == 'logo' ? (<ModalUploadImage
        toggle={() => setModalOpen(!ModalOpen)}
        isOpen={ModalOpen}
        id={UserID}
        size=""
        path="/api/users/logo/"
        crop={false}
        title="Trocar logo"
        reloadData={reloadData}
      />) : ''}      

      <Container>
        <Row className='mb-5'>

          <LayoutBannerHeader show={true} />
          <Form
            className="form-horizontal"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            {!ContentLoaderGet ? (            
            <Col md={12} className="perfil-box">
              <Row>

                <Col md={9}>
                  <Row>
                    <Col md={12} className="text-center">
                      <div className="titulo-hr">
                        <span>Dados da sua página</span>
                      </div>
                    </Col>
                    <Col sm="3" className="ver-minha-pagina">
                      <Link
                        color="info" 
                        to={`/m/${FormData.slug}`} 
                      >
                        Ver minha página
                      </Link>
                    </Col>                    
                    <Col sm="9">
                      <FormGroup floating>
                        <Input
                          className=""
                          autoComplete={false}
                          name="name"
                          type="text"
                          maxLength={255}
                          onChange={(event) => { setFormData({ ...FormData, 'name': event.target.value }); }}
                          value={FormData.name ? FormData.name : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.name && validation.errors.name ? true : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                        ) : null}
                        <Label>
                          Nome Completo
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup floating>
                        <Input
                          className=""
                          autoComplete={false}
                          name="cpf"
                          type="text"
                          maxLength={255}
                          onChange={(event) => { setFormData({ ...FormData, 'cpf': MaskCpf(event.target.value) }); }}
                          value={FormData.cpf ? FormData.cpf : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.cpf && validation.errors.cpf ? true : false
                          }
                        />
                        {validation.touched.cpf && validation.errors.cpf ? (
                          <FormFeedback type="invalid">{validation.errors.cpf}</FormFeedback>
                        ) : null}
                        <Label>
                          CPF
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup floating>
                        <Input
                          className=""
                          autoComplete={false}
                          name="birth"
                          type="date"
                          onChange={(event) => { setFormData({ ...FormData, 'birth': event.target.value }); }}
                          value={FormData.birth ? FormData.birth : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.birth && validation.errors.birth ? true : false
                          }
                        />
                        {validation.touched.birth && validation.errors.birth ? (
                          <FormFeedback type="invalid">{validation.errors.birth}</FormFeedback>
                        ) : null}
                        <Label>
                          Nascimento
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup floating>
                        <Input
                          className=""
                          autoComplete={false}
                          name="cell_phone"
                          type="text"
                          maxLength={255}
                          onChange={(event) => { setFormData({ ...FormData, 'cell_phone': MaskCellPhone(event.target.value) }); }}
                          value={FormData.cell_phone ? FormData.cell_phone : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.cell_phone && validation.errors.cell_phone ? true : false
                          }
                        />
                        {validation.touched.cell_phone && validation.errors.cell_phone ? (
                          <FormFeedback type="invalid">{validation.errors.cell_phone}</FormFeedback>
                        ) : null}
                        <Label>
                          Celular
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup floating>
                        <Input
                          className=""
                          autoComplete={false}
                          name="email"
                          type="email"
                          maxLength={255}
                          onChange={(event) => { setFormData({ ...FormData, 'email': event.target.value }); }}
                          value={FormData.email ? FormData.email : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                        <Label>
                          E-mail
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup floating>
                        <Input
                          className=""
                          autoComplete={false}
                          name="nickname"
                          type="text"
                          maxLength={255}
                          onChange={(event) => { setFormData({ ...FormData, 'nickname': event.target.value }); }}
                          value={FormData.nickname ? FormData.nickname : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.nickname && validation.errors.nickname ? true : false
                          }
                        />
                        {validation.touched.nickname && validation.errors.nickname ? (
                          <FormFeedback type="invalid">{validation.errors.nickname}</FormFeedback>
                        ) : null}
                        <Label>
                          Como deseja ser chamado
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                        <FormGroup>
                            <Label>
                                Descrição
                            </Label>
                            <CKEditor
                                editor={ClassicEditor}
                                config={CkeditorOptionsEditorConfiguration}
                                data={FormData.description ? FormData.description : ''}
                                onReady={editor => {
                                    // Allows you to store the editor instance and use it later.
                                    // // console.log('11', editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData(); 
                                    // // console.log({ event, editor, data });
                                    setFormData({ ...FormData, 'description': (data) });
                                }}
                                onBlur={(event, editor) => {
                                    // // console.log('22', editor);
                                }}
                                onFocus={(event, editor) => {
                                    // // console.log('333', editor);
                                }}
                            />
                            {validation.touched.description && validation.errors.description ? (
                                <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>                    

                    <Col sm="12">

                      <FormGroup floating>
                        <Input
                          autoComplete={false}
                          name="website"
                          type="text"
                          onChange={(event) => { setFormData({ ...FormData, 'website': event.target.value }); }}
                          value={FormData.website ? FormData.website : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.website && validation.errors.website ? true : false
                          }
                        />
                        {validation.touched.website && validation.errors.website ? (
                          <FormFeedback type="invalid">{validation.errors.website}</FormFeedback>
                        ) : null}
                        <Label>
                          Website
                        </Label>
                      </FormGroup>

                    </Col>

                    <Col md={12} className="text-center mt-4">
                      <div className="titulo-hr">
                        <span>Vídeo Apresentação Youtube</span>
                      </div>
                    </Col>
                    <Col sm="12">
                      <FormGroup floating>
                        <Input
                          className=""
                          autoComplete={false}
                          name="movie"
                          type="text"
                          maxLength={255}
                          onChange={(event) => { setFormData({ ...FormData, 'movie': event.target.value }); }}
                          value={FormData.movie ? FormData.movie : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.movie && validation.errors.movie ? true : false
                          }
                        />
                        {validation.touched.movie && validation.errors.movie ? (
                          <FormFeedback type="invalid">{validation.errors.movie}</FormFeedback>
                        ) : null}
                        <Label>
                          Link do vídeo no Youtube
                        </Label>
                      </FormGroup>
                    </Col>

                    <Col md={12} className="text-center mt-4">
                      <div className="titulo-hr">
                        <span>Endereço</span>
                      </div>
                    </Col>
                    <Col sm="9">
                      <FormGroup floating>
                        <Input
                          className=""
                          autoComplete={false}
                          name="address"
                          type="text"
                          maxLength={255}
                          onChange={(event) => { setFormData({ ...FormData, 'address': event.target.value }); }}
                          value={FormData.address ? FormData.address : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.address && validation.errors.address ? true : false
                          }
                        />
                        {validation.touched.address && validation.errors.address ? (
                          <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                        ) : null}
                        <Label>
                          Endereço
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup floating>
                        <Input
                          className=""
                          autoComplete={false}
                          name="number"
                          type="text"
                          maxLength={25}
                          onChange={(event) => { setFormData({ ...FormData, 'number': event.target.value }); }}
                          value={FormData.number ? FormData.number : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.number && validation.errors.number ? true : false
                          }
                        />
                        {validation.touched.number && validation.errors.number ? (
                          <FormFeedback type="invalid">{validation.errors.number}</FormFeedback>
                        ) : null}
                        <Label>
                          Número
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup floating>
                        <Input
                          className=""
                          autoComplete={false}
                          name="district"
                          type="text"
                          // placeholder="airro"
                          onChange={(event) => { setFormData({ ...FormData, 'district': event.target.value }); }}
                          value={FormData.district ? FormData.district : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.district && validation.errors.district ? true : false
                          }
                        />
                        {validation.touched.district && validation.errors.district ? (
                          <FormFeedback type="invalid">{validation.errors.district}</FormFeedback>
                        ) : null}
                        <Label>
                          Bairro
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup floating>
                        <Input
                          className=""
                          autoComplete={false}
                          name="complement"
                          type="text"
                          maxLength={25}
                          placeholder="Complemento"
                          onChange={(event) => { setFormData({ ...FormData, 'complement': event.target.value }); }}
                          value={FormData.complement ? FormData.complement : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.complement && validation.errors.complement ? true : false
                          }
                        />
                        {validation.touched.complement && validation.errors.complement ? (
                          <FormFeedback type="invalid">{validation.errors.complement}</FormFeedback>
                        ) : null}
                        <Label>
                          Complemento
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup floating>
                        <Input
                          className=""
                          autoComplete={false}
                          name="zip_code"
                          type="text"
                          onChange={(event) => { setFormData({ ...FormData, 'zip_code': MaskCep(event.target.value) }); }}
                          value={FormData.zip_code ? FormData.zip_code : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.zip_code && validation.errors.zip_code ? true : false
                          }
                        />
                        {validation.touched.zip_code && validation.errors.zip_code ? (
                          <FormFeedback type="invalid">{validation.errors.zip_code}</FormFeedback>
                        ) : null}
                        <Label>
                          CEP
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col lg={4}>
                      <FormGroup floating>
                        <Input
                          id="countries_id"
                          className="countries_id"
                          name="countries_id"
                          type="select"
                          onChange={(event) => {
                            setFormData({ ...FormData, 'countries_id': event.target.value });
                            LoadStates(event.target.value);
                          }}
                          value={FormData.countries_id ? FormData.countries_id : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.countries_id && validation.errors.countries_id ? true : false
                          }
                        >
                          {validation.touched.countries_id && validation.errors.countries_id ? (
                            <FormFeedback type="invalid">{validation.errors.countries_id}</FormFeedback>
                          ) : null}
                          <option value=""> Selecione... </option>
                          {CountriesData.map((row, index) => (
                            <option key={index} value={row.id}>{row.name}</option>
                          ))}
                        </Input>
                        <Label>
                          País
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col lg={4}>
                      <FormGroup floating>
                        <Input
                          disabled={FormData.countries_id && CountriesData.length > 0 ? false : true}
                          id="states_id"
                          className="states_id"
                          name="states_id"
                          type="select"
                          onChange={(event) => {
                            setFormData({ ...FormData, 'states_id': event.target.value });
                            LoadCities(event.target.value);
                          }}
                          value={FormData.states_id ? FormData.states_id : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.states_id && validation.errors.states_id ? true : false
                          }
                        >
                          {validation.touched.states_id && validation.errors.states_id ? (
                            <FormFeedback type="invalid">{validation.errors.states_id}</FormFeedback>
                          ) : null}
                          <option value=""> Selecione... </option>
                          {StatesData.map((row, index) => (
                            <option key={index} value={row.id}>{row.name}</option>
                          ))}
                        </Input>
                        <Label>
                          Estado
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col lg={4}>
                      <FormGroup floating>
                        <Input
                          disabled={FormData.states_id && StatesData.length > 0 ? false : true}
                          id="cities_id"
                          className="cities_id"
                          name="cities_id"
                          type="select"
                          onChange={(event) => { setFormData({ ...FormData, 'cities_id': event.target.value }); }}
                          value={FormData.cities_id ? FormData.cities_id : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.cities_id && validation.errors.cities_id ? true : false
                          }
                        >
                          {validation.touched.cities_id && validation.errors.cities_id ? (
                            <FormFeedback type="invalid">{validation.errors.cities_id}</FormFeedback>
                          ) : null}
                          <option value=""> Selecione... </option>
                          {CitiesData.map((row, index) => (
                            <option key={index} value={row.id}>{row.name}</option>
                          ))}
                        </Input>
                        <Label>
                          Cidade
                        </Label>
                      </FormGroup>
                    </Col>

                    <Col md={12} className="text-center mt-4">
                      <div className="titulo-hr">
                        <span>Dados da empresa</span>
                      </div>
                    </Col>
                    <Col sm="12">
                      <FormGroup floating>
                        <Input
                          className=""
                          autoComplete={false}
                          name="companie_name"
                          type="text"
                          maxLength={255}
                          onChange={(event) => { setFormData({ ...FormData, 'companie_name': event.target.value }); }}
                          value={FormData.companie_name ? FormData.companie_name : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.companie_name && validation.errors.companie_name ? true : false
                          }
                        />
                        {validation.touched.companie_name && validation.errors.companie_name ? (
                          <FormFeedback type="invalid">{validation.errors.companie_name}</FormFeedback>
                        ) : null}
                        <Label>
                          Nome da Sua Empresa
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup floating>
                        <Input
                          className=""
                          autoComplete={false}
                          name="companie_cnpj"
                          type="text"
                          maxLength={255}
                          onChange={(event) => { setFormData({ ...FormData, 'companie_cnpj': MaskCnpj(event.target.value) }); }}
                          value={FormData.companie_cnpj ? FormData.companie_cnpj : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.companie_cnpj && validation.errors.companie_cnpj ? true : false
                          }
                        />
                        {validation.touched.companie_cnpj && validation.errors.companie_cnpj ? (
                          <FormFeedback type="invalid">{validation.errors.companie_cnpj}</FormFeedback>
                        ) : null}
                        <Label>
                          CNPJ da sua empresa
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup floating>
                        <Input
                          className=""
                          autoComplete={false}
                          name="branches_id"
                          type="select"
                          onChange={(event) => { setFormData({ ...FormData, 'branches_id': event.target.value }); }}
                          value={FormData.branches_id ? FormData.branches_id : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.branches_id && validation.errors.branches_id ? true : false
                          }
                        >
                          {validation.touched.branches_id && validation.errors.branches_id ? (
                            <FormFeedback type="invalid">{validation.errors.branches_id}</FormFeedback>
                          ) : null}
                          <option value=""> Selecione... </option>
                          {BranchesData.map((row, index) => (
                            <option key={index} value={row.id}>{row.name}</option>
                          ))}
                        </Input>
                        <Label>
                          Qual o Ramo da Sua empresa?
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup floating>
                        <Input
                          className=""
                          autoComplete={false}
                          name="func_companie"
                          type="text"
                          maxLength={255}
                          onChange={(event) => { setFormData({ ...FormData, 'func_companie': event.target.value }); }}
                          value={FormData.func_companie ? FormData.func_companie : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.func_companie && validation.errors.func_companie ? true : false
                          }
                        />
                        {validation.touched.func_companie && validation.errors.func_companie ? (
                          <FormFeedback type="invalid">{validation.errors.func_companie}</FormFeedback>
                        ) : null}
                        <Label>
                          Qual sua função na Empresa?
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup floating>
                        <Input
                          className=""
                          autoComplete={false}
                          name="time_atuation"
                          type="text"
                          maxLength={255}
                          onChange={(event) => { setFormData({ ...FormData, 'time_atuation': event.target.value }); }}
                          value={FormData.time_atuation ? FormData.time_atuation : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.time_atuation && validation.errors.time_atuation ? true : false
                          }
                        />
                        {validation.touched.time_atuation && validation.errors.time_atuation ? (
                          <FormFeedback type="invalid">{validation.errors.time_atuation}</FormFeedback>
                        ) : null}
                        <Label>
                          Quanto tempo você tem de atuação no seu mercado?
                        </Label>
                      </FormGroup>
                    </Col>


                    <Col sm="12">
                        <FormGroup>
                            <Label>
                              Explique em sua e com poucas palavras o que sua empresa atua? asdsa
                            </Label>
                            <CKEditor
                                editor={ClassicEditor}
                                config={CkeditorOptionsEditorConfiguration}
                                data={FormData.companie_description ? FormData.companie_description : ''}
                                onReady={editor => {
                                    // Allows you to store the editor instance and use it later.
                                    // // console.log('11', editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    // // console.log({ event, editor, data });
                                    setFormData({ ...FormData, 'companie_description': (data) });
                                }}
                                onBlur={(event, editor) => {
                                    // // console.log('22', editor);
                                }}
                                onFocus={(event, editor) => {
                                    // // console.log('333', editor);
                                }}
                            />
                            {validation.touched.companie_description && validation.errors.companie_description ? (
                                <FormFeedback type="invalid">{validation.errors.companie_description}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>                    
                    <Col sm="12">
                      <FormGroup floating>
                        <Input
                          className=""
                          autoComplete={false}
                          name="amount_employees"
                          type="select"
                          onChange={(event) => { setFormData({ ...FormData, 'amount_employees': event.target.value }); }}
                          value={FormData.amount_employees ? FormData.amount_employees : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.amount_employees && validation.errors.amount_employees ? true : false
                          }
                        >
                          {validation.touched.amount_employees && validation.errors.amount_employees ? (
                            <FormFeedback type="invalid">{validation.errors.amount_employees}</FormFeedback>
                          ) : null}
                          <option value=""> Selecione... </option>
                          <option value="1"> 0 </option>
                          <option value="2"> 1 à 5 </option>
                          <option value="3"> 6 à 15 </option>
                          <option value="4"> 16 à 30 </option>
                          <option value="5"> acima de 31 </option>
                        </Input>
                        <Label>
                          Sua empresa possui quantos funcionários?
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup floating>
                        <Input
                          className=""
                          autoComplete={false}
                          name="list_partnerships_clients"
                          type="text"
                          maxLength={255}
                          onChange={(event) => { setFormData({ ...FormData, 'list_partnerships_clients': event.target.value }); }}
                          value={FormData.list_partnerships_clients ? FormData.list_partnerships_clients : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.list_partnerships_clients && validation.errors.list_partnerships_clients ? true : false
                          }
                        />
                        {validation.touched.list_partnerships_clients && validation.errors.list_partnerships_clients ? (
                          <FormFeedback type="invalid">{validation.errors.list_partnerships_clients}</FormFeedback>
                        ) : null}
                        <Label>
                          Qual o tipo de cliente ou parceria você busca? <span style={{ color: 'red' }}>Cite pelo menos três e separa por vírgulas</span>
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup floating>
                        <Input
                          className=""
                          autoComplete={false}
                          name="group_expectation"
                          type="text"
                          maxLength={255}
                          onChange={(event) => { setFormData({ ...FormData, 'group_expectation': event.target.value }); }}
                          value={FormData.group_expectation ? FormData.group_expectation : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.group_expectation && validation.errors.group_expectation ? true : false
                          }
                        />
                        {validation.touched.group_expectation && validation.errors.group_expectation ? (
                          <FormFeedback type="invalid">{validation.errors.group_expectation}</FormFeedback>
                        ) : null}
                        <Label>
                          O que você achou do nosso encontro e qual sua expectativa em relação ao Grupo?
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup floating>
                        <Input
                          className=""
                          autoComplete={false}
                          name="specific_activity"
                          type="text"
                          maxLength={255}
                          onChange={(event) => { setFormData({ ...FormData, 'specific_activity': event.target.value }); }}
                          value={FormData.specific_activity ? FormData.specific_activity : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.specific_activity && validation.errors.specific_activity ? true : false
                          }
                        />
                        {validation.touched.specific_activity && validation.errors.specific_activity ? (
                          <FormFeedback type="invalid">{validation.errors.specific_activity}</FormFeedback>
                        ) : null}
                        <Label>
                          Qual atividade especifica pretende bloquear?
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup floating>
                        <Input
                          className=""
                          autoComplete={false}
                          name="allows_contact"
                          type="select"
                          onChange={(event) => { setFormData({ ...FormData, 'allows_contact': event.target.value }); }}
                          value={FormData.allows_contact ? FormData.allows_contact : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.allows_contact && validation.errors.allows_contact ? true : false
                          }
                        >
                          {validation.touched.allows_contact && validation.errors.allows_contact ? (
                            <FormFeedback type="invalid">{validation.errors.allows_contact}</FormFeedback>
                          ) : null}
                          <option value=""> Selecione... </option>
                          <option value="1"> Sim </option>
                          <option value="2"> Não </option>
                        </Input>
                        <Label>
                          Você permite passar seu contato para pessoas que tem interesse em fazer negócios com você?
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup floating>
                        <Input
                          className=""
                          autoComplete={false}
                          name="urgency_schedule"
                          type="select"
                          onChange={(event) => { setFormData({ ...FormData, 'urgency_schedule': event.target.value }); }}
                          value={FormData.urgency_schedule ? FormData.urgency_schedule : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.urgency_schedule && validation.errors.urgency_schedule ? true : false
                          }
                        >
                          {validation.touched.urgency_schedule && validation.errors.urgency_schedule ? (
                            <FormFeedback type="invalid">{validation.errors.urgency_schedule}</FormFeedback>
                          ) : null}
                          <option value=""> Selecione... </option>
                          <option value="1">Financeiro </option>
                          <option value="2">Contábil </option>
                          <option value="3">Juridico </option>
                          <option value="4">Contábil </option>
                          <option value="5">Gestão Emocional/Espiritual </option>
                          <option value="6">Marketing digital/rede social </option>
                        </Input>
                        <Label>
                          Qual área você tem urgência em agendar atendimento?
                        </Label>
                      </FormGroup>
                    </Col>

                    <Col md={12} className="text-center mt-4">
                      <div className="titulo-hr">
                        <span>Qual o melhor horário para agendar reuniões individuais com outros membros</span>
                      </div>
                    </Col>
                    <Col sm="12" className="mt-4 mb-4">

                      <Row>
                        <Col sm="3" className="text-right">
                          <Row>
                            <Col sm="12" className="mt-1">
                              Segunda-feira
                            </Col>
                            <Col sm="12">
                              <span style={{ fontSize: '11px' }}>Horários para agendamento</span>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm="7">
                          <Row>
                            <Col sm="6">
                              <FormGroup floating>
                                <Input
                                  autoComplete={false}
                                  type="time"
                                  onChange={(event) => { setFormData({ ...FormData, 'time_i_1': event.target.value }); }}
                                  value={FormData.time_i_1 ? FormData.time_i_1 : ''}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.time_i_1 && validation.errors.time_i_1 ? true : false
                                  }
                                />
                                <Label>
                                  Horário Inicial
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup floating>
                                <Input
                                  autoComplete={false}
                                  type="time"
                                  onChange={(event) => { setFormData({ ...FormData, 'time_f_1': event.target.value }); }}
                                  value={FormData.time_f_1 ? FormData.time_f_1 : ''}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.time_f_1 && validation.errors.time_f_1 ? true : false
                                  }
                                />
                                <Label>
                                  Horário Final
                                </Label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="3" className="text-right">
                          <Row>
                            <Col sm="12" className="mt-1">
                              Terça-feira
                            </Col>
                            <Col sm="12">
                              <span style={{ fontSize: '11px' }}>Horários para agendamento</span>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm="7">
                          <Row>
                            <Col sm="6">
                              <FormGroup floating>
                                <Input
                                  autoComplete={false}
                                  type="time"
                                  onChange={(event) => { setFormData({ ...FormData, 'time_i_2': event.target.value }); }}
                                  value={FormData.time_i_2 ? FormData.time_i_2 : ''}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.time_i_2 && validation.errors.time_i_2 ? true : false
                                  }
                                />
                                <Label>
                                  Horário Inicial
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup floating>
                                <Input
                                  autoComplete={false}
                                  type="time"
                                  onChange={(event) => { setFormData({ ...FormData, 'time_f_2': event.target.value }); }}
                                  value={FormData.time_f_2 ? FormData.time_f_2 : ''}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.time_f_2 && validation.errors.time_f_2 ? true : false
                                  }
                                />
                                <Label>
                                  Horário Final
                                </Label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="3" className="text-right">
                          <Row>
                            <Col sm="12" className="mt-1">
                              Quarta-feira
                            </Col>
                            <Col sm="12">
                              <span style={{ fontSize: '11px' }}>Horários para agendamento</span>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm="7">
                          <Row>
                            <Col sm="6">
                              <FormGroup floating>
                                <Input
                                  autoComplete={false}
                                  type="time"
                                  onChange={(event) => { setFormData({ ...FormData, 'time_i_3': event.target.value }); }}
                                  value={FormData.time_i_3 ? FormData.time_i_3 : ''}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.time_i_3 && validation.errors.time_i_3 ? true : false
                                  }
                                />
                                <Label>
                                  Horário Inicial
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup floating>
                                <Input
                                  autoComplete={false}
                                  type="time"
                                  onChange={(event) => { setFormData({ ...FormData, 'time_f_3': event.target.value }); }}
                                  value={FormData.time_f_3 ? FormData.time_f_3 : ''}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.time_f_3 && validation.errors.time_f_3 ? true : false
                                  }
                                />
                                <Label>
                                  Horário Final
                                </Label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="3" className="text-right">
                          <Row>
                            <Col sm="12" className="mt-1">
                              Quinta-feira
                            </Col>
                            <Col sm="12">
                              <span style={{ fontSize: '11px' }}>Horários para agendamento</span>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm="7">
                          <Row>
                            <Col sm="6">
                              <FormGroup floating>
                                <Input
                                  autoComplete={false}
                                  type="time"
                                  onChange={(event) => { setFormData({ ...FormData, 'time_i_4': event.target.value }); }}
                                  value={FormData.time_i_4 ? FormData.time_i_4 : ''}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.time_i_4 && validation.errors.time_i_4 ? true : false
                                  }
                                />
                                <Label>
                                  Horário Inicial
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup floating>
                                <Input
                                  autoComplete={false}
                                  type="time"
                                  onChange={(event) => { setFormData({ ...FormData, 'time_f_4': event.target.value }); }}
                                  value={FormData.time_f_4 ? FormData.time_f_4 : ''}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.time_f_4 && validation.errors.time_f_4 ? true : false
                                  }
                                />
                                <Label>
                                  Horário Final
                                </Label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="3" className="text-right">
                          <Row>
                            <Col sm="12" className="mt-1">
                              Sexta-feira
                            </Col>
                            <Col sm="12">
                              <span style={{ fontSize: '11px' }}>Horários para agendamento</span>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm="7">
                          <Row>
                            <Col sm="6">
                              <FormGroup floating>
                                <Input
                                  autoComplete={false}
                                  type="time"
                                  onChange={(event) => { setFormData({ ...FormData, 'time_i_5': event.target.value }); }}
                                  value={FormData.time_i_5 ? FormData.time_i_5 : ''}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.time_i_5 && validation.errors.time_i_5 ? true : false
                                  }
                                />
                                <Label>
                                  Horário Inicial
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup floating>
                                <Input
                                  autoComplete={false}
                                  type="time"
                                  onChange={(event) => { setFormData({ ...FormData, 'time_f_5': event.target.value }); }}
                                  value={FormData.time_f_5 ? FormData.time_f_5 : ''}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.time_f_5 && validation.errors.time_f_5 ? true : false
                                  }
                                />
                                <Label>
                                  Horário Final
                                </Label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                    </Col>
                    <Col sm="12">

                    </Col>
                  </Row>
                </Col>
                <Col md={3} className="px-3">
                  <Row>
                    <Col md={12} className="text-center">
                      <div className="titulo-hr">
                        <span>Seu avatar</span>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="boxes">
                        <Row>
                          <Col md={12}>
                            <img class="avatar" src={FormData.avatar ? ("https://api.empresariossa.com/storage/images/avatar/" + FormData.avatar) : '-'} />
                          </Col>
                          <Col md={12} className="mt-2 text-center">
                            <Button
                              type='button'
                              color="link"
                              size="sm"
                              onClick={(event) => {
                                setModal('avatar');
                                setModalOpen(true);
                                setSwalOpen('');
                                setModalId(UserID);
                              }}
                            >
                              Trocar avatar
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col md={12} className="text-center mt-4">
                      <div className="titulo-hr">
                        <span>Logo da sua empresa</span>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="boxes">
                        <Row>
                          <Col md={12}>
                            <img class="logo-page" src={FormData.logo ? ("https://api.empresariossa.com/storage/images/logo/" + FormData.logo) : "https://api.empresariossa.com/storage/images/logo/default.png" } />
                          </Col>
                          <Col md={12} className="mt-2 text-center">
                            <Button
                              type='button'
                              color="link"
                              size="sm"
                              onClick={(event) => {
                                setModal('logo');
                                setModalOpen(true);
                                setSwalOpen('');
                                setModalId(UserID);
                              }}                              
                            >
                              Trocar logo
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col md={12} className="text-center mt-4">
                      <div className="titulo-hr">
                        <span>Suas redes sociais</span>
                      </div>
                    </Col>
                    <Col md={12} className="mb-3">
                      <div className="boxes" style={{ margin: '0px', padding: '14px 10px 0px 20px' }}>
                        <Row>
                          <Col lg={2}>
                            <i class="bx bxl-facebook" style={{ paddingTop: '5px', fontSize: '30px' }} ></i>
                          </Col>
                          <Col lg={10}>
                            <FormGroup>
                              <Input
                                autoComplete={false}
                                name="facebook"
                                type="text"
                                onChange={(event) => { setFormData({ ...FormData, 'facebook': event.target.value }); }}
                                value={FormData.facebook ? FormData.facebook : ''}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col md={12} className="mb-3">
                      <div className="boxes" style={{ margin: '0px', padding: '14px 10px 0px 20px' }}>
                        <Row>
                          <Col lg={2}>
                            <i class="bx bxl-instagram" style={{ paddingTop: '5px', fontSize: '30px' }} ></i>
                          </Col>
                          <Col lg={10}>
                            <FormGroup>
                              <Input
                                autoComplete={false}
                                name="instagram"
                                type="text"
                                onChange={(event) => { setFormData({ ...FormData, 'instagram': event.target.value }); }}
                                value={FormData.instagram ? FormData.instagram : ''}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col md={12} className="mb-3">
                      <div className="boxes" style={{ margin: '0px', padding: '14px 10px 0px 20px' }}>
                        <Row>
                          <Col lg={2} className="text-center">
                            <i class="bx bxl-twitter" style={{ paddingTop: '5px', fontSize: '30px' }} ></i>
                          </Col>
                          <Col lg={10}>
                            <FormGroup>
                              <Input
                                autoComplete={false}
                                name="twitter"
                                type="text"
                                onChange={(event) => { setFormData({ ...FormData, 'twitter': event.target.value }); }}
                                value={FormData.twitter ? FormData.twitter : ''}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col md={12} className="mb-3">
                      <div className="boxes" style={{ margin: '0px', padding: '14px 10px 0px 20px' }}>
                        <Row>
                          <Col lg={2} className="text-center">
                            <i class="bx bxl-linkedin" style={{ paddingTop: '5px', fontSize: '30px' }} ></i>
                          </Col>
                          <Col lg={10}>
                            <FormGroup>
                              <Input
                                autoComplete={false}
                                name="linkedIn"
                                type="text"
                                onChange={(event) => { setFormData({ ...FormData, 'linkedIn': event.target.value }); }}
                                value={FormData.linkedIn ? FormData.linkedIn : ''}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col md={12} className="mb-3">
                      <div className="boxes" style={{ margin: '0px', padding: '14px 10px 0px 20px' }}>
                        <Row>
                          <Col lg={2} className="text-center">
                            <i class="bx bxl-tiktok" style={{ paddingTop: '5px', fontSize: '30px' }} ></i>
                          </Col>
                          <Col lg={10}>
                            <FormGroup>
                              <Input
                                autoComplete={false}
                                name="tiktok"
                                type="text"
                                onChange={(event) => { setFormData({ ...FormData, 'tiktok': event.target.value }); }}
                                value={FormData.tiktok ? FormData.tiktok : ''}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col md={12} className="mb-3">
                      <div className="boxes" style={{ margin: '0px', padding: '14px 10px 0px 20px' }}>
                        <Row>
                          <Col lg={2} className="text-center">
                            <i class="bx bxl-youtube" style={{ paddingTop: '5px', fontSize: '30px' }} ></i>
                          </Col>
                          <Col lg={10}>
                            <FormGroup>
                              <Input
                                autoComplete={false}
                                name="youtube"
                                type="text"
                                onChange={(event) => { setFormData({ ...FormData, 'youtube': event.target.value }); }}
                                value={FormData.youtube ? FormData.youtube : ''}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Col>

              </Row>
              <Row className="mt-3 mb-2">
                <Col sm="12">
                  <Button type="submit" color="success" disabled={Loading ? true : false} style={{ width: '100%' }}>
                    Atualizar seus dados
                  </Button>
                </Col>
              </Row>
            </Col>
          ) : null}
          
          {ContentLoaderGet ? (
            <>
              <Col md={12} className="img-destaque mb-5">
                <ContentLoader
                  speed={2}
                  width="100%"
                  height="1300px"
                  backgroundColor="#ccc"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                </ContentLoader>
              </Col>
            </>
          ) : null}

          </Form>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default PerfilView;