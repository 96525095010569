import React, { useState, useEffect } from "react";
import {
  useNavigate,
} from 'react-router-dom';
import api from "../../../services/api";
import {
  Row,
  Col,
} from "reactstrap";
import LayoutHeader from "../../../components/header";

import foto from '../../../assets/images/regras.jpg';

const MembersRulesScoresList = props => {

  const navigate = useNavigate();

  let NameModule = 'Regras de pontuação';

  document.title = NameModule + " | Empresários SA";

  const [Loading, setLoading] = useState(true);

  const [RowData, setRowData] = useState([]);
  const [BranchesData, setBranchesData] = useState([]);
  const [StatesData, setStatesData] = useState([]);
  const [CitiesData, setCitiesData] = useState([]);

  const [Pages, setPages] = useState([]);
  const [PaginationTotalItems, setPaginationTotalItems] = useState(1);
  const [PaginationPageActual, setPaginationPageActual] = useState(1);
  const [FormData, setFormData] = useState({});

  function LoadData(Page) {

    setLoading(true);
    setRowData([]);

    try {
      const Data = {
        "type": FormData.type || '',
        "status": FormData.status || '', 
        "search": FormData.search || '',
      }
      setPaginationPageActual(Page);
      api.post('/api/meetings/filter?page=' + Page, Data).then(response => {
        return response;
      }).then(data => {
        setRowData(data.data);
        setPaginationTotalItems(data.data.total);
        listItems(data.data.total, data.data.per_page);
        setLoading(false);
      }).catch(error => {
        // console.log(error);
      });
    } catch (error) {
      // console.log(error);
    }
  }

  useEffect(() => { 
    LoadData(1);
  }, []);

  function listItems(total, PorPage) {
    let totalPages = 1;
    let asdasdsa = [];
    if (PorPage < total) {
      totalPages = total / PorPage;
    }
    for (let a = 1; a < totalPages + 1; a++) {
      asdasdsa.push([a]);
    }
    setPages(asdasdsa);
  }

  function PaginationOpen(Page) {
    LoadData(Page);
  }

  return (
    <React.Fragment>

      <LayoutHeader isAdmin={false} show={true} />    

      <div className="box-container">
        <Row className="pt-3910201290">

          <Col md={12} className="content-box">
            <Row>
              <Col md={12} className="text-center">
                <div className="titulo-hr">
                  <span>{NameModule}</span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={12} className="content-box">
            <Row className="mb-3">
              <Col md={12}>
                <div className="boxes">
                  <img src={foto} alt="" width="100%" />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default MembersRulesScoresList;