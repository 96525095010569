import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Form,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Label,
    Spinner,
} from "reactstrap";
import ModalBase from '../../../../../components/modal/modal';
import api from "../../../../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    showToastArray,
} from '../../../../../helpers/generals';
import ModalAddAdminMembersForm from '../../members/modal/form';

export default function ModalInterviewAdminMembersInterview({
    modalId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [FormData, setFormData] = useState({});
    const [UsersData, setUsersData] = useState([]);
    const [Loading, setLoading] = useState(false);
    const [Search, setSearch] = useState('');
    const [Id, setId] = useState('');

    function LoadUsers() {
        setLoading(true);
        setUsersData([]);
        api.get('/api/users/active/members?s=' + Search).then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setUsersData(data.data);
                setLoading(false);
            }
        }).catch(error => {
            // console.log(error);
            setLoading(false);
        })
        api.get('/api/interviews/show/' + modalId).then(response => {
            return response.data
        }).then(data => {
            if (data.success == true) {
                // console.log('data.data.interviewed', data.data[0].interviewed);
                setId(data.data[0].interviewed);
            }
        }).catch(error => {
            // console.log(error);
        })        
    };

    function SearchMember() {
        LoadUsers();
    }

    const [QuestionCheckbox, setQuestionCheckbox] = useState([]);

    function ArrayQuestionCheckbox(value) {
        const checkboxValue = QuestionCheckbox.filter((item) => item === value);
        if (checkboxValue.length === 0) {
            setQuestionCheckbox((QuestionCheckbox) => [...QuestionCheckbox, value]);
        } else {
            const newTasks = QuestionCheckbox.filter((item) => item !== value);
            setQuestionCheckbox(newTasks);
        }
    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            checkeds: QuestionCheckbox || '',
            question_1: FormData.question_1 || '',
            question_2: FormData.question_2 || '',
        },
        validationSchema: Yup.object({
            question_1: Yup.string().required("Responda essa pergunta."),
            question_2: Yup.string().required("Responda essa pergunta."),
        }),
        onSubmit: (values) => {
            // console.log('values', values);
            setLoading(true);
            api.put('/api/interviews/checked/' + modalId, values).then(response => {
                return response.data;
            }).then(data => {
                if (data.success == true) {
                    showToastArray("", "Sucesso", "Cadastro atualizado com sucesso!");
                    setLoading(false);
                    reloadData();
                }
            }).catch(error => {
                if (error) {
                    let message = 'Erro ao enviar o registro!';
                    if (error.response.data.message) {
                        message = error.response.data.message;
                    }
                    showToastArray("error", "Erro!", message);
                    setLoading(false);
                }
            });
        },
    });

    useEffect(() => {
        LoadUsers();
    }, []);

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={'Entrevistar | ' + title}
        >
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Row>
                    <Col sm="6">
                        <Row>
                            <Col sm="12">
                                <h4 className="pb-3">Dados do membro</h4>
                                <ModalAddAdminMembersForm
                                    modalId={Id}
                                    reloadData={reloadData}
                                    blockInputs={true}
                                    toggle2={toggle}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col sm="6">
                        <div style={{ width: '100%', padding: '30px', borderRadius: '8px', border: '1px solid #ccc', background: '#f9f9f9' }}>
                            <Row>
                                <Col sm="12">
                                    <h4 className="pb-3">Dados da entrevista</h4>
                                </Col>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label>
                                            Como você iniciou sua carreira e como chegou até sua posição atual?
                                        </Label>
                                        <Input
                                            className=""
                                            autoComplete={false}
                                            name="question_1"
                                            style={{ height: '100px' }}
                                            type="textearea"
                                            onChange={(event) => { setFormData({ ...FormData, 'question_1': event.target.value }); }}
                                            value={FormData.question_1 ? FormData.question_1 : ''}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.question_1 && validation.errors.question_1 ? true : false
                                            }
                                        />
                                        {validation.touched.question_1 && validation.errors.question_1 ? (
                                            <FormFeedback type="invalid">{validation.errors.question_1}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label>
                                            Quais parcerias você busca hoje?
                                        </Label>
                                        <Input
                                            className=""
                                            autoComplete={false}
                                            name="question_2"
                                            style={{ height: '100px' }}
                                            type="textearea"
                                            onChange={(event) => { setFormData({ ...FormData, 'question_2': event.target.value }); }}
                                            value={FormData.question_2 ? FormData.question_2 : ''}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.question_2 && validation.errors.question_2 ? true : false
                                            }
                                        />
                                        {validation.touched.question_2 && validation.errors.question_2 ? (
                                            <FormFeedback type="invalid">{validation.errors.question_2}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col sm="12">
                                    <Row className="mt-3">
                                        <Col sm="12">
                                            <FormGroup>
                                                <Label>
                                                    Quais membros podem fazer sentido fazer uma aproximação com este novo membro?
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        {/* <Col sm="3" className="text-right">
                                            <div style={{ width: '100%', marginBottom: '20px' }}>
                                                <Button type="button" color="primary" className="" disabled={Loading ? true : false}>
                                                    {Loading ? <Spinner /> : 'Adicionar membro'}
                                                </Button>
                                            </div>
                                        </Col>                                         */}
                                    </Row>
                                    <div style={{ width: '100%', padding: '20px 20px', borderRadius: '0.375rem', border: '1px solid #dee2e6', background: '#fff' }}>
                                        <Row>
                                            <Col sm="8">
                                                <FormGroup floating>
                                                    <Input
                                                        className=""
                                                        autoComplete={false}
                                                        name="search"
                                                        type="text"
                                                        onChange={(event) => {
                                                            setSearch(event.target.value);
                                                        }}
                                                        value={Search ? Search : ''}
                                                    />
                                                    <Label>
                                                        Buscar membro
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                            <Col sm="4">
                                                <Button type="button" onClick={() => { SearchMember(); }} color="success" className="w-100 btnFilter" disabled={Loading ? true : false}>
                                                    {Loading ? <Spinner /> : 'Buscar membro'}
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {UsersData && !Loading ? (
                                                UsersData.length > 0 ? (
                                                    <div style={{ width: '100%', height: '1000px', overflowY: 'scroll', overflowX: 'hidden' }}>
                                                        {UsersData.map((row, index) => (
                                                            <Col sm="12" key={index}>
                                                                <Row>
                                                                    {index == 0 ? (
                                                                        <Col sm="12" className="mb-0">
                                                                            <hr style={{ borderTop: '1px dashed #999' }} />
                                                                        </Col>
                                                                    ) : null}
                                                                    <Col sm="12" className="mb-0">
                                                                        <FormGroup switch>
                                                                            <Input
                                                                                type="switch"
                                                                                checked={QuestionCheckbox.findIndex(val => val === row.id) > -1}
                                                                                value={row.id}
                                                                                onClick={(event) => {
                                                                                    ArrayQuestionCheckbox(event.target.value);
                                                                                }}
                                                                                onBlur={validation.handleBlur}
                                                                                invalid={
                                                                                    QuestionCheckbox.length === 0 ? true : false
                                                                                }
                                                                            />
                                                                            <Label check>
                                                                                <Row className="mb-0">
                                                                                    <Col sm="12">
                                                                                        {row.name}
                                                                                    </Col>
                                                                                    <Col sm="12" style={{ fontSize: '11px', color: '#888' }}>
                                                                                        <Row>
                                                                                            <Col sm="12" className="mb-0">
                                                                                                <span className="me-4">{row.units ? 'Unidade: ' + row.units.name : null}</span>
                                                                                                <span className="me-4">|</span>
                                                                                                <span>{row.cities ? row.cities.name : null} {row.states ? '/' + row.states.uf : null}</span>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Label>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col sm="12" className="mb-0">
                                                                        <hr style={{ borderTop: '1px dashed #999' }} />
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <Col sm="12" className="text-center mt-5 mb-5">
                                                        Sem dados no momento.
                                                    </Col>
                                                )
                                            ) : (
                                                <Col sm="12" className="text-center mt-5">
                                                    <Spinner></Spinner>
                                                </Col>
                                            )}
                                        </Row>
                                    </div>
                                </Col>
                                <Col sm="12" className="mt-4">
                                    <Button type="submit" color="success" disabled={Loading ? true : false}>
                                        {Loading ? <Spinner size="sm" /> : 'Atualizar'}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <Row className="mt-3 mb-2">
                    <Col sm="12" className="text-right pb-2">
                        <button type="button" className="btn btn-secondary" onClick={toggle} disabled={Loading ? true : false}>Fechar</button>
                    </Col>
                </Row>
            </Form>
        </ModalBase>
    );
}

ModalInterviewAdminMembersInterview.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
