import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CkeditorOptionsEditorConfiguration } from '../../../../../components/ckeditor-options';
import {
    Row,
    Col,
    Form,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Label,
    Spinner,
} from "reactstrap";
import ModalBase from '../../../../../components/modal/modal';
import api from "../../../../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    showToastArray,
} from '../../../../../helpers/generals';

export default function AdminMembersApprovalsApprove({
    modalId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [FormData, setFormData] = useState({});
    const [Loading, setLoading] = useState(false);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            note: FormData.note || '',
        },
        validationSchema: Yup.object({
            note: Yup.string().required("Digite uma justificativa."),
        }),
        onSubmit: (values) => {
            setLoading(true);
            api.put('/api/interviews/approve/' + modalId, values).then(response => {
                return response.data;
            }).then(data => {
                if (data.success == true) {
                    showToastArray("", "Sucesso", "Cadastro atualizado com sucesso!");
                    setLoading(false);
                    reloadData();
                }
            }).catch(error => {
                if (error) {
                    let message = 'Erro ao enviar o registro!';
                    if (error.response.data.message) {
                        message = error.response.data.message;
                    }
                    showToastArray("error", "Erro!", message);
                    setLoading(false);
                }
            });

        },
    });

    useEffect(() => {

    }, [modalId]);


    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={'Aprovar | ' + title}
        >
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Row>
                    <Col sm="12" className="text-center mb-3 mt-3" style={{ color: 'red' }}>
                        <h3>Tem certeza que deseja APROVAR essa entrevista?</h3>
                    </Col>
                    <Col sm="12">
                        <FormGroup>
                            <Label>
                                Justificar
                            </Label>
                            <CKEditor
                                editor={ClassicEditor}
                                config={CkeditorOptionsEditorConfiguration}
                                data={FormData.note ? FormData.note : ''}
                                onReady={editor => {
                                    // Allows you to store the editor instance and use it later.
                                    // // console.log('11', editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    // // console.log({ event, editor, data });
                                    setFormData({ ...FormData, 'note': (data) });
                                }}
                                onBlur={(event, editor) => {
                                    // // console.log('22', editor);
                                }}
                                onFocus={(event, editor) => {
                                    // // console.log('333', editor);
                                }}
                            />
                            {validation.touched.note && validation.errors.note ? (
                                <FormFeedback type="invalid">{validation.errors.note}</FormFeedback>
                            ) : null}
                        </FormGroup>                        
                    </Col>
                </Row>

                <Row className="mt-3 mb-2">
                    <Col sm="6">
                    <Button type="submit" color="success" disabled={Loading ? true : false }>
                            {Loading ? <Spinner size="sm"/> : 'Sim' }
                        </Button>
                    </Col>
                    <Col sm="6" className="text-right pb-2">
                        <button type="button" className="btn btn-danger" onClick={toggle} disabled={Loading ? true : false}>Não</button>
                    </Col>
                </Row>
            </Form>
        </ModalBase>
    );
}

AdminMembersApprovalsApprove.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
