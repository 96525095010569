import PropTypes from 'prop-types';
import React from "react";
import ReactDom from 'react-dom';
import { Modal, ModalBody } from "reactstrap";

export default function ModalBase({ 
  children,
  toggle,
  isOpen,
  size = 'sm',
  centered = true,
  title,
}) {
  return ReactDom.createPortal(
    <Modal size={size} isOpen={isOpen} toggle={toggle} centered={centered} backdrop={'static'} style={{ zIndex: '999999 !important' }}>
      <div className="modal-content" style={{ background: '#f3f2ef', zIndex: '999999 !important' }}>
        <ModalBody className="px-4 py-4 mb-0 pb-2">
          <button type="button" onClick={toggle} className="btn-close position-absolute end-0 px-4"></button>
          <div className="mb-4 mx-auto">
            <h5 className="w-100 formTitle">
              {title} 
            </h5>
          </div>
          <div className="mb-0 pb-0 mx-auto">
            {children}
          </div>
        </ModalBody>
      </div>
    </Modal>,
  document.getElementById('modal'));
}

ModalBase.propTypes = {
  children: PropTypes.element.isRequired,
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
  centered: PropTypes.string.isRequired,
  title: PropTypes.string,
}
