
import toastr from "toastr";
import "toastr/build/toastr.min.css";

export function MaskPhone(value) {
  if (value) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{0})(\d)/, '$1($2')
      .replace(/(\d{2})(\d)/, '$1) $2')
      .replace(/(\d{4})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  }
}

export function MaskCellPhone(value) {
  if (value) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{0})(\d)/, '$1($2')
      .replace(/(\d{2})(\d)/, '$1) $2')
      .replace(/(\d{5})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  }
}

export function MaskCep(value) {
  if (value) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{5})(\d{1,3})/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1');
  }
}

export function MaskCpf(value) {
  if (value) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }
}

export function MaskCnpj(value) {
  if (value) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d{1,4})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }
}

export function isEmailValid(email) {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

export function temNumero(key) {
  return /\d/.test(key);
}

export function somenteNumero(numero) {
  let regex = /^[0-9.]+$/;
  return regex.test(numero);
}

export function temMaiusculasEMinusculas(key) {
  return /^(?=.*[a-z])(?=.*[A-Z]).+/.test(key);
}

export function cpfValido(cpf) {
  const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
  return regex.test(cpf);
}

export function cepValido(cep) {
  // Elimina CEPs invalidos conhecidos
  if (cep == '00000-000'
    || cep == '11111-111'
    || cep == '22222-222'
    || cep == '33333-333'
    || cep == '44444-444'
    || cep == '55555-555'
    || cep == '66666-666'
    || cep == '77777-777'
    || cep == '88888-888'
    || cep == '99999-999') return false;

  const regex = /^[0-9]{5}-[0-9]{3}$/;
  return regex.test(cep);
}

export function cnpjValido(cnpj) {
  const regex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
  return regex.test(cnpj);
}

export const retornarApenasNumeros = (texto) => {
  let regex = texto.match(/[0-9]/g);
  if (regex) regex = regex.join('');
  return Number(regex);
};

export const retornarLetras = (texto) => {
  // Aceita apóstrofo e espaço, se for nome. Exemplo: Pedro D'ávila
  let regex = texto.match(/[a-zA-Z\u00C0-\u00FF' ]+/i);
  if (regex) regex = regex.join('');
  return regex;
};

export function retornarNumeroEndereco(texto) {
  // Aceita números, barra e traço. Exemplos: 123, 12-B, 402/A
  let regex = texto.match(/[0-9a-zA-Z\u00C0-\u00FF-/]+/ig);
  if (regex) regex = regex.join('');
  return regex;
}

export const removerEmojis = (text) => text?.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');

export const removerEspacosExtras = (texto) => texto?.replace(/( )+/g, ' ');

export function validarCNPJ(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj == '') return false;

  if (cnpj.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (cnpj == '00000000000000'
    || cnpj == '11111111111111'
    || cnpj == '22222222222222'
    || cnpj == '33333333333333'
    || cnpj == '44444444444444'
    || cnpj == '55555555555555'
    || cnpj == '66666666666666'
    || cnpj == '77777777777777'
    || cnpj == '88888888888888'
    || cnpj == '99999999999999') return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;
  tamanho += 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;
  return true;
}

export function validarCPF(strCPF) {
  strCPF = strCPF.replace(/[^\d]+/g, '');
  let Soma;
  let Resto;
  Soma = 0;

  for (let i = 1; i <= 9; i++) {
    Soma += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
  }
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(9, 10), 10)) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++) {
    Soma += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
  }
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(10, 11), 10)) return false;
  return true;
}

export function DateToData(value, separator = "-") {

  const value2 = value.replace("/", "-");
  const explode = value2.split("-");

  const Day = explode[0];
  const Month = explode[1];
  const Year = explode[2];

  return Year + separator + Month + separator + Day;
}

export function formatDataToDate(value) {
  const explode = value.split("/");
  const Day = explode[0];
  const Month = explode[1];
  const Year = explode[2];
  return Year + '-' + Month + '-' + Day;
}

export function formatarMilharReais(value) { // somente utilizar para alimentar CAMPO TEXTO do formulário, porque pode gerar bug, por exemplo quando recebe value '2.3'
  if (value) {
    let v = value.replace(/\D/g, '');
    v = `${(v / 100).toFixed(2)}`;
    v = v.replace('.', ',');
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    return v;
  }
  return null;
}

export function desformatarMilharesReais(valueStr) {
  if (!valueStr || !valueStr.replaceAll) return;
  let value = valueStr.replaceAll('.', '');
  value = value.replaceAll(',', '.');
  return parseFloat(value);
}

export function MaskPorc(value) {
  let valueNew = '';
  if (value >= 1 && value <= 100) {
    valueNew = value;
  } else {
    valueNew = '';
  }
  return valueNew;
}

export function MaskDays(value) {
  let valueNew = '';
  if (value >= 1 && value <= 30) {
    valueNew = value;
  } else {
    valueNew = '';
  }
  return valueNew;
}