import PropTypes from 'prop-types';
import React, { useState, useEffect } from "react";
import {
    Col,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    Spinner
} from 'reactstrap';
import api from "../services/api";
import { Link } from "react-router-dom";

export default function LayoutBannerHeader({
    show,
}) {

    const [Loading, setLoading] = useState(true);
    const [items, setitems] = useState([]);

    const Load = async => {
        api.get('/api/adverts/active').then(response => {
            return response.data
        }).then(data => {
            if (data.success == true) {
                // console.log('news', data.data);
                setitems(data.data);
                setLoading(false);
            }
        }).catch(error => {
            // console.log(error)
        })
    };

    useEffect(() => {
        Load();
    }, []);

    // State for Active index
    const [activeIndex, setActiveIndex] = React.useState(0);

    // State for Animation
    const [animating, setAnimating] = React.useState(false);

    // Items array length
    const itemLength = items.length - 1

    // Previous button for Carousel
    const previousButton = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ?
            itemLength : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    // Next button for Carousel
    const nextButton = () => {
        if (animating) return;
        const nextIndex = activeIndex === itemLength ?
            0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    // Carousel Item Data
    const carouselItemData = items.map((item) => {
        // console.log('item', item);
        return (
            <CarouselItem
                key={item.image}
                onExited={() => setAnimating(false)}
                onExiting={() => setAnimating(true)}
            >
                <img src={'https://api.empresariossa.com/storage/files/' + item.image} alt={item.name} />
            </CarouselItem>
        );
    });

    return (
        <React.Fragment>
            <Col lg={12} className="mb-4">
                {Loading ? (
                    <div className={localStorage.getItem('UserType') === 'member' ? "LayoutBannerHeader" : "LayoutBannerHeader "}>
                        <div style={{ width: '100%', textAlign: 'center', padding: '100px 30px' }}>
                            <Spinner></Spinner>
                        </div>
                    </div>
                ) : (
                    <div className={localStorage.getItem('UserType') === 'member' ? "LayoutBannerHeader" : "LayoutBannerHeader "}>
                        <Carousel className="LayoutBannerHeaderCarousel" previous={previousButton} next={nextButton} activeIndex={activeIndex}>
                            <CarouselIndicators items={items}
                                activeIndex={activeIndex}
                                onClickHandler={(newIndex) => {
                                    if (animating) return;
                                    setActiveIndex(newIndex);
                                }} />
                            {carouselItemData}
                            {/* <CarouselControl directionText="Prev" direction="prev" onClickHandler={previousButton} />
                        <CarouselControl directionText="Next" direction="next" onClickHandler={nextButton} /> */}
                        </Carousel>
                    </div>
                )}
            </Col>
        </React.Fragment>
    );
}

LayoutBannerHeader.propTypes = {
    show: PropTypes.bool.isRequired,
}
