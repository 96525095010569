import React, { useState, useEffect } from "react";
import {
  Link,
  useNavigate,
  useParams
} from 'react-router-dom';
import api from "../../../services/api";
import {
  Row,
  Col,
  Container,
  Button
} from "reactstrap";
import ContentLoader from "react-content-loader";
import LayoutHeader from "../../../components/header";
import LayoutBannerHeader from "../../../components/bannerHeader";
import ModalScheduleMeetings from "../members/modal/schedule-meetings/agendar";

const Perfil = props => {
  const navigate = useNavigate();

  const { slug } = useParams();
  const [Loading, setLoading] = useState(false);
  const [CheckedPage, setCheckedPage] = useState(true);
  const [FormData, setFormData] = useState({});

  const [SwalOpen, setSwalOpen] = useState('');  
  const [ModalOpen, setModalOpen] = useState(false);
  const [Modal, setModal] = useState('');
  const [ModalId, setModalId] = useState(0);

  function LoadData() {

    setLoading(true);
    setFormData({});

    try { 
      if (slug) {
        api.get('/api/users/show/slug/' + slug).then(response => {
          return response.data;
        }).then(data => {
          if (data.results > 0) {
            setFormData(data.data);
            setCheckedPage(false);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          } else {
            setLoading(false);
          }
        }).catch(error => {
          // console.log(error);
        });
      }
    } catch (error) {
      // console.log(error);
    }
  }

  function reloadData() {
    setModalOpen(false);
    setModal('');
    setSwalOpen('');
    setModalId(0);
  }

  useEffect(() => {
    LoadData();
  }, []);

  function openScheduleMeetings(id, slug) {
    navigate('/members/schedule-meetings/' + slug + '/' + id);
  }

  return (
    <React.Fragment>

      {Modal == 'agendar' ? (<ModalScheduleMeetings
        toggle={() => setModalOpen(!ModalOpen)}
        isOpen={ModalOpen}
        modalId={ModalId}
        size="xl"
        title="Convidar"
        reloadData={reloadData}
      />) : ''}

      <LayoutHeader isAdmin={false} show={true} />

      <Container>
        <Row>

          <LayoutBannerHeader show={true} />

          {!CheckedPage && !Loading ? (
            <Col md={12} className="perfil-box">
              <Row>

                <Col md={12} className="content-box">
                  <Row>
                    <Col md={12} className="text-center">
                      <div className="titulo-hr">
                        <span>Página do membro</span>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col md={9} className="content-box">
                  <Row>
                    <Col md={4}>
                      <div className="boxes">
                        <Row>
                          <Col md={12}>
                            <img class="avatar" src={FormData.avatar ? ("https://api.empresariossa.com/storage/images/avatar/" + FormData.avatar) : '-'} />
                          </Col>
                        </Row>
                      </div>
                      <div className="boxes">
                        <Row>
                          <Col md={12}>
                            <span>Membro</span>
                          </Col>
                          <Col md={12} className="mb-2">
                            <h2>{FormData.nickname ? FormData.nickname : '-'}</h2>
                          </Col>
                          <Col md={12}>
                            <span>Unidade</span>
                          </Col>
                          <Col md={12} className="mb-2">
                            <h2>{FormData.units ? FormData.units.name : '-'}</h2>
                          </Col>
                          <Col md={12}>
                            <span>Local</span>
                          </Col>
                          <Col md={12}>
                            <h2>{FormData.cities ? FormData.cities.name : '-'}/{FormData.states ? FormData.states.uf : '-'}</h2>
                          </Col>
                        </Row>
                      </div>
                      <div className="boxes interesses">
                        <Row>
                          <Col md={12} className="mb-1">
                            <span>Intereses(s)</span>
                          </Col>
                          <Col md={12}>
                            <hr />
                          </Col>

                          {FormData.interests ? (
                            FormData.interests.map((row, index) => (
                              <div key={index}>
                                <Col md={12}>
                                  {row}
                                </Col>
                                <Col md={12}>
                                  <hr />
                                </Col>
                              </div>
                            ))
                          ) : '-'}

                        </Row>
                      </div>
                    </Col>
                    <Col md={8}>
                      <div className="boxes">
                        <Row>
                          <Col md={12} className="mb-3">
                            <span>Vídeo de Apresentação</span>
                          </Col>
                          <Col md={12}>
                            {FormData.movie ? (
                              <iframe width="100%" height="320" src={FormData.movie ? 'https://www.youtube.com/embed/' + FormData.movie : null} title={FormData.nickname ? FormData.nickname : '-'} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            ) : (
                              <iframe width="100%" height="320" src="https://www.youtube.com/embed/KhJrrWX9REc" title={FormData.nickname ? FormData.nickname : '-'} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            )}
                          </Col>
                        </Row>
                      </div>
                      <div className="boxes">
                        <Row>
                          <Col md={12} className="mb-3">
                            <span>Descrição</span>
                          </Col>
                          <Col md={12}>
                            <div className="overflow-x" style={{ width: '100%', height: '223px' }}>
                              <p dangerouslySetInnerHTML={{ __html:FormData.description}}></p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={3} className="content-box">
                  <Row>
                    <Col md={12}>
                      <Button 
                        color="info" 
                        className="btn-agendar-reuniao"
                        onClick={() => {
                          setModal('agendar');
                          setModalOpen(true);                                    
                          setSwalOpen('');
                          setModalId(FormData.id);                          
                        }}    
                      >
                        Convidar
                      </Button>{' '}
                    </Col>
                    <Col md={12}>
                      <div className="boxes horarios">
                        <Row>
                          <Col md={12} className="mb-2">
                            <span>Horários para reuniões</span>
                          </Col>
                          <Col md={12}>
                            <hr />
                          </Col>
                          <Col md={12}>
                            <h5>Segunda-feira</h5>
                          </Col>
                          <Col md={12}>
                            <label>
                              {FormData.time_i_1 && FormData.time_f_1 ? (
                                FormData.time_i_1 + ' às ' + FormData.time_f_1
                              ) : (
                                'Sem horário disponível'
                              )}
                            </label>
                          </Col>
                          <Col md={12}>
                            <hr />
                          </Col>
                          <Col md={12}>
                            <h5>Terça-feira</h5>
                          </Col>
                          <Col md={12}>
                            <label>
                              {FormData.time_i_2 && FormData.time_f_2 ? (
                                FormData.time_i_2 + ' às  ' + FormData.time_f_2
                              ) : (
                                'Sem horário disponível'
                              )}
                            </label>
                          </Col>
                          <Col md={12}>
                            <hr />
                          </Col>
                          <Col md={12}>
                            <h5>Quarta-feira</h5>
                          </Col>
                          <Col md={12}>
                            <label>
                              {FormData.time_i_3 && FormData.time_f_3 ? (
                                FormData.time_i_3 + ' às ' + FormData.time_f_3
                              ) : (
                                'Sem horário disponível'
                              )}
                            </label>
                          </Col>
                          <Col md={12}>
                            <hr />
                          </Col>
                          <Col md={12}>
                            <h5>Quinta-feira</h5>
                          </Col>
                          <Col md={12}>
                            <label>
                              {FormData.time_i_4 && FormData.time_f_4 ? (
                                FormData.time_i_4 + ' às ' + FormData.time_f_4
                              ) : (
                                'Sem horário disponível'
                              )}
                            </label>
                          </Col>
                          <Col md={12}>
                            <hr />
                          </Col>
                          <Col md={12}>
                            <h5>Sexta-feira</h5>
                          </Col>
                          <Col md={12}>
                            <label>
                              {FormData.time_i_5 && FormData.time_f_5 ? (
                                FormData.time_i_5 + ' às ' + FormData.time_f_5
                              ) : (
                                'Sem horário disponível'
                              )}
                            </label>
                          </Col>
                          <Col md={12}>
                            <hr />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="text-center">
                      <div className="titulo-hr">
                        <span>Redes Sociais</span>
                      </div>
                    </Col>
                    <Col md={12} className="mt-3">
                      <Row>

                        {FormData.instagram ? (
                          <>
                            <Col md={4} className="text-center mb-4">
                              <Link
                                className="mx-2"
                                target="_blank"
                                to={"https://www.instagram.com/" + FormData.instagram}
                              >
                                <i class="bx bxl-instagram" style={{ paddingTop: '0px', fontSize: '30px' }} ></i>
                              </Link>
                            </Col>
                          </>
                        ) : ''}

                        {FormData.facebook ? (
                          <>
                            <Col md={4} className="text-center mb-4">
                              <Link
                                className="mx-2"
                                target="_blank"
                                to={"https://www.facebook.com/" + FormData.facebook}
                              >
                                <i class="bx bxl-facebook" style={{ paddingTop: '0px', fontSize: '30px' }} ></i>
                              </Link>
                            </Col>
                          </>
                        ) : ''}

                        {FormData.linkedIn ? (
                          <>
                            <Col md={4} className="text-center mb-4">
                              <Link
                                className="mx-2"
                                target="_blank"
                                to={"https://www.linkedin.com/in/" + FormData.linkedIn}
                              >
                                <i class="bx bxl-linkedin" style={{ paddingTop: '0px', fontSize: '30px' }} ></i>
                              </Link>
                            </Col>
                          </>
                        ) : ''}

                        {FormData.tiktok ? (
                          <>
                            <Col md={4} className="text-center mb-4">
                              <Link
                                className="mx-2"
                                target="_blank"
                                to={"https://www.tiktok.com/@" + FormData.tiktok}
                              >
                                <i class="bx bxl-tiktok" style={{ paddingTop: '0px', fontSize: '30px' }} ></i>
                              </Link>
                            </Col>
                          </>
                        ) : ''}

                        {FormData.twitter ? (
                          <>
                            <Col md={4} className="text-center mb-4">
                              <Link
                                className="mx-2"
                                target="_blank"
                                to={"https://www.twitter.com/" + FormData.instagram}
                              >
                                <i class="bx bxl-twitter" style={{ paddingTop: '0px', fontSize: '30px' }} ></i>
                              </Link>
                            </Col>
                          </>
                        ) : ''}

                        {FormData.youtube ? (
                          <>
                            <Col md={4} className="text-center mb-4">
                              <Link
                                className="mx-2"
                                target="_blank"
                                to={"https://www.youtube.com/@" + FormData.youtube}
                              >
                                <i class="bx bxl-youtube" style={{ paddingTop: '0px', fontSize: '30px' }} ></i>
                              </Link>
                            </Col>
                          </>
                        ) : ''}

                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col md={12} className="content-box">
                  <Row>
                    <Col md={12} className="text-center">
                      <div className="titulo-hr">
                        <span>Empresa</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={9} className="mb-5">
                  <div className="boxes px-3">
                    <Row>
                      <Col md={12}>
                        <Row>
                          <Col md={12}>
                            <span>Empresa</span>
                          </Col>
                          <Col md={12}>
                            <p>{FormData.companie_name ? FormData.companie_name : '-'}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={3}>
                        <Row>
                          <Col md={12}>
                              <span>Função</span>
                            </Col>
                            <Col md={12}>
                              <p>{FormData.func_companie ? FormData.func_companie : '-'}</p>
                            </Col>
                        </Row>
                      </Col>
                      <Col md={3}>
                        <Row>
                          <Col md={12}>
                            <span>Ramo</span>
                          </Col>
                          <Col md={12}>
                            <p>{FormData.branches ? FormData.branches.name : '-'}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={3}>
                        <Row>
                          <Col md={12}>
                            <span>Qts. funcionário(s): </span>
                          </Col>
                          <Col md={12}>
                            <p>
                              {FormData.amount_employees ? ( FormData.amount_employees == 1 ? '0' : null ) : '-'}
                              {FormData.amount_employees ? ( FormData.amount_employees == 2 ? '1 à 5' : null ) : '-'}
                              {FormData.amount_employees ? ( FormData.amount_employees == 3 ? '6 à 15' : null ) : '-'}
                              {FormData.amount_employees ? ( FormData.amount_employees == 4 ? '16 à 30' : null ) : '-'}
                              {FormData.amount_employees ? ( FormData.amount_employees == 5 ? 'Acima de 31' : null ) : '-'}
                            </p>
                          </Col>
                        </Row>
                      </Col>

                      <Col md={3}>
                        <Row>
                          <Col md={12}>
                            <span>Tempo de atuação: </span>
                          </Col>
                          <Col md={12}>
                            <p>{FormData.time_atuation ? FormData.time_atuation : '-'}</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <div className="boxes px-3">
                    <Row>
                      <Col md={12}>
                        <span>Sobre</span>
                      </Col>
                      <Col md={12}>
                        <div className="overflow-x" style={{ width: '100%', height: '365px' }}>
                          <p dangerouslySetInnerHTML={{ __html:FormData.companie_description}}></p>
                        </div>                        
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={3} className=" mb-5">
                  <div className="boxes">
                    <Row>
                      <Col md={12} className="px-4 py-3">
                        <img class="logo-page" src={FormData.logo ? ("https://api.empresariossa.com/storage/images/logo/" + FormData.logo) : "https://api.empresariossa.com/storage/images/logo/default.png"} />
                      </Col>
                    </Row>
                  </div>
                  <div className="boxes">
                    <Row>
                      <Col md={12}>
                        <span>Website:</span>
                      </Col>
                      <Col md={12} className="mb-2">
                        <h2>{FormData.website ? FormData.website : '-'}</h2>
                      </Col>
                    </Row>
                  </div>
                  <div className="boxes">
                    <Row>
                      <Col md={12}>
                        <span>E-mail</span>
                      </Col>
                      <Col md={12} className="mb-2">
                        <h2>{FormData.email ? FormData.email : '-'}</h2>
                      </Col>
                    </Row>
                  </div>
                  <div className="boxes">
                    <Row>
                      <Col md={12}>
                        <span>Celular:</span>
                      </Col>
                      <Col md={12} className="mb-2">
                        <h2>{FormData.cell_phone ? FormData.cell_phone : '-'}</h2>
                      </Col>
                    </Row>
                  </div>
                  <div className="boxes">
                    <Row>
                      <Col md={12}>
                        <span>Endereço:</span>
                      </Col>
                      <Col md={12} className="mb-2">
                        <h2>{FormData.address ? FormData.address : '-'}, {FormData.number ? FormData.number : '-'}</h2>
                        <h2>{FormData.district ? 'Bairro: ' + FormData.district : '-'}</h2>
                        <h2>{FormData.zip_code ? 'CEP: ' + FormData.zip_code : '-'}</h2>
                        <h2>{FormData.cities ? FormData.cities.name : '-'}/{FormData.states ? FormData.states.uf : '-'}</h2>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          ) : null}

          {CheckedPage && !Loading ? (
            <Col md={12} className="perfil-box">
              <Row>
                <Col md={12} className="pagine-404">
                  <i class='bx bx-error'></i>
                  <h2>Página não encontrada</h2>
                  <h6>Não localizamos nenhuma página com essa caminho.</h6>
                </Col>
              </Row>
            </Col>
          ) : null}

          {Loading ? (
            <>
              <Col md={12} className="img-destaque mb-5">
                <ContentLoader
                  speed={2}
                  width="100%"
                  height="800px"
                  backgroundColor="#ccc"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                </ContentLoader>
              </Col>
            </>
          ) : null}

        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Perfil;