import PropTypes from 'prop-types';
import React from "react";
import { Routes, Route } from "react-router-dom";
import { authProtectedRoutes, publicRoutes } from "./routes";
import Authmiddleware from "./routes/route";
import "./assets/scss/styles.scss";
import "./assets/scss/responsive.scss";

const App = () => {
  return (
    <React.Fragment>
      <Routes>
        
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <div>
                {route.component}
              </div>
            }
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <div>{route.component}</div>
              </Authmiddleware>}
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;