import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef, useCallback } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import {
    Row,
    Col,
    Form,
    Badge,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Label,
} from "reactstrap";
import ModalBase from '../../components/modal/modal';
import api from "../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    showToastArray,
  } from '../../helpers/generals';

export default function ModalCrop({
    id,
    NameImage,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [Loading, setLoading] = useState(false);
    const cropperRef = useRef(null);
    const [croppedImg, setCroppedImg] = useState("");

    const onCrop = () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;
        setCroppedImg(cropper.getCroppedCanvas().toDataURL());
        // // console.log('img', cropper.getCroppedCanvas().toDataURL());
    };    

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
          image: croppedImg || '',
          old_image: NameImage || '',
        },
        validationSchema: Yup.object({
        }), onSubmit: (values) => {
        //   // console.log('values', values);
          setLoading(true);
          const res = api.put('/api/users/avatar/crop/' + id, values).then(response => {
            return response.data;
          }).then(data => {
            if (data.success == true) {
              reloadData();
              setLoading(false);
            }
          }).catch(error => {
            if (error) {
              let message = 'Erro ao enviar o registro!';
              if (error.response.data.message) {
                message = error.response.data.message;
              }
              showToastArray("error", "Erro!", message);
              setLoading(false);
            }
          });
        },
      });
    
    useEffect(() => { 

    }, []); 

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={title}
        >
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Row>
                    <Col sm="12">
                        <Cropper
                            src={"https://api.empresariossa.com/storage/images/avatar/" + NameImage}
                            style={{ height: 500, width: "100%" }}
                            initialAspectRatio={16 / 9}
                            guides={true}
                            crop={onCrop}
                            ref={cropperRef}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            // background={false}
                            responsive={true}
                            autoCropArea={1}
                            aspectRatio={4 / 4}
                            checkOrientation={true}
                        />
                    </Col>                     
                </Row>

                <Row className="mt-3 mb-2">
                    <Col xs="6">
                        <Button type="submit" color="success" disabled={Loading ? true : false }>
                            Atualizar
                        </Button>
                    </Col>                    
                    <Col xs="6" className="text-right pb-2">
                        <button type="button" className="btn btn-secondary" onClick={toggle} disabled={Loading ? true : false }>Fechar</button>
                    </Col>
                </Row>
            </Form>
        </ModalBase>
    );
}

ModalCrop.propTypes = {
    id: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
