import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import {
    Row,
    Col,
    Button,
    Table,
    Spinner,
    FormGroup,
    Input,
    Label,
} from "reactstrap";
import ModalBase from '../../components/modal/modal';
import api from "../../services/api";
import { MaskCellPhone, MaskCpf } from '../../helpers/mascarasParaCampos';

export default function ModalEventsPresences({
    modalId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [Loading, setLoading] = useState(true);
    const [RowData, setRowData] = useState([]);

    const [FormData, setFormData] = useState({});

    const [Pages, setPages] = useState([]);
    const [PaginationTotalItems, setPaginationTotalItems] = useState(1);
    const [PaginationPageActual, setPaginationPageActual] = useState(1);

    const [ids, setIds] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    function LoadData(Page) {

        setLoading(true);
        setRowData([]);

        try {
            const Data = {
                "events": modalId,
                "search": FormData.search || '',
            }
            setPaginationPageActual(Page);
            api.post('/api/presences/filter?page=' + Page, Data).then(response => {
                return response;
            }).then(data => {
                setRowData(data.data);

                for (let a = 0; a < data.data.data.length; a++) {
                    if (data.data.data[a] && data.data.data[a].events && data.data.data[a].events.length > 0) {
                        setIds(prevIds => [...prevIds, data.data.data[a].id]);
                        setIds(prevIds => [...new Set(prevIds)]);
                    }
                }



                setPaginationTotalItems(data.data.total);
                listItems(data.data.total, data.data.per_page);
                setLoading(false);
            }).catch(error => {
                // console.log(error);
            });
        } catch (error) {
            // console.log(error);
        }
    }

    function update(Page) {
        try {
            const Data = {
                "events": modalId,
                "ids": ids || '',
            }
            setPaginationPageActual(Page);
            api.put('/api/presences/users/update', Data).then(response => {
                return response;
            }).then(data => {
                LoadData(PaginationPageActual);
            }).catch(error => {
                // console.log(error);
            });
        } catch (error) {
            // console.log(error);
        }
    }

    useEffect(() => {
        LoadData(1);
    }, [modalId]);

    function listItems(total, PorPage) {
        let totalPages = 1;
        let asdasdsa = [];
        if (PorPage < total) {
            totalPages = total / PorPage;
        }
        for (let a = 1; a < totalPages + 1; a++) {
            asdasdsa.push([a]);
        }
        setPages(asdasdsa);
    }

    function PaginationOpen(Page) {
        LoadData(Page);
    }

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={'Presenças | ' + title}
        >
            <Row >
                <Col md={12} className="content-box">
                    <Row>
                        <Col lg={12}>
                            <Row>
                                <Col lg={1} className="text-center">
                                    <Button
                                        className="btnFilter"
                                        color="primary"
                                        onClick={() => {
                                            update();
                                        }}
                                    >
                                        Atualizar
                                    </Button>
                                </Col>
                                <Col lg={10}>
                                    <FormGroup floating>
                                        <Input
                                            id="search"
                                            className="search"
                                            name="search"
                                            type="search"
                                            placeholder="Especilidade"
                                            onChange={(event) => { setFormData({ ...FormData, 'search': event.target.value }); }}
                                            value={FormData.search ? FormData.search : ''}
                                        />
                                        <Label>
                                            Buscar
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col lg={1} className="text-center">
                                    <Button
                                        className="btnFilter"
                                        onClick={() => {
                                            LoadData();
                                        }}
                                    >
                                        Filtrar
                                    </Button>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Col>
                <Col md={12} className="content-box">
                    <Row className="mb-3">
                        <Col md={12}>
                            <div className="boxes">
                                <Table responsive size="sm" className="table">
                                    <thead>
                                        <th width="1%">
                                            <Input
                                                type="checkbox"
                                                name="marca_desmarca_todos"
                                                checked={selectAll}
                                                onChange={() => {
                                                    const newSelectAll = !selectAll;
                                                    setSelectAll(newSelectAll);
                                                    if (newSelectAll) {
                                                        // Se marca_desmarca_todos estiver marcado, adicione todos os IDs ao array ids
                                                        setIds(RowData.data.map(row => row.id));
                                                    } else {
                                                        // Se marca_desmarca_todos estiver desmarcado, esvazie o array ids
                                                        setIds([]);
                                                    }
                                                }}
                                            />
                                        </th>
                                        <th> Unidade </th>
                                        <th> Membro </th>
                                        <th> CPF </th>
                                        <th> Email </th>
                                        <th> Celular </th>
                                        <th> Empresa </th>
                                        <th width="10%"> Cadastro </th>
                                    </thead>
                                    <tbody>

                                        {Loading ? (
                                            <tr>
                                                <td colSpan={10} style={{ border: '0px' }}>
                                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                                        <Spinner color="info" className="mt-5 mb-2"></Spinner>
                                                        <br />
                                                        Carregando dados...
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : null}

                                        {!Loading && RowData.results === 0 ? (
                                            <tr>
                                                <td colSpan={30} style={{ border: '0px', paddingTop: '30px', textAlign: 'center' }}>
                                                    Sem dados com esses filtros no momento.
                                                </td>
                                            </tr>
                                        ) : null}

                                        {RowData && !Loading ? (
                                            RowData.data.map((row, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <Input
                                                            type="checkbox"
                                                            name="checkboxItems"
                                                            checked={selectAll || (ids && ids.indexOf(row.id) > -1)}
                                                            onChange={(event) => {
                                                                const checked = event.target.checked;
                                                                const id = row.id;
                                                                if (checked) {
                                                                    setIds(prevIds => [...prevIds, id]);
                                                                    setIds(prevIds => [...new Set(prevIds)]);
                                                                } else {
                                                                    setIds(prevIds => {
                                                                        const index = prevIds.indexOf(id);
                                                                        if (index > -1) {
                                                                            prevIds.splice(index, 1);
                                                                        }
                                                                        return [...prevIds];
                                                                    });
                                                                    // Se um checkbox é desmarcado, marque_desmarca_todos também deve ser desmarcado
                                                                    setSelectAll(false);
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                    <td>
                                                        {row && row.units ? row.units.name : '-'}
                                                    </td>
                                                    <td>
                                                        {row.name ? row.name : '-'}
                                                    </td>
                                                    <td>
                                                        {row.name ? MaskCpf(row.cpf) : '-'}
                                                    </td>
                                                    <td>
                                                        {row.email ? row.email : '-'}
                                                    </td>
                                                    <td>
                                                        {row.cell_phone ? MaskCellPhone(row.cell_phone) : '-'}
                                                    </td>
                                                    <td>
                                                        {row.companie_name ? row.companie_name : '-'}
                                                    </td>
                                                    <td>
                                                        {row.created_at ? row.created_at : '-'}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : null}

                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                        <Col md={9}>
                            <div className="TablePagination">
                                Mostrando página <strong>{PaginationPageActual}</strong> de <strong>1</strong>. | Total de registros é de <strong>{PaginationTotalItems}</strong>.
                            </div>
                        </Col>
                        <Col md={3}>
                            <FormGroup row className="text-right">
                                <Label
                                    for="exampleEmail"
                                    sm={4}
                                >
                                    Página
                                </Label>
                                <Col sm={8}>
                                    <Input
                                        className=""
                                        autoComplete={false}
                                        name="companie_taxation"
                                        type="select"
                                        placeholder="Tributação"
                                        onChange={(event) => { PaginationOpen(event.target.value); }}
                                    >
                                        {Pages.map((row) => (
                                            <option key={row} value={row} > {row} </option>
                                        ))}
                                    </Input>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </ModalBase>
    );
}

ModalEventsPresences.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
