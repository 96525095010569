import PropTypes from 'prop-types';
import React from "react";
import Swal from 'sweetalert2';

import api from "../services/api";

export default function SwalDeleteItem({
  DeleteId,
  PathApi,
  MethodApi="delete",
  reloadData,
  title="Deletar",
  text="Você tem certeza que deseja deletar este dado?",
  isConfirmedTitle="Sucesso!",
  isConfirmedText="Dado deletado com sucesso.",
}) {

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success my-1 mx-2',
      cancelButton: 'btn btn-danger my-1 mx-2'
    },
    buttonsStyling: false
  })

  let confirmButtonText = "Sim";
  let cancelButtonText = "Não";
  let CancelledTile = "Cancelada";
  let CancelledText = "Ação foi cancelada.";    

  // Swal.fire({
  //   title: 'Submit your Github username',
  //   input: 'text',
  //   inputAttributes: {
  //     autocapitalize: 'off'
  //   },
  //   showCancelButton: true,
  //   confirmButtonText: 'Look up',
  //   showLoaderOnConfirm: true,
  //   preConfirm: (login) => {
  //     return fetch(`//api.github.com/users/${login}`)
  //       .then(response => {
  //         if (!response.ok) {
  //           throw new Error(response.statusText)
  //         }
  //         return response.json()
  //       })
  //       .catch(error => {
  //         Swal.showValidationMessage(
  //           `Request failed: ${error}`
  //         )
  //       })
  //   },
  //   allowOutsideClick: () => !Swal.isLoading()
  // }).then((result) => {
  //   if (result.isConfirmed) {
  //     Swal.fire({
  //       title: `${result.value.login}'s avatar`,
  //       imageUrl: result.value.avatar_url
  //     })
  //   }
  // })  

  swalWithBootstrapButtons.fire({
    title: title,
    text: text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    reverseButtons: true
  }).then((result) => {
    if (result.isConfirmed) {
      if (MethodApi == 'delete') {
        api.delete(PathApi + DeleteId).then(response => {
          return response.data;
        }).then(data => {
          if (data.success === true) {
            swalWithBootstrapButtons.fire(
              isConfirmedTitle,
              isConfirmedText,
              'success'
            );
            reloadData();
          }
        }).catch(error => {
          if (error) {
            reloadData();
          }
        });
      }

      if (MethodApi == 'put') {
        api.put(PathApi + DeleteId).then(response => {
          return response.data;
        }).then(data => {
          if (data.success === true) {
            let message = isConfirmedText;
            if (data.message) message = data.message;
            swalWithBootstrapButtons.fire(
              isConfirmedTitle,
              message,
              'success'
            );
            reloadData();
          }
        }).catch(error => {
          if (error) {
            reloadData();
          }
        });
      }

      if (MethodApi == 'patch') {
        api.patch(PathApi + DeleteId).then(response => {
          return response.data;
        }).then(data => {
          if (data.success === true) {
            let message = isConfirmedText;
            if (data.message) message = data.message;
            swalWithBootstrapButtons.fire(
              isConfirmedTitle,
              message,
              'success'
            );
            reloadData();
          }
        }).catch(error => {
          if (error) {
            reloadData();
          }
        });
      }      
    } else if (
      result.dismiss === Swal.DismissReason.cancel
    ) {
      swalWithBootstrapButtons.fire(
        CancelledTile,
        CancelledText,
        'error'
      );
      reloadData();
    }
  });

  return (<div></div>);
}

SwalDeleteItem.propTypes = {
  DeleteId: PropTypes.number,
  PathApi: PropTypes.string.isRequired,
  reloadData: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isConfirmedTitle: PropTypes.string.isRequired,
  isConfirmedText: PropTypes.string.isRequired,
}
