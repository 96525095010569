import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    Row,
    Col,
    Form,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Label,
} from "reactstrap";
import ModalBase from '../../../components/modal/modal';
import api from "../../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    showToastArray,
} from '../../../helpers/generals';
import { encodeImageFileAsURL } from "../../../helpers/filesBase64";
import { CkeditorOptionsEditorConfiguration } from '../../../components/ckeditor-options';

export default function ModalMarketingClasses({
    modalId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [FormData, setFormData] = useState({});
    const [Loading, setLoading] = useState(false);
    const [Desc, setDesc] = useState();

    const [FileB64, setFileB64] = useState();
    const [Img, setImg] = useState();

    const LoadDataShow = async => {
        if (modalId > 0) {
            try {
                api.get('/api/classes/show/' + modalId).then(response => {
                    return response;
                }).then(data => {
                    if (data.data.success) {

                        const formData = { ...FormData };

                        if (data.data.data) {

                            const item = data.data.data;

                            formData.date = item.date;
                            formData.name = item.name;
                            formData.yotube_url = item.yotube_url;
                            formData.subtitle = item.subtitle;
                            formData.is_active = item.is_active;
                            
                            setDesc(item.description);                            
                            setImg(item.thumb);
                        }

                        setFormData(formData);
                        setLoading(false);

                    }
                }).catch(error => {
                    // console.log(error);
                });
            } catch (error) {
                // console.log(error);
            }
        }
    };

    useEffect(() => {
        LoadDataShow();
    }, []);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            date: FormData.date || '',
            name: FormData.name || '',
            yotube_url: FormData.yotube_url || '',
            subtitle: FormData.subtitle || '',
            description: Desc || '',

            fileName: FormData.fileName || '',
            file_b64: FileB64 || '',

            is_active: FormData.is_active || '',
        },
        validationSchema: Yup.object({
            date: Yup.string().required("Digite esse campo."),
            name: Yup.string().required("Digite esse campo."),
            yotube_url: Yup.string().required("Digite esse campo."),
            description: Yup.string().required("Digite esse campo."),
            fileName: Yup.string().when({
              is: (is_active) => !modalId,
              then: Yup.string().required("Selecione uma opção."),
              otherwise: Yup.string(),
            }),
            is_active: Yup.string().required("Selecione uma opção."),
        }),
        onSubmit: (values) => {
            setLoading(true);
            if (modalId) {
                api.put('/api/classes/update/' + modalId, values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success == true) {
                        showToastArray("", "Sucesso", "Cadastro atualizado com sucesso!");
                        setLoading(false);
                        reloadData();
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });
            } else {
                api.post('/api/classes/save', values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success == true) {
                        showToastArray("", "Sucesso", "Cadastro atualizado com sucesso!");
                        setLoading(false);
                        reloadData();
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });
            }
        },
    });

    async function fileBase64Code(event) {
        event.persist();
      
        if (!event.target || !event.target.files) {
          return;
        }
      
        const fileList = event.target.files;
        const latestUploadedFile = fileList.item(fileList.length - 1);
      
        try {
            const fileContents = await encodeImageFileAsURL(latestUploadedFile);
            let b64 = '';
            let base64result = fileContents.substr(fileContents.indexOf(',') + 1);
            let base64dataType = fileContents.split(';base64,')[0];
            base64dataType = base64dataType.split('/')[1];
            b64 = base64dataType + '##@@nedo##$$' + base64result; 
            if(b64) setFileB64(b64);
        } catch (e) {
            // console.log(e);
        }
    };

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={modalId > 0 ? 'Editar | ' + title : 'Adicionar | ' + title}
        >
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Row>

                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="is_active"
                                type="select"
                                placeholder="Situação"
                                onChange={(event) => { setFormData({ ...FormData, 'is_active': event.target.value }); }}
                                value={FormData.is_active ? FormData.is_active : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.is_active && validation.errors.is_active ? true : false
                                }
                            >
                                {validation.touched.is_active && validation.errors.is_active ? (
                                    <FormFeedback type="invalid">{validation.errors.is_active}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                <option value="1"> Ativo </option>
                                <option value="2"> Inativo  </option>
                            </Input>
                            <Label>
                                Situação
                            </Label>
                        </FormGroup>
                    </Col>

                    <Col md={12} className="text-center mt-2">
                        <div className="titulo-hr">
                            <span> Dados do registro </span>
                        </div>
                    </Col>
                    <Col sm="2">
                        <FormGroup floating>
                            <Input
                                autoComplete={false}
                                name="date"
                                type="date"
                                onChange={(event) => { setFormData({ ...FormData, 'date': event.target.value }); }}
                                value={FormData.date ? FormData.date : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.date && validation.errors.date ? true : false
                                }
                            />
                            {validation.touched.date && validation.errors.date ? (
                                <FormFeedback type="invalid">{validation.errors.date}</FormFeedback>
                            ) : null}
                            <Label>
                                Data
                            </Label>
                        </FormGroup>
                    </Col>                    
                    <Col sm="5">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="name"
                                type="text"
                                maxLength={255}
                                onChange={(event) => { setFormData({ ...FormData, 'name': event.target.value }); }}
                                value={FormData.name ? FormData.name : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.name && validation.errors.name ? true : false
                                }
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                            ) : null}
                            <Label>
                                Título
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="5">
                        <FormGroup floating>
                            <Input
                                autoComplete={false}
                                name="subtitle"
                                type="text"
                                maxLength={255}
                                onChange={(event) => { setFormData({ ...FormData, 'subtitle': event.target.value }); }}
                                value={FormData.subtitle ? FormData.subtitle : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.subtitle && validation.errors.subtitle ? true : false
                                }
                            />
                            {validation.touched.subtitle && validation.errors.subtitle ? (
                                <FormFeedback type="invalid">{validation.errors.subtitle}</FormFeedback>
                            ) : null}
                            <Label>
                                Subtítulo
                            </Label>
                        </FormGroup>
                    </Col>                    
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                autoComplete={false}
                                name="yotube_url"
                                type="text"
                                maxLength={255}
                                onChange={(event) => { setFormData({ ...FormData, 'yotube_url': event.target.value }); }}
                                value={FormData.yotube_url ? FormData.yotube_url : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.yotube_url && validation.errors.yotube_url ? true : false
                                }
                            />
                            {validation.touched.yotube_url && validation.errors.yotube_url ? (
                                <FormFeedback type="invalid">{validation.errors.yotube_url}</FormFeedback>
                            ) : null}
                            <Label>
                                Código youtube
                            </Label>
                        </FormGroup>
                    </Col>   
                    <Col sm="12">
                        <FormGroup>
                            <Label>
                                Anexe a thumb (Tamanho de 400x350 pixels)
                            </Label>
                            <Input
                                name="fileName"
                                type="file"
                                accept="image/png, image/jpeg"
                                onChange={(event) => {
                                    setFormData({ ...FormData, 'fileName': event.target.value });
                                    fileBase64Code(event);
                                }}
                                value={FormData.fileName ? FormData.fileName : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.fileName && validation.errors.fileName ? true : false
                                }
                            />
                            {validation.touched.fileName && validation.errors.fileName ? (
                                <FormFeedback type="invalid">{validation.errors.fileName}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                    {FormData.yotube_url ? (
                        <Col sm="6" className="mb-3">
                            <iframe width="100%" height="480px" src={"https://www.youtube.com/embed/" + FormData.yotube_url} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </Col>
                    ) : null}                            
                    {Img ? (
                        <Col sm="6" className="mb-3">
                            <a href={'https://api.empresariossa.com/storage/images/' + Img} target="_blank" rel="noreferrer">
                                <img src={'https://api.empresariossa.com/storage/images/' + Img} width="100%" style={{ border: '3px solid #ccc', borderRadius: '10px' }} />
                            </a>
                        </Col>
                    ) : null}                      
                    <Col sm="12">
                        <FormGroup>
                            <Label>
                                Descrição
                            </Label>
                            <CKEditor
                                editor={ClassicEditor}
                                config={CkeditorOptionsEditorConfiguration}
                                data={Desc ? Desc : null}
                                onReady={editor => {
                                    // Allows you to store the editor instance and use it later.
                                    // const data = editor.getData();
                                    // // console.log({ editor, data });
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    // // console.log({ event, editor, data });
                                    setDesc(data);
                                }}
                                onBlur={(event, editor) => {
                                    // // console.log('22', editor);
                                }}
                                onFocus={(event, editor) => {
                                    // // console.log('333', editor);
                                }}
                            />
                            {validation.touched.description && validation.errors.description ? (
                                <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mt-3 mb-2">
                    <Col xs="6">
                        <Button type="submit" color="success" disabled={Loading ? true : false}>
                            {modalId > 0 ? 'Atualizar' : 'Adicionar'}
                        </Button>
                    </Col>
                    <Col xs="6" className="text-right pb-2">
                        <button type="button" className="btn btn-secondary" onClick={toggle} disabled={Loading ? true : false}>Fechar</button>
                    </Col>
                </Row>
            </Form>
        </ModalBase>
    );
}

ModalMarketingClasses.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
