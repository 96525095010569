import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Form,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Label,
    Spinner,
} from "reactstrap";
import ModalBase from '../../../../components/modal/modal';
import api from "../../../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    showToastArray,
} from '../../../../helpers/generals';
import { encodeImageFileAsURL } from "../../../../helpers/filesBase64";

export default function ModalInvitationsReceipt({
    modalId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [FormData, setFormData] = useState({});
    const [Loading, setLoading] = useState(false);

    const [FileName, setFileName] = useState();
    const [FileB64, setFileB64] = useState();

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            FileName: FileName || '',
            file: FileB64 || '',
        },
        validationSchema: Yup.object({
            FileName: Yup.string().required("Selecione um comprovante."),
        }),
        onSubmit: (values) => {
            setLoading(true);
            if (modalId > 0) {
                api.put('/api/invitations/update/file/' + modalId, values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success == true) {
                        showToastArray("", "Sucesso", "Sua solicitação foi atualizada com sucesso.");
                        setLoading(false);
                        reloadData();
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });
            }
        },
    });

    useEffect(() => {

    }, []);

    async function fileBase64Code(event) {
        event.persist();
      
        if (!event.target || !event.target.files) {
          return;
        }
      
        const fileList = event.target.files;
        const latestUploadedFile = fileList.item(fileList.length - 1);
      
        try {
            const fileContents = await encodeImageFileAsURL(latestUploadedFile);
            let b64 = '';
            let base64result = fileContents.substr(fileContents.indexOf(',') + 1);
            let base64dataType = fileContents.split(';base64,')[0];
            base64dataType = base64dataType.split('/')[1];
            b64 = base64dataType + '##@@nedo##$$' + base64result; 
            if(b64) setFileB64(b64);
        } catch (e) {
            // console.log(e);
        }
    };    

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={title}
        >
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Row>
                    <Col sm="12">
                        <FormGroup>
                            <Label>
                                Anexar comprovante
                            </Label>
                            <Input
                                className=""
                                autoComplete={false}
                                name="file"
                                type="file"
                                onChange={(event) => {
                                    setFileName(event.target.value);
                                    fileBase64Code(event);

                                }}
                                value={FileName ? FileName : ''}
                                accept="image/jpeg,image/png,application/pdf"
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.FileName && validation.errors.FileName ? true : false
                                }
                            />
                            {validation.touched.FileName && validation.errors.FileName ? (
                                <FormFeedback type="invalid">{validation.errors.FileName}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mt-3 mb-2">
                    <Col sm="6">
                        {Loading ? (
                            <Spinner />
                        ) : (
                            <Button type="submit" color="success" disabled={Loading ? true : false}>
                                Anexar
                            </Button>
                        )}
                    </Col>
                    <Col xs="6" className="text-right pb-2">
                        <button type="button" className="btn btn-secondary" onClick={toggle} disabled={Loading ? true : false}>Fechar</button>
                    </Col>
                </Row>
            </Form>
        </ModalBase>
    );
}

ModalInvitationsReceipt.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
