import PropTypes from 'prop-types';
import React, { useState, useEffect, useCounter } from "react";
import {
    Row,
    Col,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from 'reactstrap';
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";

export default function SlideMovies({
    show,
    rows,
    title,
}) {
    
    const [Loading, setLoading] = useState(true);

    const itemLoading = [
        {
            "id": 1,
            "items": [
                {
                    "id": 1,
                    "thumb": "",
                    "name": ""
                },
                {
                    "id": 2,
                    "thumb": "",
                    "name": ""
                },
                {
                    "id": 3,
                    "thumb": "",
                    "name": ""
                },
            ]
        },
        {
            "id": 2,
            "items": [
                {
                    "id": 4,
                    "thumb": "",
                    "name": ""
                },
                {
                    "id": 5,
                    "thumb": "",
                    "name": ""
                },
                {
                    "id": 6,
                    "thumb": "",
                    "name": ""
                },
            ]
        },        
    ]

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 3000);
      }, []);  

    const [activeIndex, setActiveIndex] = useState(0);

    // State for Animation
    const [animating, setAnimating] = useState(false);

    // Sample items for Carousel
    const items = rows;

    // Items array length
    const itemLength = items.length - 1

    // Previous button for Carousel
    const previousButton = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ?
            itemLength : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    // Next button for Carousel
    const nextButton = () => {
        if (animating) return;
        const nextIndex = activeIndex === itemLength ?
            0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    return (
        <React.Fragment>
            <Row className="px-3 pb-0">
                <Col lg={12}>
                    <div className="boxes slide-movies">
                        <Row>
                            <Col xs={9}>
                                <h5 className="m-0 p-0">
                                    {title}
                                </h5>
                            </Col>
                            <Col xs={3} className="text-right">
                                <Link to="">
                                    Ver todos
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <Carousel className="px-2 pb-0 mb-2" previous={previousButton} next={nextButton} activeIndex={activeIndex}>

                {!Loading ? 
                    rows.map((row) => (
                        <CarouselItem
                            key={row.id}
                            onExited={() => setAnimating(false)}
                            onExiting={() => setAnimating(true)}
                        >
                            <div class="containerSlide">
                                {row.items ? (
                                    row.items.map((rowSub) => (
                                        <div key={rowSub.id} class="itemSlide">
                                            <Link to={"/classes/view/" + rowSub.id}>
                                                <img width="100%" src={'https://api.empresariossa.com/storage/images/' + rowSub.thumb} alt={rowSub.name} />
                                            </Link>
                                        </div>
                                    ))
                                ) : null}
                            </div>
                        </CarouselItem>
                    ))
                : null }

                {Loading ? 
                    itemLoading.map((rowItem) => (
                        <CarouselItem
                            key={rowItem.id}
                            onExited={() => setAnimating(false)}
                            onExiting={() => setAnimating(true)}
                        >
                            <div class="containerSlide">
                                {rowItem.items ? (
                                    rowItem.items.map((rowSub) => (
                                        <div key={rowSub.id} class="itemSlide">
                                            <ContentLoader
                                                key={rowSub.id}
                                                speed={2}
                                                width="100%"
                                                viewBox="0 0 400 160"
                                                backgroundColor="#ccc"
                                                foregroundColor="#ecebeb"
                                            >
                                                <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                                            </ContentLoader>
                                        </div>
                                    ))
                                ) : null}
                            </div>
                        </CarouselItem>
                    )) 
                : ''}
                <CarouselControl directionText="Prev" direction="prev" onClickHandler={previousButton} />
                <CarouselControl directionText="Next" direction="next" onClickHandler={nextButton} />
            </Carousel>

        </React.Fragment>
    );
}

SlideMovies.propTypes = {
    show: PropTypes.bool.isRequired,
    rows: PropTypes.array.isRequired,
}
