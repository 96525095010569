
import toastr from "toastr";
import "./../../node_modules/toastr/build/toastr.min.css";

export function cleanValuesMask(value) {
  if (value) {
    value = value.replace("/", "").replace("-", "").replace(".", "");
    value = value.replace("/", "").replace("-", "").replace(".", "");
  }
  return value;
}

export function showToastArray(toastType, title, message) {
  // console.log('message', message);
  toastr.options = {
    closeButton: true,
    debug: false,
    extendedTimeOut: "5000",
    hideDuration: "5000",
    hideEasing: "linear",
    hideMethod: "fadeOut",
    newestOnTop: true,
    positionClass: "toast-bottom-center",
    preventDuplicates: false,
    progressBar: true,
    showDuration: "5000",
    showEasing: "swing",
    showMethod: "fadeIn",
    timeOut: "5000",
  };

  if (toastType === "info") {
    toastr.info(message, title);
  } else if (toastType === "warning") {
    toastr.warning(message, title);
  } else if (toastType === "error") {
    toastr.error(message, title);
  } else {
    toastr.success(message, title);
  }
}

export function showToastErrorDefault(message) {
  toastr.options = {
      closeButton: true,
      debug: false,
      extendedTimeOut: "3000",
      hideDuration: "3000",
      hideEasing: "linear",
      hideMethod: "fadeOut",
      newestOnTop: true,
      positionClass: "toast-bottom-center",
      preventDuplicates: false,
      progressBar: true,
      showDuration: "3000",
      showEasing: "swing",
      showMethod: "fadeIn",
      timeOut: "3000",
  };
  let messageDefault = 'Erro ao enviar o registro!';
  if (message) {
    messageDefault = message;
  }
  toastr.error(messageDefault, 'Erro!');
}