
export const encodeImageFileAsURL = async (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }
  
  export const fileBase64CodeNameFile = async (event) => {
    event.persist();
  
    if (!event.target || !event.target.files) {
      return;
    }
  
    const fileList = event.target.files;
    const latestUploadedFile = fileList.item(fileList.length - 1);
  
    try {
      const fileContents = await encodeImageFileAsURL(latestUploadedFile);
      let b64 = '';
      let base64result = fileContents.substr(fileContents.indexOf(',') + 1);
      let base64dataType = fileContents.split(';base64,')[0];
      base64dataType = base64dataType.split('/')[1];
      return base64result;
    } catch (e) {
      // console.log(e);
    }
  };
  
  export const fileBase64CodeComplet = async (event) => {
    event.persist();
  
    if (!event.target || !event.target.files) {
      return;
    }
  
    const fileList = event.target.files;
    const latestUploadedFile = fileList.item(fileList.length - 1);
  
    try {
      const fileContents = await encodeImageFileAsURL(latestUploadedFile);
      let b64 = '';
      let base64result = fileContents.substr(fileContents.indexOf(',') + 1);
      let base64dataType = fileContents.split(';base64,')[0];
      base64dataType = base64dataType.split('/')[1];
      return b64 = base64dataType + '##@@nedo##$$' + base64result;
    } catch (e) {
      // console.log(e);
    }
  };