import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Button,
    Table,
    Spinner,
    FormGroup,
    Input,
    Label,
} from "reactstrap";
import ModalBase from '../../components/modal/modal';
import api from "../../services/api";

export default function ModalEventsInvitations({
    modalId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [Loading, setLoading] = useState(true);
    const [RowData, setRowData] = useState([]);

    const [FormData, setFormData] = useState({});

    const [Pages, setPages] = useState([]);
    const [PaginationTotalItems, setPaginationTotalItems] = useState(1);
    const [PaginationPageActual, setPaginationPageActual] = useState(1);

    function LoadData(Page) {

        setLoading(true);
        setRowData([]);

        try {
            const Data = {
                "events": modalId,
                "search": FormData.search || '',
            }
            setPaginationPageActual(Page);
            api.post('/api/invitations/filter?page=' + Page, Data).then(response => {
                return response;
            }).then(data => {
                setRowData(data.data);
                setPaginationTotalItems(data.data.total);
                listItems(data.data.total, data.data.per_page);
                setLoading(false);
            }).catch(error => {
                // console.log(error);
            });
        } catch (error) {
            // console.log(error);
        }
    }

    useEffect(() => {
        LoadData(1);
    }, [modalId]);

    function listItems(total, PorPage) {
        let totalPages = 1;
        let asdasdsa = [];
        if (PorPage < total) {
            totalPages = total / PorPage;
        }
        for (let a = 1; a < totalPages + 1; a++) {
            asdasdsa.push([a]);
        }
        setPages(asdasdsa);
    }

    function PaginationOpen(Page) {
        LoadData(Page);
    }

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={'Convidados | ' + title}
        >
            <Row >
                <Col md={12} className="content-box">
                    <Row>
                        <Col lg={12}>
                            <Row>
                                <Col lg={11}>
                                    <FormGroup floating>
                                        <Input
                                            id="search"
                                            className="search"
                                            name="search"
                                            type="search"
                                            placeholder="Especilidade"
                                            onChange={(event) => { setFormData({ ...FormData, 'search': event.target.value }); }}
                                            value={FormData.search ? FormData.search : ''}
                                        />
                                        <Label>
                                            Buscar
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col lg={1} className="text-center">
                                    <Button
                                        className="btnFilter"
                                        onClick={() => {
                                            LoadData();
                                        }}
                                    >
                                        Filtrar
                                    </Button>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Col>
                <Col md={12} className="content-box">
                    <Row className="mb-3">
                        <Col md={12}>
                            <div className="boxes">
                                <Table responsive size="sm" className="table">
                                    <thead>
                                        {/* <th width="1%"></th> */}
                                        <th> Ramo </th>
                                        <th> Membro </th>
                                        <th> Nome </th>
                                        <th> CPF </th>
                                        <th> Email </th>
                                        <th> Celular </th>
                                        <th> Empresa </th>
                                        <th> Comprovante </th>
                                        <th> Cadastro </th>
                                    </thead>
                                    <tbody>

                                        {Loading ? (
                                            <tr>
                                                <td colSpan={10} style={{ border: '0px' }}>
                                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                                        <Spinner color="info" className="mt-5 mb-2"></Spinner>
                                                        <br />
                                                        Carregando dados...
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : null}

                                        {!Loading && RowData.results === 0 ? (
                                            <tr>
                                                <td colSpan={30} style={{ border: '0px', paddingTop: '30px', textAlign: 'center' }}>
                                                    Sem dados com esses filtros no momento.
                                                </td>
                                            </tr>
                                        ) : null}

                                        {RowData && !Loading ? (
                                            RowData.data.map((row, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {row.branches ? row.branches.name : '-'}
                                                    </td>
                                                    <td>
                                                        {row.users_invites ? row.users_invites.name : '-'}
                                                    </td>
                                                    <td>
                                                        {row.name ? row.name : '-'}
                                                    </td>   
                                                    <td>
                                                        {row.cpf ? row.cpf : '-'}
                                                    </td>  
                                                    <td>
                                                        {row.email ? row.email : '-'}
                                                    </td> 
                                                    <td>
                                                        {row.cell_phone ? row.cell_phone : '-'}
                                                    </td>   
                                                    <td>
                                                        {row.companie_name ? row.companie_name : '-'}
                                                    </td> 
                                                    <td>
                                                        {row.file ? (<a href={'https://api.empresariossa.com/storage/files/' + row.file} target='_blank'>Download</a>) : '-'}
                                                    </td>                                                     
                                                    <td>
                                                        {row.created_at ? row.created_at : '-'}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : null}

                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                        <Col md={9}>
                            <div className="TablePagination">
                                Mostrando página <strong>{PaginationPageActual}</strong> de <strong>1</strong>. | Total de registros é de <strong>{PaginationTotalItems}</strong>.
                            </div>
                        </Col>
                        <Col md={3}>
                            <FormGroup row className="text-right">
                                <Label
                                    for="exampleEmail"
                                    sm={4}
                                >
                                    Página
                                </Label>
                                <Col sm={8}>
                                    <Input
                                        className=""
                                        autoComplete={false}
                                        name="companie_taxation"
                                        type="select"
                                        placeholder="Tributação"
                                        onChange={(event) => { PaginationOpen(event.target.value); }}
                                    >
                                        {Pages.map((row) => (
                                            <option key={row} value={row} > {row} </option>
                                        ))}
                                    </Input>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </ModalBase>
    );
}

ModalEventsInvitations.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
