import React, { useState, useEffect } from "react";
import {
  useNavigate,
} from 'react-router-dom';
import * as Yup from "yup";
import { useFormik } from "formik";
import api from "../../../services/api";
import {
  Row,
  Col,
  Container,
  FormFeedback,
  Spinner,
  Label,
  Input,
  Form,
} from "reactstrap";
import LayoutHeader from "../../../components/header";
import LayoutBannerHeader from "../../../components/bannerHeader";
import { showToastArray, showToastErrorDefault } from "../../../helpers/generals";

const EditPassword = props => {

  const navigate = useNavigate();

  document.title = "Mudar minha senha | Empresários SA";

  const [Send, setSend] = useState(false);
  const [FormData, setFormData] = useState({});

  let UserID = localStorage.getItem('UserID');

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password_a: FormData.password_a || '',
      password_n: FormData.password_n || '',
      password_c: FormData.password_c || '',
    },
    validationSchema: Yup.object({
      password_a: Yup.string().required("Por favor digite essa senha"),
      password_n: Yup.string().required("Por favor digite essa senha"),
      password_c: Yup.string().required("Por favor digite essa senha"),
    }),
    onSubmit: (values) => {
      setSend(true);
      api.put('/api/users/update/pass/' + UserID, values).then(response => {
        return response.data;
      }).then(data => {
        if (data.success == true) {
          setSend(false);
          setFormData({});
          showToastArray("", "Sucesso!", data.message);
        }
      }).catch(error => {
        if (error) {
          let message = 'Erro ao enviar o registro!';
          setSend(false);
          if (error.response) {
            if (error.response.data) message = error.response.data.message;
          }
          showToastErrorDefault(message);
        }
      });
    }
  });

  const [Loading, setLoading] = useState(true);

  return (
    <React.Fragment>

      <LayoutHeader isAdmin={true} show={true} />

      <Container>
        <Row>
          <LayoutBannerHeader show={true} />
          <Col md={12}>
            <Row>

              <Col md={12} className="content-box p-0">
                <Row>
                  <Col md={12} className="text-center">
                    <div className="titulo-hr">
                      <span>Mudar senha</span>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={3}></Col>
              <Col md={6}>
                <div className="LayoutBannerHeader">
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="mb-3">
                      <Label className="form-label">Senha antiga</Label>
                      <Input
                        name="password_a"
                        className="form-control"
                        type="password"
                        maxLength={25}
                        minLength={6}
                        onChange={(event) => { setFormData({ ...FormData, 'password_a': event.target.value }); }}
                        value={FormData.password_a ? FormData.password_a : ''}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.password_a && validation.errors.password_a ? true : false
                        }
                      />
                      {validation.touched.password_a && validation.errors.password_a ? (
                        <FormFeedback type="invalid">{validation.errors.password_a}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Nova senha</Label>
                      <Input
                        name="password_n"
                        className="form-control"
                        type="password"
                        maxLength={25}
                        minLength={6}
                        onChange={(event) => { setFormData({ ...FormData, 'password_n': event.target.value }); }}
                        value={FormData.password_n ? FormData.password_n : ''}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.password_n && validation.errors.password_n ? true : false
                        }
                      />
                      {validation.touched.password_n && validation.errors.password_n ? (
                        <FormFeedback type="invalid">{validation.errors.password_n}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Confirmar nova senha</Label>
                      <Input
                        name="password_c"
                        className="form-control"
                        type="password"
                        maxLength={25}
                        minLength={6}
                        onChange={(event) => { setFormData({ ...FormData, 'password_c': event.target.value }); }}
                        value={FormData.password_c ? FormData.password_c : ''}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.password_c && validation.errors.password_c ? true : false
                        }
                      />
                      {validation.touched.password_c && validation.errors.password_c ? (
                        <FormFeedback type="invalid">{validation.errors.password_c}</FormFeedback>
                      ) : null}
                    </div>
                    <Row className="mt-4 mb-1">
                      <Col className="text-start">
                        {Send ? (
                          <Spinner color="info"></Spinner>
                        ) : (
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                          >
                            Atualizar senha
                          </button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default EditPassword;