import React, { useState, useEffect } from "react";
import {
  Link,
  useNavigate,
} from 'react-router-dom';
import api from "../../../services/api";
import {
  Row,
  Col,
  Container,
  Button,
  Table,
  Spinner,
  Badge,
  FormGroup,
  Input,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ContentLoader from "react-content-loader";
import LayoutHeader from "../../../components/header";
import {
  formatDataToDate
} from '../../../helpers/mascarasParaCampos';
import ModalInvitations from "./modal/invitations";
import ModalInvitationsReceipt from "./modal/receipt";
import ModalInvitationsChecked from "./modal/checked";

const MembersInvitationsList = props => {

  const navigate = useNavigate();

  let NameModule = 'Convites';

  document.title = NameModule + " | Empresários SA";

  const [Loading, setLoading] = useState(true);

  const [RowData, setRowData] = useState([]);
  const [EventsData, setEventsData] = useState([]);
  const [BranchesData, setBranchesData] = useState([]);

  const [Type, setType] = useState();

  const [Pages, setPages] = useState([]);
  const [PaginationTotalItems, setPaginationTotalItems] = useState(1);
  const [PaginationPageActual, setPaginationPageActual] = useState(1);
  const [FormData, setFormData] = useState({});

  const LoadEvents = async => {
    setEventsData([]);
    api.get('/api/events/active').then(response => {
      return response.data
    }).then(data => {
      if (data.success) {
        setEventsData(data.data);
      }
    }).catch(error => {
      // console.log(error);
    })
  };

  function LoadBranches() {
    setBranchesData([]);
    api.get('/api/branches/active').then(response => {
      return response.data
    }).then(data => {
      if (data.success) {
        setBranchesData(data.data);
      }
    }).catch(error => {
      // console.log(error);
    })
  };

  function LoadData(Page) {

    setLoading(true);
    setRowData([]);

    let user = 0;

    if (localStorage.getItem('UserID')) user = localStorage.getItem('UserID');
    if (localStorage.getItem('UserType')) setType(localStorage.getItem('UserType'));

    // nedo
    try {
      const Data = {
        "users": user,
        "status": FormData.status || '',
        "events": FormData.events || '',
        "branches": FormData.branches || '',
        "search": FormData.search || '',
      }
      setPaginationPageActual(Page);
      api.post('/api/invitations/filter?page=' + Page, Data).then(response => {
        return response;
      }).then(data => {
        setRowData(data.data);
        setPaginationTotalItems(data.data.total);
        listItems(data.data.total, data.data.per_page);
        setLoading(false);
      }).catch(error => {
        // console.log(error);
      });
    } catch (error) {
      // console.log(error);
    }
  }

  useEffect(() => {
    LoadEvents();
    LoadBranches();
    LoadData(1);
  }, []);

  function listItems(total, PorPage) {
    let totalPages = 1;
    let asdasdsa = [];
    if (PorPage < total) {
      totalPages = total / PorPage;
    }
    for (let a = 1; a < totalPages + 1; a++) {
      asdasdsa.push([a]);
    }
    setPages(asdasdsa);
  }

  function PaginationOpen(Page) {
    LoadData(Page);
  }

  const [SwalOpen, setSwalOpen] = useState('');
  const [ModalOpen, setModalOpen] = useState(false);
  const [Modal, setModal] = useState('');
  const [ModalId, setModalId] = useState(0);

  function reloadData() {
    LoadData(1);
    setModalOpen(false);
    setModal('');
    setSwalOpen('');
    setModalId(0);
  }

  return (
    <React.Fragment>

      {Modal == 'add' ? (<ModalInvitations
        toggle={() => setModalOpen(!ModalOpen)}
        isOpen={ModalOpen}
        size=""
        title="Adicionar | Convite"
        reloadData={reloadData}
      />) : ''}

      {Modal == 'ver' ? (<ModalInvitations
        toggle={() => setModalOpen(!ModalOpen)}
        isOpen={ModalOpen}
        modalId={ModalId}
        size=""
        title="Editar | Convite"
        reloadData={reloadData}
      />) : ''}

      {Modal == 'comprovante' ? (<ModalInvitationsReceipt
        toggle={() => setModalOpen(!ModalOpen)}
        isOpen={ModalOpen}
        modalId={ModalId}
        size=""
        title="Comprovante | Convite"
        reloadData={reloadData}
      />) : ''}

      {Modal == 'checked' ? (<ModalInvitationsChecked
        toggle={() => setModalOpen(!ModalOpen)}
        isOpen={ModalOpen}
        modalId={ModalId}
        size=""
        title="Verificação de comprovante | Convite"
        reloadData={reloadData}
      />) : ''}

      <LayoutHeader isAdmin={false} show={true} />

      <div className="box-container">
        <Row className="pt-3910201290">

          <Col md={12} className="content-box">
            <Row>
              <Col md={12} className="text-center">
                <div className="titulo-hr">
                  <span>{NameModule}</span>
                </div>
              </Col>

              <Col lg={12}>
                <Row>
                  <Col lg={2}>
                    <FormGroup floating>
                      <Input
                        id="status"
                        className="status"
                        name="status"
                        type="select"
                        onChange={(event) => {
                          setFormData({ ...FormData, 'status': event.target.value });
                        }}
                        value={FormData.status ? FormData.status : ''}
                      >
                        <option value=""> Todos </option>
                        <option value="1111"> Criado na tela login </option>
                        <option value="1"> Pendente comprovante </option>
                        <option value="2"> Aguardando verificação </option>
                        <option value="3"> Pago </option>
                        <option value="4"> Rejeitado </option>
                      </Input>
                      <Label>
                        Tipo
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg={2}>
                    <FormGroup floating>
                      <Input
                        id="events"
                        className="events"
                        name="events"
                        type="select"
                        onChange={(event) => {
                          setFormData({ ...FormData, 'events': event.target.value });
                        }}
                        value={FormData.events ? FormData.events : ''}
                      >
                        <option value=""> Todos </option>
                        {EventsData.map((row, index) => (
                          <option key={index} value={row.id}>{row.name}</option>
                        ))}
                      </Input>
                      <Label>
                        Evento
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg={2}>
                    <FormGroup floating>
                      <Input
                        id="branches"
                        className="branches"
                        name="branches"
                        type="select"
                        onChange={(event) => {
                          setFormData({ ...FormData, 'branches': event.target.value });
                        }}
                        value={FormData.branches ? FormData.branches : ''}
                      >
                        <option value=""> Todos </option>
                        {BranchesData.map((row, index) => (
                          <option key={index} value={row.id}>{row.name}</option>
                        ))}
                      </Input>
                      <Label>
                        Ramo
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg={4}>
                    <FormGroup floating>
                      <Input
                        id="search"
                        className="search"
                        name="search"
                        type="search"
                        placeholder="Especilidade"
                        onChange={(event) => { setFormData({ ...FormData, 'search': event.target.value }); }}
                        value={FormData.search ? FormData.search : ''}
                      />
                      <Label>
                        Buscar
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg={1} className="text-center">
                    <Button
                      className="btnFilter"
                      onClick={() => {
                        LoadData();
                      }}
                    >
                      Filtrar
                    </Button>
                  </Col>
                  <Col lg={1} className="text-center">
                    <Button
                      className="btnFilter"
                      color="success"
                      onClick={() => {
                        setModalId('');                        
                        setModalOpen(true);
                        setModal('add');
                      }}
                    >
                      Adicionar
                    </Button>
                  </Col>
                </Row>
              </Col>

            </Row>
          </Col>
          <Col md={12} className="content-box">
            <Row className="mb-3">
              <Col md={12}>
                <div className="boxes">
                  <Table responsive size="sm" className="table">
                    <thead>
                      <th></th>
                      <th> Status </th>
                      {/* <th width="1%"></th> */}
                      <th> Evento </th>
                      <th> Ramo </th>
                      <th> Membro </th>
                      <th> Nome </th>
                      <th> CPF </th>
                      <th> Email </th>
                      <th> Celular </th>
                      <th> Empresa </th>
                      <th width="10%"> Cadastro </th>
                    </thead>
                    <tbody>

                      {Loading ? (
                        <tr>
                          <td colSpan={50} style={{ border: '0px' }}>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                              <Spinner color="info" className="mt-5 mb-2"></Spinner>
                              <br />
                              Carregando dados...
                            </div>
                          </td>
                        </tr>
                      ) : null}

                      {!Loading && RowData.results === 0 ? (
                        <tr>
                          <td colSpan={50} style={{ border: '0px', paddingTop: '30px', textAlign: 'center' }}>
                            Sem dados com esses filtros no momento.
                          </td>
                        </tr>
                      ) : null}

                      {RowData && !Loading ? (
                        RowData.data.map((row, index) => (
                          <tr key={index}>
                            <td style={{ width: '10px', textAlign: 'center' }}>
                              <UncontrolledDropdown
                                className="me-2"
                                direction="down"
                              >
                                <DropdownToggle
                                  caret
                                  color="dark"
                                  size="sm"
                                  style={{ padding: '1px 5px' }}
                                >
                                  Acões
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem header>
                                    Selecione uma ação
                                  </DropdownItem>
                                  <DropdownItem onClick={(event) => {
                                    setModalOpen(true);
                                    setModal('ver');
                                    setModalId(row.id);
                                  }}>
                                    Ver / Editar
                                  </DropdownItem>
                                  {row.status != 2 && row.status != 1111 && row.status != 3 ? (
                                    <>
                                      <DropdownItem divider />
                                      <DropdownItem onClick={(event) => {
                                        setModalOpen(true);
                                        setModal('comprovante');
                                        setModalId(row.id);
                                      }}>
                                        Anexar Comprovante
                                      </DropdownItem>
                                    </>
                                  ) : null}                                  
                                  { (row.status == 2 || row.status == 1111) && (Type == 'admin' || Type == 'super_admin') ? (
                                    <>
                                      <DropdownItem divider />
                                      <DropdownItem onClick={(event) => {
                                        setModalOpen(true);
                                        setModal('checked');
                                        setModalId(row.id);
                                      }}>
                                        Verificar comprovante
                                      </DropdownItem>
                                    </>
                                  ) : null}
                                </DropdownMenu>                                  
                              </UncontrolledDropdown>
                            </td>
                            <td>
                              {row.status == 1 ? 'Pendente comprovante' : null}
                              {row.status == 2 ? 'Aguardando verificação' : null}
                              {row.status == 3 ? 'Pago' : null}
                              {row.status == 4 ? 'Rejeitado' : null}
                              {row.status == 1111 ? 'Criado na tela login' : null}
                            </td>
                            <td>
                              {row.events ? row.events.name : '-'}
                            </td>
                            <td>
                              {row.branches ? row.branches.name : '-'}
                            </td>
                            <td>
                              {row.users_invites ? row.users_invites.name : '-'}
                            </td>
                            <td>
                              {row.name ? row.name : '-'}
                            </td>
                            <td>
                              {row.cpf ? row.cpf : '-'}
                            </td>
                            <td>
                              {row.email ? row.email : '-'}
                            </td>
                            <td>
                              {row.cell_phone ? row.cell_phone : '-'}
                            </td>
                            <td>
                              {row.companie_name ? row.companie_name : '-'}
                            </td>
                            <td>
                              {row.created_at ? row.created_at : '-'}
                            </td>
                          </tr>
                        ))
                      ) : null}

                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col md={9}>
                <div className="TablePagination">
                  Mostrando página <strong>{PaginationPageActual}</strong> de <strong>1</strong>. | Total de registros é de <strong>{PaginationTotalItems}</strong>.
                </div>
              </Col>
              <Col md={3}>
                <FormGroup row className="text-right">
                  <Label
                    for="exampleEmail"
                    sm={4}
                  >
                    Página
                  </Label>
                  <Col sm={8}>
                    <Input
                      className=""
                      autoComplete={false}
                      name="companie_taxation"
                      type="select"
                      placeholder="Tributação"
                      onChange={(event) => { PaginationOpen(event.target.value); }}
                    >
                      {Pages.map((row) => (
                        <option key={row} value={row} > {row} </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default MembersInvitationsList;