import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import {
  Row,
  Col,
  Container,
} from "reactstrap";
import ContentLoader from "react-content-loader";
import LayoutHeader from "../../../components/header";
import LayoutSideBar from "../../../components/sidebar";
import LayoutBannerHeader from "../../../components/bannerHeader";
import { Link } from "react-router-dom";

const AllEvents = props => {

  const [Loading, setLoading] = useState(true);

  const [rows, setRows] = useState([]);

  const Load = async => {
    api.get('/api/events/active').then(response => {
      return response.data
    }).then(data => {
      setRows(data.data);
      setLoading(false);
    }).catch(error => {
      // console.log(error)
    })
  };

  useEffect(() => {
    Load();
  }, []);

  return (
    <React.Fragment>

      <LayoutHeader isAdmin={true} show={true} />

      <Container>
        <Row>

          <LayoutBannerHeader show={true} />

          <Col md={9}>
            <Row>
              <Col xs={12} className="content-box p-0  mb-0 pb-0">
                <Row>
                  <Col xs={12} className="text-center">
                    <div className="titulo-hr mb-0 pb-0">
                      <span>Agenda de eventos</span>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col xs={12} className="content-box" style={{ paddingBottom: '0px' }}>
                <div className="sidebar-agenda">
                  <Row className="sidebar-box">
                    {!Loading ?
                      rows.map((row, idx) => (
                        <Col md={6} key={idx}>
                          <div className="boxes">
                            <Row>
                              <Col md={12}>
                                <Row>
                                  <Col xs={3}>
                                    <div className="agenda-data-box">
                                      <div className="agenda-data-dia">{row.day}</div>
                                      <div className="agenda-data-mes">{row.month}</div>
                                    </div>
                                  </Col>
                                  <Col xs={9}>
                                    <Row>
                                      <Col xs={12} className="mb-1">
                                        <Link to={`/all/events/${row.id}`}>                                 
                                          <h3>{row.name}</h3>
                                        </Link>
                                      </Col>
                                      <Col xs={12} className="mb-3">
                                        <Link to={`/all/events/${row.id}`}>  
                                          <h4>{row.units ? row.units.name : null}</h4>
                                        </Link>
                                      </Col>
                                      <Col md={12}>
                                        <Link to={`/all/events/${row.id}`}>                                     
                                          <p className="m-0" style={{ fontSize: '11px' }}>
                                            Local: {row.local ? row.local : '-'}
                                            <br/>
                                            Horário: {row.time_i ? row.time_i : null} {row.time_f ? ' até ' + row.time_f : null}
                                          </p>
                                        </Link>
                                      </Col>                                     
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </Col>))
                      : (
                        <Col md={12}>
                          <div className="boxes">
                            <Row>
                              <Col md={12} className="mb-3">
                                <Row>
                                  <Col xs={1}>
                                    <ContentLoader
                                      speed={2}
                                      width="100%"
                                      height="40px"
                                      backgroundColor="#f3f3f3"
                                      foregroundColor="#ecebeb"
                                    >
                                      <circle cx="20" cy="20" r="20" width="100%" />
                                    </ContentLoader>
                                  </Col>
                                  <Col xs={11}>
                                    <Row>
                                      <Col md={12}>
                                        <ContentLoader
                                          speed={2}
                                          width="100%"
                                          height="20px"
                                          backgroundColor="#f3f3f3"
                                          foregroundColor="#ecebeb"
                                        >
                                          <rect x="0" y="0" width="100%" height="20px" />
                                        </ContentLoader>
                                      </Col>
                                      <Col md={12}>
                                        <ContentLoader
                                          speed={2}
                                          width="100%"
                                          height="20px"
                                          backgroundColor="#f3f3f3"
                                          foregroundColor="#ecebeb"
                                        >
                                          <rect x="0" y="5" width="30%" height="10px" />
                                        </ContentLoader>
                                      </Col>
                                      <Col md={12} className="mt-3 mb-0 text-bold">
                                        <ContentLoader
                                          speed={2}
                                          width="100%"
                                          height="20px"
                                          backgroundColor="#f3f3f3"
                                          foregroundColor="#ecebeb"
                                        >
                                          <rect x="0" y="0" width="80%" height="20px" />
                                        </ContentLoader>
                                      </Col>
                                      <Col md={12} className="mt-1 mb-0">
                                        <ContentLoader
                                          speed={2}
                                          width="100%"
                                          height="120px"
                                          backgroundColor="#f3f3f3"
                                          foregroundColor="#ecebeb"
                                        >
                                          <rect x="0" y="20" width="60%" height="15px" />
                                          <rect x="0" y="45" width="80%" height="15px" />
                                          <rect x="0" y="70" width="90%" height="15px" />
                                          <rect x="0" y="95" width="80%" height="15px" />
                                        </ContentLoader>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={12} className="img-destaque">
                                <ContentLoader
                                  speed={2}
                                  width="100%"
                                  height="400px"
                                  backgroundColor="#f3f3f3"
                                  foregroundColor="#ecebeb"
                                >
                                  <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                                </ContentLoader>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      )}
                  </Row>
                </div>
              </Col>

            </Row>
          </Col>

          <Col md={3}>
            <LayoutSideBar isAdmin={true} show={true} />
          </Col>

        </Row>
      </Container>
    </React.Fragment>
  );
};

export default AllEvents;