import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import {
    useParams,
} from 'react-router-dom';
import {
    Row,
    Col,
    Container,
} from "reactstrap";
import ContentLoader from "react-content-loader";
import LayoutHeader from "../../../components/header";
import LayoutSideBar from "../../../components/sidebar";
import LayoutBannerHeader from "../../../components/bannerHeader";

const AllEventsView = props => {
    const { id } = useParams();

    const [Loading, setLoading] = useState(true);

    const [rows, setRows] = useState({});

    const Load = async => {
        api.get(`/api/events/show/${id}`).then(response => {
            return response.data
        }).then(data => {
            setRows(data.data);
            setLoading(false);
        }).catch(error => {
            // console.log(error)
        })
    };

    useEffect(() => {
        Load();
    }, [id]);

    return (
        <React.Fragment>

            <LayoutHeader isAdmin={true} show={true} />

            <Container>
                <Row>

                    <LayoutBannerHeader show={true} />

                    <Col md={9}>
                        <Row>
                            <Col xs={12} className="content-box p-0">
                                <Row>
                                    <Col xs={12} className="text-center">
                                        <div className="titulo-hr">
                                            <span>Agenda de eventos</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={12} className="content-box">
                                <Row>
                                    {!Loading && rows ?
                                        <Col xs={12}>
                                            <div className="boxes">
                                                <Row>
                                                    <Col md={12}>
                                                        <Row>

                                                            <Col md={12}>
                                                                <h2>{rows.name ? rows.name : null}</h2>
                                                            </Col>
                                                            <Col md={12}>
                                                                <h4>{rows.units ? rows.units.name : null}</h4>
                                                            </Col>
                                                            <Col md={12} className="sidebar-box">
                                                                <hr />
                                                            </Col>  
                                                            <Col xs={12} >
                                                                <span>{rows.day} {rows.month} {rows.year} - Das {rows.time_i ? rows.time_i : null} {rows.time_f ? ' até ' + rows.time_f : null}</span>
                                                            </Col>
                                                            <Col md={12}>
                                                                <p>Local: {rows.local ? rows.local : '-'}</p>
                                                            </Col>                                                            
                                                            <Col md={12} className="sidebar-box">
                                                                <hr />
                                                            </Col>                                                                                                                      
                                                            <Col md={12}>
                                                                <div dangerouslySetInnerHTML={{ __html: rows.description }}></div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        : (
                                            <Col md={12}>
                                                <div className="boxes">
                                                    <Row>
                                                        <Col md={12} className="mb-3">
                                                            <Row>
                                                                <Col xs={1}>
                                                                    <ContentLoader
                                                                        speed={2}
                                                                        width="100%"
                                                                        height="40px"
                                                                        backgroundColor="#f3f3f3"
                                                                        foregroundColor="#ecebeb"
                                                                    >
                                                                        <circle cx="20" cy="20" r="20" width="100%" />
                                                                    </ContentLoader>
                                                                </Col>
                                                                <Col xs={11}>
                                                                    <Row>
                                                                        <Col md={12}>
                                                                            <ContentLoader
                                                                                speed={2}
                                                                                width="100%"
                                                                                height="20px"
                                                                                backgroundColor="#f3f3f3"
                                                                                foregroundColor="#ecebeb"
                                                                            >
                                                                                <rect x="0" y="0" width="100%" height="20px" />
                                                                            </ContentLoader>
                                                                        </Col>
                                                                        <Col md={12}>
                                                                            <ContentLoader
                                                                                speed={2}
                                                                                width="100%"
                                                                                height="20px"
                                                                                backgroundColor="#f3f3f3"
                                                                                foregroundColor="#ecebeb"
                                                                            >
                                                                                <rect x="0" y="5" width="30%" height="10px" />
                                                                            </ContentLoader>
                                                                        </Col>
                                                                        <Col md={12} className="mt-3 mb-0 text-bold">
                                                                            <ContentLoader
                                                                                speed={2}
                                                                                width="100%"
                                                                                height="20px"
                                                                                backgroundColor="#f3f3f3"
                                                                                foregroundColor="#ecebeb"
                                                                            >
                                                                                <rect x="0" y="0" width="80%" height="20px" />
                                                                            </ContentLoader>
                                                                        </Col>
                                                                        <Col md={12} className="mt-1 mb-0">
                                                                            <ContentLoader
                                                                                speed={2}
                                                                                width="100%"
                                                                                height="120px"
                                                                                backgroundColor="#f3f3f3"
                                                                                foregroundColor="#ecebeb"
                                                                            >
                                                                                <rect x="0" y="20" width="60%" height="15px" />
                                                                                <rect x="0" y="45" width="80%" height="15px" />
                                                                                <rect x="0" y="70" width="90%" height="15px" />
                                                                                <rect x="0" y="95" width="80%" height="15px" />
                                                                            </ContentLoader>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={12} className="img-destaque">
                                                            <ContentLoader
                                                                speed={2}
                                                                width="100%"
                                                                height="400px"
                                                                backgroundColor="#f3f3f3"
                                                                foregroundColor="#ecebeb"
                                                            >
                                                                <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                                                            </ContentLoader>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        )}
                                </Row>
                            </Col>

                        </Row>
                    </Col>

                    <Col md={3}>
                        <LayoutSideBar isAdmin={true} show={true} />
                    </Col>

                </Row>
            </Container>
        </React.Fragment>
    );
};

export default AllEventsView;