import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Form,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Label,
} from "reactstrap";
import ModalBase from '../../../../components/modal/modal';
import api from "../../../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    showToastArray,
} from '../../../../helpers/generals';
import { MaskCellPhone } from '../../../../helpers/mascarasParaCampos';

export default function ModalIndications({
    reloadData2,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [FormData, setFormData] = useState({});
    const [Loading, setLoading] = useState(false);
    
    const [members, setMembers] = useState([]);

    const LoadMembers = async => {
        setMembers([]);
        api.get('/api/users/active/members').then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setMembers(data.data);
                setLoading(false);
            }
        }).catch(error => {
            // console.log(error);
        })
    };

    useEffect(() => {
        LoadMembers();
    }, []);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            members_id: FormData.members_id || '',            
            date: FormData.date || '',
            type: FormData.type || '',
            note: FormData.note || '', 
            name: FormData.name || '',
            cell_phone: FormData.cell_phone || '',
            email: FormData.email || '',
            is_active: 1,              
        },
        validationSchema: Yup.object({
            members_id: Yup.string().required("Selecione uma opção."),
            date: Yup.string().required("Digite um dado."),
            type: Yup.string().required("Selecione uma opção."),
            note: Yup.string().required("Digite um dado."), 
        }),
        onSubmit: (values) => {
            try {
                setLoading(true);
                console.log(values);
                api.post('/api/indications/save', values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success === true) {
                        showToastArray("", "Sucesso", title + " enviado com sucesso!");
                        setLoading(false);
                        reloadData2();
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });
            } catch (error) {
                showToastArray(error, 'error');
                setLoading(false);
            }
        },
    });

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={'Adicionar | ' + title}
        >
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Row>                    
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                autoComplete={false}
                                name="members_id"
                                type="select"
                                onChange={(event) => { setFormData({ ...FormData, 'members_id': event.target.value }); }}
                                value={FormData.members_id ? FormData.members_id : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.members_id && validation.errors.members_id ? true : false
                                }
                            >
                                {validation.touched.members_id && validation.errors.members_id ? (
                                    <FormFeedback type="invalid">{validation.errors.members_id}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                {members.map((row, index) => (
                                    <option key={index} value={row.id}>{row.name}</option>
                                ))}
                            </Input>
                            <Label>
                                Membro
                            </Label>
                        </FormGroup>
                    </Col> 
                    <Col sm="12">
                        <FormGroup floating>
                            <Input 
                                autoComplete={false}
                                name="type"
                                type="select"
                                onChange={(event) => { setFormData({ ...FormData, 'type': event.target.value }); }}
                                value={FormData.type ? FormData.type : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.type && validation.errors.type ? true : false
                                }
                            >
                                {validation.touched.type && validation.errors.type ? (
                                    <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                <option value="1"> Recebido </option>
                                <option value="2"> Realizada </option>
                            </Input>
                            <Label>
                                Tipo
                            </Label>
                        </FormGroup>
                    </Col>                                      
                    <Col sm="12">
                        <FormGroup floating>
                            <Input   
                                autoComplete={false}
                                name="date"
                                type="date"
                                onChange={(event) => { setFormData({ ...FormData, 'date': event.target.value }); }}
                                value={FormData.date ? FormData.date : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.date && validation.errors.date ? true : false
                                }
                            />
                            <Label>
                                Data
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <FormGroup floating>
                            <Input                   
                                autoComplete={false}
                                name="name"
                                type="text"
                                max={255}
                                maxLength={255}
                                onChange={(event) => { setFormData({ ...FormData, 'name': event.target.value }); }}
                                value={FormData.name ? FormData.name : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.name && validation.errors.name ? true : false
                                }
                            />
                            <Label>
                                Nome
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                autoComplete={false}
                                name="cell_phone"
                                type="text"
                                max={255}
                                maxLength={255}
                                onChange={(event) => { setFormData({ ...FormData, 'cell_phone': MaskCellPhone(event.target.value) }); }}
                                value={FormData.cell_phone ? FormData.cell_phone : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.cell_phone && validation.errors.cell_phone ? true : false
                                }
                            />
                            <Label>
                                Celular
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                autoComplete={false}
                                name="email"
                                type="email"
                                max={255}
                                maxLength={255}
                                onChange={(event) => { setFormData({ ...FormData, 'email': event.target.value }); }}
                                value={FormData.email ? FormData.email : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.nemailame && validation.errors.email ? true : false
                                }
                            />
                            <Label>
                                E-mail
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                name="note"
                                type="textarea"
                                style={{ height: '120px' }}
                                value={FormData.note ? FormData.note : ''}
                                onChange={(event) => { setFormData({ ...FormData, 'note': event.target.value }); }}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.note && validation.errors.note ? true : false
                                }
                            />
                            <Label>
                                Necessidade
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mt-3 mb-2">
                    <Col xs="6">
                        <Button type="submit" color="success" disabled={Loading ? true : false}>
                            Adicionar
                        </Button>
                    </Col>
                    <Col xs="6" className="text-right pb-2">
                        <button type="button" className="btn btn-secondary" onClick={toggle} disabled={Loading ? true : false}>Fechar</button>
                    </Col>
                </Row>
            </Form>
        </ModalBase>
    );
}

ModalIndications.propTypes = {
    reloadData2: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
