import PropTypes from 'prop-types'
import React from "react";
import ModalBase from '../../../../../components/modal/modal';
import ModalAddAdminMembersForm from './form';

export default function ModalAddAdminMembers({
    modalId,
    reloadData,
    blockInputs,
    toggle,
    isOpen,
    size,
    title,
}) {
    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={modalId > 0 ? 'Editar | ' + title : 'Adicionar | ' + title}
        >
            <ModalAddAdminMembersForm
                modalId={modalId}
                reloadData={reloadData}
                blockInputs={false}
                toggle2={toggle}
            />
        </ModalBase>
    );
}

ModalAddAdminMembers.propTypes = {
    modalId: PropTypes.number.isRequired,
    reloadData: PropTypes.func.isRequired,
    blockInputs: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
