import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CkeditorOptionsEditorConfiguration } from '../../../../../components/ckeditor-options';
import {
    Row,
    Col,
    Form,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Label,
} from "reactstrap";
import ModalBase from '../../../../../components/modal/modal';
import api from "../../../../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    showToastArray,
} from '../../../../../helpers/generals';

export default function ModalScheduleMeetings({
    modalId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [FormData, setFormData] = useState({});
    const [FormDataMeet, setFormDataMeet] = useState({});
    const [Loading, setLoading] = useState(true);

    let UserID = localStorage.getItem('UserID');

    var day = new Date().getDay();
    if (day < 10) {
        day = '0' + day;
      }
    var month = new Date().getMonth() + 1;
    if (month < 10) {
      month = '0' + month;
    }
    var year = new Date().getFullYear();
    const date_now = year + '-' + month + '-' + day;

    const LoadDataShow = async => {
        if (modalId > 0) {
            try { 
                api.get('/api/users/show/' + modalId).then(response => {
                    return response;
                }).then(data => {
                    setFormData(data.data.data);
                    setLoading(false);
                }).catch(error => {
                    // console.log(error);
                });
            } catch (error) {
                // console.log(error);
            }
        }
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            users_id: UserID || '',
            users_meeting_id: modalId || '',
            date: FormDataMeet.date || '',
            time: FormDataMeet.time || '',
            duration: FormDataMeet.duration || '',
            format: FormDataMeet.format || '',
            subject: FormDataMeet.subject || '',
            description: FormDataMeet.description || '',
        },
        validationSchema: Yup.object({
            users_id: Yup.string().required("Digite esse campo."),
            users_meeting_id: Yup.string().required("Digite esse campo."),            
            date: Yup.string().required("Digite esse campo."),
            time: Yup.string().required("Digite esse campo."),
            duration: Yup.string().required("Selecione uma opção."),
            format: Yup.string().required("Selecione uma opção."),
            subject: Yup.string().required("Digite esse campo."),
            description: Yup.string().required("Digite esse campo."),
        }),
        onSubmit: (values) => {
            // console.log('values', values);
            setLoading(true);
            if (modalId > 0) {
                api.post('/api/meetings/save', values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success == true) {
                        showToastArray("", "Sucesso", "Sua solicitação de agenda foi enviada com sucesso.");
                        setLoading(false);
                        reloadData();
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });
            }
        },
    });

    useEffect(() => {
        LoadDataShow();
    }, []);

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={title}
        >
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Row>
                    <Col sm="4">
                        <Row>
                            <Col md={12} className="text-center mt-2">
                                <div className="titulo-hr">
                                    <span>Dados do participante</span>
                                </div>
                            </Col>
                            <Col sm="12">
                                <FormGroup floating>
                                    <Input
                                        name="name"
                                        type="text"
                                        value={FormData.name ? FormData.name : ''}
                                        disabled
                                    />
                                    <Label>
                                        Nome
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup floating>
                                    <Input
                                        name="text"
                                        type="text"
                                        value={FormData.email ? FormData.email : ''}
                                        disabled
                                    />
                                    <Label>
                                        Email
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup floating>
                                    <Input
                                        name="cell_phone"
                                        type="text"
                                        value={FormData.cell_phone ? FormData.cell_phone : ''}
                                        disabled
                                    />
                                    <Label>
                                        Celular
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup floating>
                                    <Input
                                        name="companie_name"
                                        type="text"
                                        value={FormData.companie_name ? FormData.companie_name : ''}
                                        disabled
                                    />
                                    <Label>
                                        Empresa
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup floating>
                                    <Input
                                        name="branches"
                                        type="text"
                                        value={FormData.branches ? FormData.branches.name : ''}
                                        disabled
                                    />
                                    <Label>
                                        Ramo
                                    </Label>
                                </FormGroup>
                            </Col>      
                            <Col sm="12">
                                <FormGroup floating>
                                    <Input
                                        name="interesse"
                                        type="text"
                                        value={FormData.list_partnerships_clients ? FormData.list_partnerships_clients : '-'}   
                                        disabled
                                    />
                                    <Label>
                                        Interesse(s)
                                    </Label>
                                </FormGroup>
                            </Col>                                          
                        </Row>
                    </Col>
                    <Col sm="3">
                        <Row>
                            <Col md={12} className="text-center ">
                                <div className="titulo-hr">
                                    <span>Disponibilidade</span>
                                </div>
                            </Col>
                            <Col sm="12" className="mb-4">

                                <Row>
                                    <Col sm="12" className="mb-2">
                                        Segunda-feira
                                    </Col>
                                    <Col sm="12">
                                        <Row>
                                            <Col sm="6">
                                                <FormGroup floating>
                                                    <Input
                                                        type="text"
                                                        value={FormData.time_i_1 ? FormData.time_i_1 : ''}
                                                        disabled
                                                    />
                                                    <Label>
                                                        Horário Inicial
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                            <Col sm="6">
                                                <FormGroup floating>
                                                    <Input
                                                        autoComplete={false}
                                                        type="text"
                                                        value={FormData.time_f_1 ? FormData.time_f_1 : ''}
                                                        disabled
                                                    />
                                                    <Label>
                                                        Horário Final
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row>

                                    <Col sm="12" className="mb-2">
                                        Terça-feira
                                    </Col>
                                    <Col sm="12">
                                        <Row>
                                            <Col sm="6">
                                                <FormGroup floating>
                                                    <Input
                                                        autoComplete={false}
                                                        type="text"
                                                        value={FormData.time_i_2 ? FormData.time_i_2 : ''}
                                                        disabled
                                                    />
                                                    <Label>
                                                        Horário Inicial
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                            <Col sm="6">
                                                <FormGroup floating>
                                                    <Input
                                                        autoComplete={false}
                                                        type="text"
                                                        value={FormData.time_f_2 ? FormData.time_f_2 : ''}
                                                        disabled
                                                    />
                                                    <Label>
                                                        Horário Final
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row>

                                    <Col sm="12" className="mb-1">
                                        Quarta-feira
                                    </Col>
                                    <Col sm="12">
                                        <Row>
                                            <Col sm="6">
                                                <FormGroup floating>
                                                    <Input
                                                        autoComplete={false}
                                                        type="text"
                                                        value={FormData.time_i_3 ? FormData.time_i_3 : ''}
                                                        disabled
                                                    />
                                                    <Label>
                                                        Horário Inicial
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                            <Col sm="6">
                                                <FormGroup floating>
                                                    <Input
                                                        autoComplete={false}
                                                        type="text"
                                                        value={FormData.time_f_3 ? FormData.time_f_3 : ''}
                                                        disabled
                                                    />
                                                    <Label>
                                                        Horário Final
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12" className="mb-1">
                                        Quinta-feira
                                    </Col>
                                    <Col sm="12">
                                        <Row>
                                            <Col sm="6">
                                                <FormGroup floating>
                                                    <Input
                                                        autoComplete={false}
                                                        type="text"
                                                        value={FormData.time_i_4 ? FormData.time_i_4 : ''}
                                                        disabled
                                                    />
                                                    <Label>
                                                        Horário Inicial
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                            <Col sm="6">
                                                <FormGroup floating>
                                                    <Input
                                                        autoComplete={false}
                                                        type="text"
                                                        value={FormData.time_f_4 ? FormData.time_f_4 : ''}
                                                        disabled
                                                    />
                                                    <Label>
                                                        Horário Final
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="12" className="mb-1">
                                        Sexta-feira
                                    </Col>
                                    <Col sm="12">
                                        <Row>
                                            <Col sm="6">
                                                <FormGroup floating>
                                                    <Input
                                                        autoComplete={false}
                                                        type="text"
                                                        value={FormData.time_i_5 ? FormData.time_i_5 : ''}
                                                        disabled
                                                    />
                                                    <Label>
                                                        Horário Inicial
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                            <Col sm="6">
                                                <FormGroup floating>
                                                    <Input
                                                        autoComplete={false}
                                                        type="text"
                                                        value={FormData.time_f_5 ? FormData.time_f_5 : ''}
                                                        disabled
                                                    />
                                                    <Label>
                                                        Horário Final
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </Col>

                    <Col sm="5">
                        <Row>
                            <Col md={12} className="text-center mt-2">
                                <div className="titulo-hr">
                                    <span>Dados da reunião</span>
                                </div>
                            </Col>
                            <Col sm="6">
                                <FormGroup floating>
                                    <Input
                                        autoComplete={false}
                                        name="date"
                                        type="date"
                                        min={date_now}
                                        onChange={(event) => { setFormDataMeet({ ...FormDataMeet, 'date': event.target.value }); }}
                                        value={FormDataMeet.date ? FormDataMeet.date : ''}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.date && validation.errors.date ? true : false
                                        }
                                    />
                                    {validation.touched.date && validation.errors.date ? (
                                        <FormFeedback type="invalid">{validation.errors.date}</FormFeedback>
                                    ) : null}
                                    <Label>
                                        Data da reunião
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup floating>
                                    <Input
                                        autoComplete={false}
                                        name="time"
                                        type="time"
                                        onChange={(event) => { setFormDataMeet({ ...FormDataMeet, 'time': event.target.value }); }}
                                        value={FormDataMeet.time ? FormDataMeet.time : ''}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.time && validation.errors.time ? true : false
                                        }
                                    />
                                    {validation.touched.time && validation.errors.time ? (
                                        <FormFeedback type="invalid">{validation.errors.time}</FormFeedback>
                                    ) : null}
                                    <Label>
                                        Horário da reunião
                                    </Label>
                                </FormGroup>
                            </Col>                            
                            <Col sm="12">
                                <FormGroup floating>
                                    <Input
                                        name="duration"
                                        type="select"
                                        onChange={(event) => { setFormDataMeet({ ...FormDataMeet, 'duration': event.target.value }); }}
                                        value={FormDataMeet.duration ? FormDataMeet.duration : ''}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.duration && validation.errors.duration ? true : false
                                        }
                                     >
                                        <option value=""> Selecione... </option>
                                        <option value="30 min"> 30 min </option>
                                        <option value="1 h"> 1 h </option>
                                        <option value="1 h e 30 min"> 1 h e 30 min </option>
                                        <option value="2 h"> 2 h </option>
                                    </Input>
                                    {validation.touched.duration && validation.errors.duration ? (
                                        <FormFeedback type="invalid">{validation.errors.duration}</FormFeedback>
                                    ) : null}
                                    <Label>
                                        Duração
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup floating>
                                    <Input
                                        name="format"
                                        type="select"
                                        onChange={(event) => { setFormDataMeet({ ...FormDataMeet, 'format': event.target.value }); }}
                                        value={FormDataMeet.format ? FormDataMeet.format : ''}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.format && validation.errors.format ? true : false
                                        }
                                    >
                                        <option value=""> Selecione... </option>
                                        <option value="Remoto / online"> Remoto / online </option>
                                        <option value="Presencial"> Presencial </option>
                                    </Input>
                                    {validation.touched.format && validation.errors.format ? (
                                        <FormFeedback type="invalid">{validation.errors.format}</FormFeedback>
                                    ) : null}
                                    <Label>
                                        Formato
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup floating>
                                    <Input
                                        autoComplete={false}
                                        name="subject"
                                        type="text"
                                        maxLength={255}
                                        onChange={(event) => { setFormDataMeet({ ...FormDataMeet, 'subject': event.target.value }); }}
                                        value={FormDataMeet.subject ? FormDataMeet.subject : ''}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.subject && validation.errors.subject ? true : false
                                        }
                                    />
                                    {validation.touched.subject && validation.errors.subject ? (
                                        <FormFeedback type="invalid">{validation.errors.subject}</FormFeedback>
                                    ) : null}
                                    <Label>
                                        Assunto da reunião
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup>
                                    <Label>
                                        Objetivo da reunião
                                    </Label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={CkeditorOptionsEditorConfiguration}
                                        data={FormDataMeet.description ? FormDataMeet.description : ''}
                                        onReady={editor => {
                                            // Allows you to store the editor instance and use it later.
                                            // // console.log('11', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            // // console.log({ event, editor, data });
                                            setFormDataMeet({ ...FormDataMeet, 'description': (data) });
                                        }}
                                        onBlur={(event, editor) => {
                                            // // console.log('22', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            // // console.log('333', editor);
                                        }}
                                    />
                                    {validation.touched.description && validation.errors.description ? (
                                        <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                    ) : null}
                                </FormGroup>                                
                            </Col>
                        </Row>
                    </Col>



                </Row>

                <Row className="mt-3 mb-2">
                    <Col xs="6">
                        <Button type="submit" color="success" disabled={Loading ? true : false}>
                            Agendar
                        </Button>
                    </Col>
                    <Col xs="6" className="text-right pb-2">
                        <button type="button" className="btn btn-secondary" onClick={toggle} disabled={Loading ? true : false}>Fechar</button>
                    </Col>
                </Row>
            </Form>
        </ModalBase>
    );
}

ModalScheduleMeetings.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
