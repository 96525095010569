import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef, useCallback } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import {
    Row,
    Col,
    Form,
    Badge,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Label,
    Spinner,
} from "reactstrap";
import ModalBase from '../../components/modal/modal';
import api from "../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import { encodeImageFileAsURL } from "../../helpers/filesBase64";
import {
    showToastArray,
  } from '../../helpers/generals';
import ModalCrop from '../crop/crop';
  
export default function ModalUploadImage({
    id,
    reloadData,
    toggle,
    isOpen,
    crop,
    path,
    size,
    title,
}) {

    const [Loading, setLoading] = useState(false);
    const [Image, setImage] = useState();
    const [NameImage, setNameImage] = useState('');
    const [FileB64, setFileB64] = useState();

    const [ModalCropOpen, setModalCropOpen] = useState(false);
    const [ModalOpen, setModalOpen] = useState(false);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            Image: Image || '',
            avatar: FileB64 || '',
        },
        validationSchema: Yup.object({
            Image: Yup.string().required("Selecione uma imagem."),  
            avatar: Yup.string().required("Selecione uma imagem."),    
        }), onSubmit: (values) => {
            setLoading(true);
            const res = api.put(path + id, values).then(response => {
              return response.data;
            }).then(data => {
              if (data.success == true) {
                setNameImage(data.avatar);
                localStorage.setItem("UserAvatar", data.avatar);
                // localStorage.setItem("UserLogo", data.user.logo);
                if (crop) {
                    setModalCropOpen(true);
                    setModalOpen(true)
                } else {
                    reloadData();
                }
                setImage();
                setFileB64();
                setLoading(false);
              }
            }).catch(error => {
              if (error) {
                let message = 'Erro ao enviar o registro!';
                    // if (error) {
                    //   message = error.response.data.message;
                    // }
                showToastArray("error", "Erro!", message);
                setLoading(false);
              }
            });
        },
    });
    
     async function fileBase64Code(event) {
        event.persist();
      
        if (!event.target || !event.target.files) {
          return;
        }
      
        const fileList = event.target.files;
        const latestUploadedFile = fileList.item(fileList.length - 1);
      
        try {
            const fileContents = await encodeImageFileAsURL(latestUploadedFile);
            let b64 = '';
            let base64result = fileContents.substr(fileContents.indexOf(',') + 1);
            let base64dataType = fileContents.split(';base64,')[0];
            base64dataType = base64dataType.split('/')[1];
            b64 = base64dataType + '##@@nedo##$$' + base64result; 
            if(b64) setFileB64(b64);
        } catch (e) {
            // console.log(e);
        }
    };

    useEffect(() => {

    }, []); 

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={title}
        >

            {ModalCropOpen ? (<ModalCrop
                toggle={() => setModalOpen(!ModalOpen)}
                isOpen={ModalOpen}
                id={id}
                NameImage={NameImage}
                crop={crop}
                size="xl"
                title="Ajustar seu avatar"
                reloadData={reloadData}
            />) : ''}

            <Form
                className="form-horizontal" 
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Row>
                    <Col sm="12">
                        <FormGroup>
                            <Label>
                                Selecionar imagem
                            </Label>                        
                            <Input
                                className=""
                                autoComplete={false}
                                name="image"
                                type="file"
                                onChange={(event) => { 
                                    setImage(event.target.value); 
                                    fileBase64Code(event);

                                }}
                                value={Image ? Image : ''}
                                accept="image/jpeg,image/png"
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.Image && validation.errors.Image ? true : false
                                }
                            />
                            {validation.touched.Image && validation.errors.Image ? (
                                <FormFeedback type="invalid">{validation.errors.Image}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>                     
                </Row>

                <Row className="mt-3 mb-2">
                    <Col xs="6">
                        {Loading ? (
                            <Spinner color="info"></Spinner>
                        ) : (
                            <Button type="submit" color="success" disabled={Loading ? true : false }>
                                Atualizar
                            </Button>
                        ) }
                    </Col>                    
                    <Col xs="6" className="text-right pb-2">
                        <button type="button" className="btn btn-secondary" onClick={toggle} disabled={Loading ? true : false }>Fechar</button>
                    </Col>
                </Row>
            </Form>
        </ModalBase>
    );
}

ModalUploadImage.propTypes = {
    id: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    crop: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
