import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";

import {
    Row,
    Col,
    Form,
    Badge,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Label,
} from "reactstrap";
import ModalBase from '../../../components/modal/modal';
import api from "../../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    MaskCellPhone,
    MaskCep,
    MaskCpf,
    MaskCnpj,
    formatarMilharReais,
} from '../../../helpers/mascarasParaCampos';
import {
    showToastArray,
} from '../../../helpers/generals';

export default function ModalUnits({
    modalId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [FormData, setFormData] = useState({});
    const [CountriesData, setCountriesData] = useState([]);
    const [StatesData, setStatesData] = useState([]);
    const [CitiesData, setCitiesData] = useState([]);

    const [Loading, setLoading] = useState(true);

    const LoadDataShow = async => {
        if (modalId > 0) {
            try {
                api.get('/api/units/show/' + modalId).then(response => {
                    return response;
                }).then(data => {
                    LoadCountries();
                    LoadStates(data.data.data.countries_id);
                    LoadCities(data.data.data.states_id);
                    setFormData(data.data.data);
                    setLoading(false);
                }).catch(error => {
                    // console.log(error);
                });
            } catch (error) {
                // console.log(error);
            }
        }
    };

    useEffect(() => {
        LoadCountries();
        LoadDataShow();
    }, []);

    const LoadCountries = async => {
        setCountriesData([]);
        setStatesData([]);
        setCitiesData([]);
        api.get('/api/countries/active').then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setCountriesData(data.data);
                setLoading(false);
            }
        }).catch(error => {
            // console.log(error);
        })
    };

    function LoadStates(id) {
        setStatesData([]);
        setCitiesData([]);
        api.get('/api/states/active/countries/' + id).then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setStatesData(data.data);
            }
        }).catch(error => {
            // console.log(error);
        })
    };

    function LoadCities(id) {
        setCitiesData([]);
        api.get('/api/cities/active/states/' + id).then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setCitiesData(data.data);
            }
        }).catch(error => {
            // console.log(error);
        })
    };

    // function reloadData () {

    // }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            type: FormData.type || '',
            name: FormData.name || '',

            responsible_name: FormData.responsible_name || '',
            responsible_cpf: FormData.responsible_cpf || '',
            responsible_email: FormData.responsible_email || '',
            responsible_cell_phone: FormData.responsible_cell_phone || '',

            companie_legal_name: FormData.companie_legal_name || '',
            companie_cnpj: FormData.companie_cnpj || '',
            companie_taxation: FormData.companie_taxation || '',
            companie_share_capital: FormData.companie_share_capital || '',
            companie_name: FormData.companie_name || '',
            companie_cpf: FormData.companie_cpf || '',

            address: FormData.address || '',
            number: FormData.number || '',
            complement: FormData.complement || '',
            district: FormData.district || '',
            zip_code: FormData.zip_code || '',

            countries_id: FormData.countries_id || '',
            states_id: FormData.states_id || '',
            cities_id: FormData.cities_id || '',

            is_active: FormData.is_active || '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Digite esse campo."),

            responsible_name: Yup.string().required("Digite esse campo."),
            // responsible_cpf: Yup.string().required("Digite esse campo."),
            responsible_email: Yup.string().required("Digite esse campo."),
            responsible_cell_phone: Yup.string().required("Digite esse campo."),

            companie_legal_name: Yup.string().required("Digite esse campo."),
            companie_cnpj: Yup.string().required("Digite esse campo."),
            companie_share_capital: Yup.string().required("Digite esse campo."),
            companie_name: Yup.string().required("Digite esse campo."),
            // companie_cpf: Yup.string().required("Digite esse campo."),

            address: Yup.string().required("Digite esse campo."),
            number: Yup.string().required("Digite esse campo."),
            district: Yup.string().required("Digite esse campo."),
            zip_code: Yup.string().required("Digite esse campo."),

            type: Yup.string().required("Selecione uma opção."),

            companie_taxation: Yup.string().required("Selecione uma opção."),

            countries_id: Yup.string().required("Selecione uma opção."),
            states_id: Yup.string().required("Selecione uma opção."),
            cities_id: Yup.string().required("Selecione uma opção."),

            is_active: Yup.string().required("Selecione uma opção."),
        }),
        onSubmit: (values) => {
            setLoading(true);
            if (modalId) {
                api.put('/api/units/update/' + modalId, values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success == true) {
                        showToastArray("", "Sucesso", "Cadastro atualizado com sucesso!");
                        setLoading(false);
                        reloadData();
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });
            } else {
                api.post('/api/units/save', values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success == true) {
                        showToastArray("", "Sucesso", "Cadastro atualizado com sucesso!");
                        setLoading(false);
                        reloadData();
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });
            }
        },
    });

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={modalId > 0 ? 'Editar | ' + title : 'Adicionar | ' + title}
        >
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Row>
                    <Col sm="2">
                        <FormGroup floating>
                            <Input
                                id="type"
                                className="type"
                                name="type"
                                type="select"
                                placeholder="Tipo"
                                onChange={(event) => { setFormData({ ...FormData, 'type': event.target.value }); }}
                                value={FormData.type ? FormData.type : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.type && validation.errors.type ? true : false
                                }
                            >
                                <option value=""> Selecione... </option>
                                <option value="1"> Própria </option>
                                <option value="2"> Franqueada </option>
                            </Input>
                            <Label>
                                Tipo
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="8">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="name"
                                type="text"
                                maxLength={255}
                                placeholder="Nome da Unidade"
                                onChange={(event) => { setFormData({ ...FormData, 'name': event.target.value }); }}
                                value={FormData.name ? FormData.name : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.name && validation.errors.name ? true : false
                                }
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                            ) : null}
                            <Label>
                                Nome da Unidade
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="2">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="is_active"
                                type="select"
                                placeholder="Situação"
                                onChange={(event) => { setFormData({ ...FormData, 'is_active': event.target.value }); }}
                                value={FormData.is_active ? FormData.is_active : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.is_active && validation.errors.is_active ? true : false
                                }
                            >
                                {validation.touched.is_active && validation.errors.is_active ? (
                                    <FormFeedback type="invalid">{validation.errors.is_active}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                <option value="1"> Ativo </option>
                                <option value="2"> Inativo  </option>
                            </Input>
                            <Label>
                                Situação
                            </Label>
                        </FormGroup>
                    </Col>

                    <Col md={12} className="text-center mt-2">
                        <div className="titulo-hr">
                            <span>Dados do responsável da Unidade</span>
                        </div>
                    </Col>
                    <Col sm="9">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="responsible_name"
                                type="text"
                                maxLength={255}
                                placeholder="Nome"
                                onChange={(event) => { setFormData({ ...FormData, 'responsible_name': event.target.value }); }}
                                value={FormData.responsible_name ? FormData.responsible_name : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.responsible_name && validation.errors.responsible_name ? true : false
                                }
                            />
                            {validation.touched.responsible_name && validation.errors.responsible_name ? (
                                <FormFeedback type="invalid">{validation.errors.responsible_name}</FormFeedback>
                            ) : null}
                            <Label>
                                Nome
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="3">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="responsible_cpf"
                                type="text"
                                placeholder="CPF"
                                onChange={(event) => { setFormData({ ...FormData, 'responsible_cpf': MaskCpf(event.target.value) }); }}
                                value={FormData.responsible_cpf ? FormData.responsible_cpf : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.responsible_cpf && validation.errors.responsible_cpf ? true : false
                                }
                            />
                            {validation.touched.responsible_cpf && validation.errors.responsible_cpf ? (
                                <FormFeedback type="invalid">{validation.errors.responsible_cpf}</FormFeedback>
                            ) : null}
                            <Label>
                                CPF
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="9">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="responsible_email"
                                type="email"
                                placeholder="E-mail"
                                onChange={(event) => { setFormData({ ...FormData, 'responsible_email': event.target.value }); }}
                                value={FormData.responsible_email ? FormData.responsible_email : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.responsible_email && validation.errors.responsible_email ? true : false
                                }
                            />
                            {validation.touched.responsible_email && validation.errors.responsible_email ? (
                                <FormFeedback type="invalid">{validation.errors.responsible_email}</FormFeedback>
                            ) : null}
                            <Label>
                                E-mail
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="3">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="responsible_cell_phone"
                                type="text"
                                placeholder="Celular"
                                onChange={(event) => { setFormData({ ...FormData, 'responsible_cell_phone': MaskCellPhone(event.target.value) }); }}
                                value={FormData.responsible_cell_phone ? FormData.responsible_cell_phone : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.responsible_cell_phone && validation.errors.responsible_cell_phone ? true : false
                                }
                            />
                            {validation.touched.responsible_cell_phone && validation.errors.responsible_cell_phone ? (
                                <FormFeedback type="invalid">{validation.errors.responsible_cell_phone}</FormFeedback>
                            ) : null}
                            <Label>
                                Celular
                            </Label>
                        </FormGroup>
                    </Col>

                    <Col md={12} className="text-center mt-2">
                        <div className="titulo-hr">
                            <span>Endereço</span>
                        </div>
                    </Col>
                    <Col sm="9">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="address"
                                type="text"
                                maxLength={255}
                                placeholder="Razão Social"
                                onChange={(event) => { setFormData({ ...FormData, 'address': event.target.value }); }}
                                value={FormData.address ? FormData.address : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.address && validation.errors.address ? true : false
                                }
                            />
                            {validation.touched.address && validation.errors.address ? (
                                <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                            ) : null}
                            <Label>
                                Endereço
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="3">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="number"
                                type="text"
                                maxLength={25}
                                placeholder="Número"
                                onChange={(event) => { setFormData({ ...FormData, 'number': event.target.value }); }}
                                value={FormData.number ? FormData.number : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.number && validation.errors.number ? true : false
                                }
                            />
                            {validation.touched.number && validation.errors.number ? (
                                <FormFeedback type="invalid">{validation.errors.number}</FormFeedback>
                            ) : null}
                            <Label>
                                Número
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="district"
                                type="text"
                                maxLength={255}
                                placeholder="Bairro"
                                onChange={(event) => { setFormData({ ...FormData, 'district': event.target.value }); }}
                                value={FormData.district ? FormData.district : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.district && validation.errors.district ? true : false
                                }
                            />
                            {validation.touched.district && validation.errors.district ? (
                                <FormFeedback type="invalid">{validation.errors.district}</FormFeedback>
                            ) : null}
                            <Label>
                                Bairro
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="complement"
                                type="text"
                                maxLength={25}
                                placeholder="Complemento"
                                onChange={(event) => { setFormData({ ...FormData, 'complement': event.target.value }); }}
                                value={FormData.complement ? FormData.complement : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.complement && validation.errors.complement ? true : false
                                }
                            />
                            {validation.touched.complement && validation.errors.complement ? (
                                <FormFeedback type="invalid">{validation.errors.complement}</FormFeedback>
                            ) : null}
                            <Label>
                                Complemento
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="zip_code"
                                type="text"
                                placeholder="CEP"
                                onChange={(event) => { setFormData({ ...FormData, 'zip_code': MaskCep(event.target.value) }); }}
                                value={FormData.zip_code ? FormData.zip_code : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.zip_code && validation.errors.zip_code ? true : false
                                }
                            />
                            {validation.touched.zip_code && validation.errors.zip_code ? (
                                <FormFeedback type="invalid">{validation.errors.zip_code}</FormFeedback>
                            ) : null}
                            <Label>
                                CEP
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col lg={4}>
                        <FormGroup floating>
                            <Input
                                id="countries_id"
                                className="countries_id"
                                name="countries_id"
                                type="select"
                                placeholder="País"
                                onChange={(event) => {
                                    setFormData({ ...FormData, 'countries_id': event.target.value });
                                    LoadStates(event.target.value);
                                }}
                                value={FormData.countries_id ? FormData.countries_id : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.countries_id && validation.errors.countries_id ? true : false
                                }
                            >
                                {validation.touched.countries_id && validation.errors.countries_id ? (
                                    <FormFeedback type="invalid">{validation.errors.countries_id}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                {CountriesData.map((row, index) => (
                                    <option key={index} value={row.id}>{row.name}</option>
                                ))}
                            </Input>
                            <Label>
                                País
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col lg={4}>
                        <FormGroup floating>
                            <Input
                                disabled={FormData.countries_id && CountriesData.length > 0 ? false : true}
                                id="states_id"
                                className="states_id"
                                name="states_id"
                                type="select"
                                placeholder="Estado"
                                onChange={(event) => {
                                    setFormData({ ...FormData, 'states_id': event.target.value });
                                    LoadCities(event.target.value);
                                }}
                                value={FormData.states_id ? FormData.states_id : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.states_id && validation.errors.states_id ? true : false
                                }
                            >
                                {validation.touched.states_id && validation.errors.states_id ? (
                                    <FormFeedback type="invalid">{validation.errors.states_id}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                {StatesData.map((row, index) => (
                                    <option key={index} value={row.id}>{row.name}</option>
                                ))}
                            </Input>
                            <Label>
                                Estado
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col lg={4}>
                        <FormGroup floating>
                            <Input
                                disabled={FormData.states_id && StatesData.length > 0 ? false : true}
                                id="cities_id"
                                className="cities_id"
                                name="cities_id"
                                type="select"
                                placeholder="Cidade"
                                onChange={(event) => { setFormData({ ...FormData, 'cities_id': event.target.value }); }}
                                value={FormData.cities_id ? FormData.cities_id : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.cities_id && validation.errors.cities_id ? true : false
                                }
                            >
                                {validation.touched.cities_id && validation.errors.cities_id ? (
                                    <FormFeedback type="invalid">{validation.errors.cities_id}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                {CitiesData.map((row, index) => (
                                    <option key={index} value={row.id}>{row.name}</option>
                                ))}
                            </Input>
                            <Label>
                                Cidade
                            </Label>
                        </FormGroup>
                    </Col>

                    <Col md={12} className="text-center mt-2">
                        <div className="titulo-hr">
                            <span>Dados da Pessoa Juridica da Unidade</span>
                        </div>
                    </Col>
                    <Col sm="9">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="companie_legal_name"
                                type="text"
                                maxLength={255}
                                placeholder="Razão Social"
                                onChange={(event) => { setFormData({ ...FormData, 'companie_legal_name': event.target.value }); }}
                                value={FormData.companie_legal_name ? FormData.companie_legal_name : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.companie_legal_name && validation.errors.companie_legal_name ? true : false
                                }
                            />
                            {validation.touched.companie_legal_name && validation.errors.companie_legal_name ? (
                                <FormFeedback type="invalid">{validation.errors.companie_legal_name}</FormFeedback>
                            ) : null}
                            <Label>
                                Razão Social
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="3">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="companie_cnpj"
                                type="text"
                                placeholder="CPF"
                                onChange={(event) => { setFormData({ ...FormData, 'companie_cnpj': MaskCnpj(event.target.value) }); }}
                                value={FormData.companie_cnpj ? FormData.companie_cnpj : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.companie_cnpj && validation.errors.companie_cnpj ? true : false
                                }
                            />
                            {validation.touched.companie_cnpj && validation.errors.companie_cnpj ? (
                                <FormFeedback type="invalid">{validation.errors.companie_cnpj}</FormFeedback>
                            ) : null}
                            <Label>
                                CNPJ
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="8">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="companie_taxation"
                                type="select"
                                placeholder="Tributação"
                                onChange={(event) => { setFormData({ ...FormData, 'companie_taxation': event.target.value }); }}
                                value={FormData.companie_taxation ? FormData.companie_taxation : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.companie_taxation && validation.errors.companie_taxation ? true : false
                                }
                            >
                                {validation.touched.companie_taxation && validation.errors.companie_taxation ? (
                                    <FormFeedback type="invalid">{validation.errors.companie_taxation}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                <option value="1"> MEI </option>
                                <option value="2"> Simples Nacional  </option>
                                <option value="3">Lucro Presumido</option>
                            </Input>
                            <Label>
                                Tributação
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="companie_share_capital"
                                type="text"
                                maxLength={255}
                                placeholder="Capital Social"
                                onChange={(event) => { setFormData({ ...FormData, 'companie_share_capital': formatarMilharReais(event.target.value) }); }}
                                value={FormData.companie_share_capital ? FormData.companie_share_capital : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.companie_share_capital && validation.errors.companie_share_capital ? true : false
                                }
                            />
                            {validation.touched.companie_share_capital && validation.errors.companie_share_capital ? (
                                <FormFeedback type="invalid">{validation.errors.companie_share_capital}</FormFeedback>
                            ) : null}
                            <Label>
                                Capital Social
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="8">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="companie_name"
                                type="text"
                                maxLength={255}
                                placeholder="Sócio Administrador"
                                onChange={(event) => { setFormData({ ...FormData, 'companie_name': event.target.value }); }}
                                value={FormData.companie_name ? FormData.companie_name : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.companie_name && validation.errors.companie_name ? true : false
                                }
                            />
                            {validation.touched.companie_name && validation.errors.companie_name ? (
                                <FormFeedback type="invalid">{validation.errors.companie_name}</FormFeedback>
                            ) : null}
                            <Label>
                                Sócio Administrador
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="companie_cpf"
                                type="text"
                                placeholder="CPF Sócio Administrador"
                                onChange={(event) => { setFormData({ ...FormData, 'companie_cpf': MaskCpf(event.target.value) }); }}
                                value={FormData.companie_cpf ? FormData.companie_cpf : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.companie_cpf && validation.errors.companie_cpf ? true : false
                                }
                            />
                            {validation.touched.companie_cpf && validation.errors.companie_cpf ? (
                                <FormFeedback type="invalid">{validation.errors.companie_cpf}</FormFeedback>
                            ) : null}
                            <Label>
                                CPF Sócio Administrador
                            </Label>
                        </FormGroup>
                    </Col>

                </Row>
                <Row className="mt-3 mb-2">
                    <Col xs="6">
                        <Button type="submit" color="success" disabled={Loading ? true : false}>
                            {modalId > 0 ? 'Atualizar' : 'Adicionar'}
                        </Button>
                    </Col>
                    <Col xs="6" className="text-right pb-2">
                        <button type="button" className="btn btn-secondary" onClick={toggle} disabled={Loading ? true : false}>Fechar</button>
                    </Col>
                </Row>
            </Form>
        </ModalBase>
    );
}

ModalUnits.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
