import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Form,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Label,
} from "reactstrap";
import ModalBase from '../../../../../components/modal/modal';
import api from "../../../../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    showToastArray,
} from '../../../../../helpers/generals';
import { DateToData, formatarMilharReais } from '../../../../../helpers/mascarasParaCampos';

export default function ModalInterviewAdminMembersPayments({
    modalId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [FormData, setFormData] = useState({});
    const [Loading, setLoading] = useState(false);

    const [update, setUpdate] = useState(false);
    const [Plans, setPlans] = useState([]);
    const [MethodsPayments, setMethodsPayments] = useState([]);

    const [status, setStatus] = useState(0);
    const [contractCharges, setContractCharges] = useState([]);
    const [contract, setContract] = useState({});

    function LoadPlans() {
        setPlans([]);
        api.get('/api/plans/active').then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setPlans(data.data);
            }
        }).catch(error => {
            // console.log(error);
        })
    };

    function LoadMethodsPayments() {
        setPlans([]);
        api.get('/api/methods-payments/active').then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setMethodsPayments(data.data);
            }
        }).catch(error => {
            // console.log(error);
        })
    }; 

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            plans_id: FormData.plans_id || '',
            due_date: FormData.due_date || '',
            type_pagament: FormData.type_pagament || '',
        },
        validationSchema: Yup.object({
            plans_id: Yup.string().required("Selecione um plano."),
            due_date: Yup.string().required("Digite o vencimento."),
            type_pagament: Yup.string().required("Selecione uma forma de pagamento."),
        }),
        onSubmit: (values) => {
            setLoading(true);
            api.put('/api/charges/member/update-payment/' + modalId, values).then(response => {
                return response.data;
            }).then(data => {
                if (data.success == true) {
                    showToastArray("", "Sucesso", "Cadastro atualizado com sucesso!");
                    setLoading(false);
                    reloadData();
                }
            }).catch(error => {
                if (error) {
                    let message = 'Erro ao enviar o registro!';
                    if (error.response.data.message) {
                        message = error.response.data.message;
                    }
                    showToastArray("error", "Erro!", message);
                    setLoading(false);
                }
            });

        },
    });

    const LoadDataShow = async => {
        if (modalId > 0) {
            try {
                api.get('/api/users/show/' + modalId).then(response => {
                    return response;
                }).then(data => {
                    if (data.data.success) {

                        const formData = { ...FormData };

                        if (data.data.data) {
                            const item = data.data.data;
                            if (item.subscription_plan) formData.plans_id = item.subscription_plan;
                            if (item.status) setStatus(item.status);
                            if (item.contracts && item.contracts.charges) {
                                setContract(item.contracts);
                                setContractCharges(item.contracts.charges);
                            }
                        }

                        setFormData(formData);
                        setLoading(false);
                    }
                }).catch(error => {
                    // console.log(error);
                });
            } catch (error) {
                // console.log(error);
            }
        }
    };

    useEffect(() => {
        LoadPlans();
        LoadMethodsPayments();
        LoadDataShow();
    }, [modalId]);

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={'Pagamento(s) | ' + title}
        >
            {status && (status > 5 && status < 9) ? (
                <>
                    <Row>
                        <Col sm="12" className="m-0 p-0">
                            <hr className="m-0 p-0" />
                        </Col>
                        <Col sm="12" className="mt-3 mb-2">
                            <Col sm="12">
                                <Row>
                                    <Col sm="6" className="mb-2">
                                        Plano:<br/>
                                        <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px'}}>
                                            {contract && contract.plans ? contract.plans.name : '-'}    
                                        </div>
                                    </Col>
                                    <Col sm="6" className="mb-2">
                                        Forma de Pagamento:<br/>
                                        <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px'}}>
                                            {contract && contract.methods_payments ? contract.methods_payments.name : '-'}    
                                        </div>
                                    </Col>
                                    <Col sm="6" className="mb-2">
                                        Serviço:<br/>
                                        <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px'}}>
                                            {contract && contract.services ? contract.services.name : '-'}    
                                        </div>
                                    </Col>
                                    <Col sm="6" className="mb-2">
                                        Valor total:<br/>
                                        <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px'}}>
                                            {contract && contract.amount ? 'R$ ' + formatarMilharReais(contract.amount) : '-'}    
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                        </Col>
                        <Col sm="12" className="m-0 p-0">
                            <hr className="m-0 p-0" />
                        </Col>
                        <Col sm="12" className="mt-3 mb-2">
                            <h6>Mensalidade(s):</h6>
                        </Col>
                        {contractCharges && contractCharges.length > 0 ? (
                            contractCharges.map((row, index) => (
                                <Col key={index} sm="12" className="mb-2">
                                    <Row>
                                        <Col sm="1">
                                            {row.parcel ? row.parcel + '/' + row.total_parcels : '-'}
                                        </Col>
                                        <Col sm="11">
                                            <Row>
                                                <Col sm="3">
                                                    {row.due_date ? DateToData(row.due_date, "/") : '-'}
                                                </Col>
                                                <Col sm="3">
                                                    {row.value ? 'R$ ' + formatarMilharReais(row.value) : 'R$ -'}
                                                </Col>
                                                <Col sm="3">
                                                    {row.payday ? DateToData(row.payday, "/") : '-'}
                                                </Col>
                                                <Col sm="3">
                                                    {row.amount_paid ? 'R$ ' + formatarMilharReais(row.amount_paid) : 'R$ -'}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            ))
                        ) : null}
                    </Row>
                </>
            ) : (
                <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <Row>
                        <Col sm="12">
                            <FormGroup floating>
                                <Input
                                    className=""
                                    autoComplete={false}
                                    name="plans_id"
                                    disabled={update ? true : false}
                                    type="select"
                                    onChange={(event) => { setFormData({ ...FormData, 'plans_id': event.target.value }); }}
                                    value={FormData.plans_id ? FormData.plans_id : ''}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                        validation.touched.plans_id && validation.errors.plans_id ? true : false
                                    }
                                >
                                    {validation.touched.plans_id && validation.errors.plans_id ? (
                                        <FormFeedback type="invalid">{validation.errors.plans_id}</FormFeedback>
                                    ) : null}
                                    <option value=""> Selecione... </option>
                                    {Plans.map((row, index) => (
                                        <option key={index} value={row.id}>{row.name}</option>
                                    ))}
                                </Input>
                                <Label>
                                    Plano atual
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm="12">
                            <FormGroup floating>
                                <Input
                                    className=""
                                    autoComplete={false}
                                    name="type_pagament"
                                    disabled={update ? true : false}
                                    type="select"
                                    onChange={(event) => { setFormData({ ...FormData, 'type_pagament': event.target.value }); }}
                                    value={FormData.type_pagament ? FormData.type_pagament : ''}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                        validation.touched.type_pagament && validation.errors.type_pagament ? true : false
                                    }
                                >
                                    {validation.touched.type_pagament && validation.errors.type_pagament ? (
                                        <FormFeedback type="invalid">{validation.errors.type_pagament}</FormFeedback>
                                    ) : null}
                                    <option value=""> Selecione... </option>
                                    {MethodsPayments.map((row, index) => (
                                        <option key={index} value={row.id}>{row.name}</option>
                                    ))}
                                </Input>
                                <Label>
                                    Forma de Pagamento
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm="12">
                            <FormGroup floating>
                                <Input
                                    autoComplete={false}
                                    disabled={update ? true : false}
                                    name="due_date"
                                    type="select"
                                    value={FormData.due_date ? FormData.due_date : ''}
                                    onChange={(event) => { setFormData({ ...FormData, 'due_date': event.target.value }); }}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                        validation.touched.due_date && validation.errors.due_date ? true : false
                                    }
                                >
                                    <option value=""> Selecione... </option>
                                    <option value="5"> 5 </option>
                                    <option value="10"> 10 </option>
                                    <option value="15"> 15 </option>
                                    <option value="20"> 20 </option>
                                    <option value="25"> 25 </option>
                                    <option value="30"> 30 </option>
                                </Input>
                                <Label>
                                    Vencimento
                                </Label>
                                {validation.touched.due_date && validation.errors.due_date ? (
                                    <FormFeedback type="invalid">{validation.errors.due_date}</FormFeedback>
                                ) : null}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mt-3 mb-2">
                        <Col sm="6">
                            {!update ? (
                                <Button type="submit" color="success" disabled={Loading ? true : false}>
                                    Atualizar
                                </Button>
                            ) : null}
                        </Col>
                        <Col xs="6" className="text-right pb-2">
                            <button type="button" className="btn btn-secondary" onClick={toggle} disabled={Loading ? true : false}>Fechar</button>
                        </Col>
                    </Row>
                </Form>
            )}
        </ModalBase>
    );
}

ModalInterviewAdminMembersPayments.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
