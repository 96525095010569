import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Form,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Label,
} from "reactstrap";
import ModalBase from '../../../../../components/modal/modal';
import api from "../../../../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    showToastArray,
} from '../../../../../helpers/generals';
import { formatarMilharReais } from '../../../../../helpers/mascarasParaCampos';

export default function ModalInterviewAdminMembersPaymentsChange({
    modalId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [FormData, setFormData] = useState({});
    const [Loading, setLoading] = useState(false);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            payday: FormData.payday || '',
            amount_paid: FormData.amount_paid || '',
            status: 9
        },
        validationSchema: Yup.object({
            payday: Yup.string().required("Digite um dado."),
            amount_paid: Yup.string().required("Digite um dado."),
        }),
        onSubmit: (values) => {
            setLoading(true);
            api.patch('/api/charges/change/' + modalId, values).then(response => {
                return response.data;
            }).then(data => {
                if (data.success == true) {
                    showToastArray("", "Sucesso", "Pagamento atualizado com sucesso!");
                    setLoading(false);
                    reloadData();
                }
            }).catch(error => {
                if (error) {
                    let message = 'Erro ao enviar o registro!';
                    if (error.response.data.message) {
                        message = error.response.data.message;
                    }
                    showToastArray("error", "Erro!", message);
                    setLoading(false);
                }
            });

        },
    });

    useEffect(() => {

    }, [modalId]);

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={'Pagar | ' + title}
        >
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Row>
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="payday"
                                type="date"
                                onChange={(event) => { setFormData({ ...FormData, 'payday': event.target.value }); }}
                                value={FormData.payday ? FormData.payday : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.payday && validation.errors.payday ? true : false
                                }
                            />
                                {validation.touched.payday && validation.errors.payday ? (
                                    <FormFeedback type="invalid">{validation.errors.payday}</FormFeedback>
                                ) : null}
                            <Label>
                                Data de Pagamento
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="amount_paid"
                                type="text"
                                onChange={(event) => { setFormData({ ...FormData, 'amount_paid': formatarMilharReais(event.target.value) }); }}
                                value={FormData.amount_paid ? FormData.amount_paid : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.amount_paid && validation.errors.amount_paid ? true : false
                                }
                            />
                                {validation.touched.amount_paid && validation.errors.amount_paid ? (
                                    <FormFeedback type="invalid">{validation.errors.amount_paid}</FormFeedback>
                                ) : null}
                            <Label>
                                Valor pago em R$
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mt-3 mb-2">
                    <Col sm="6">
                        <Button type="submit" color="success" disabled={Loading ? true : false}>
                            Atualizar
                        </Button>
                    </Col>
                    <Col xs="6" className="text-right pb-2">
                        <button type="button" className="btn btn-secondary" onClick={toggle} disabled={Loading ? true : false}>Fechar</button>
                    </Col>
                </Row>
            </Form>
        </ModalBase>
    );
}

ModalInterviewAdminMembersPaymentsChange.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
