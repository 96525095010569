import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";

import {
    Row,
    Col,
    Form,
    Badge,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Label,
} from "reactstrap";
import ModalBase from '../../../components/modal/modal';
import api from "../../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    formatarMilharReais,
} from '../../../helpers/mascarasParaCampos';
import {
    showToastArray,
} from '../../../helpers/generals';

export default function ModalBranches({
    modalId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [FormData, setFormData] = useState({});
    const [Loading, setLoading] = useState(false);

    useEffect(() => {
        if (modalId > 0) {
            try {
                api.get('/api/branches/show/' + modalId).then(response => {
                    return response;
                }).then(data => {
                    if (data.data.success) {
                        
                        const formData = { ...FormData };
                        
                        if (data.data.data) {
                            
                            const item = data.data.data;  

                            formData.name = item.name;   
                            formData.is_active = item.is_active;  

                        }

                        setFormData(formData);
                        setLoading(false);
                    
                    } 
                }).catch(error => {
                    // console.log(error);
                });
            } catch (error) {
                // console.log(error);
            }
        }
    }, [modalId]);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: FormData.name || '',
            is_active: FormData.is_active || '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Digite esse campo."),
            is_active: Yup.string().required("Selecione uma opção."),
        }),
        onSubmit: (values) => {
            setLoading(true);
            if (modalId) {
                api.put('/api/branches/update/' + modalId, values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success == true) {
                        showToastArray("", "Sucesso", "Cadastro atualizado com sucesso!");
                        setLoading(false);
                        reloadData();
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });
            } else {
                api.post('/api/branches/save', values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success == true) {
                        showToastArray("", "Sucesso", "Cadastro atualizado com sucesso!");
                        setLoading(false);
                        reloadData();
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });
            }
        },
    });

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={modalId > 0 ? 'Editar | ' + title : 'Adicionar | ' + title}
        >
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Row>

                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="is_active"
                                type="select"
                                placeholder="Situação"
                                onChange={(event) => { setFormData({ ...FormData, 'is_active': event.target.value }); }}
                                value={FormData.is_active ? FormData.is_active : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.is_active && validation.errors.is_active ? true : false
                                }
                            >
                                {validation.touched.is_active && validation.errors.is_active ? (
                                    <FormFeedback type="invalid">{validation.errors.is_active}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                <option value="1"> Ativo </option>
                                <option value="2"> Inativo  </option>
                            </Input>
                            <Label>
                                Situação
                            </Label>
                        </FormGroup>
                    </Col>

                    <Col md={12} className="text-center mt-2">
                        <div className="titulo-hr">
                            <span> Dados do registro </span>
                        </div>
                    </Col>
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="name"
                                type="text"
                                maxLength={255}
                                onChange={(event) => { setFormData({ ...FormData, 'name': event.target.value }); }}
                                value={FormData.name ? FormData.name : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.name && validation.errors.name ? true : false
                                }
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                            ) : null}
                            <Label>
                                Nome
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mt-3 mb-2">
                    <Col xs="6">
                        <Button type="submit" color="success" disabled={Loading ? true : false}>
                            {modalId > 0 ? 'Atualizar' : 'Adicionar'}
                        </Button>
                    </Col>
                    <Col xs="6" className="text-right pb-2">
                        <button type="button" className="btn btn-secondary" onClick={toggle} disabled={Loading ? true : false}>Fechar</button>
                    </Col>
                </Row>
            </Form>
        </ModalBase>
    );
}

ModalBranches.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
