import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";

import {
    Row,
    Col,
    Form,
    Badge,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Label,
    Spinner,
} from "reactstrap";
import ModalBase from '../../../../components/modal/modal';
import api from "../../../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    MaskCellPhone,
    MaskCep,
    MaskCpf,
    MaskCnpj,
    namearMilharReais,
} from '../../../../helpers/mascarasParaCampos';
import {
    showToastArray,
} from '../../../../helpers/generals';

export default function ModalInvitations({
    modalId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {
    const [FormData, setFormData] = useState({});
    const [EventsData, setEventsData] = useState([]);
    const [BranchesData, setBranchesData] = useState([]);

    const [Loading, setLoading] = useState(false);

    const LoadDataShow = async => {
        if (modalId > 0) {
            try {
                api.get('/api/invitations/show/' + modalId).then(response => {
                    return response;
                }).then(data => {
                    setFormData(data.data.data);
                    setLoading(false);
                }).catch(error => {
                    // console.log(error);
                });
            } catch (error) {
                // console.log(error);
            }
        }
    };

    const LoadEvents = async => {
        setEventsData([]);
        api.get('/api/events/active').then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setEventsData(data.data);
                setLoading(false);
            }
        }).catch(error => {
            // console.log(error);
        })
    };

    function LoadBranches() {
        setBranchesData([]);
        api.get('/api/branches/active').then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setBranchesData(data.data);
            }
        }).catch(error => {
            // console.log(error);
        })
    };


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            events_id: FormData.events_id || '',
            name: FormData.name || '',
            cpf: FormData.cpf || '',
            email: FormData.email || '',
            cell_phone: FormData.cell_phone || '',
            companie_name: FormData.companie_name || '',
            branches_id: FormData.branches_id || '',
        },
        validationSchema: Yup.object({
            events_id: Yup.string().required("Selecione uma opção."),
            name: Yup.string().required("Digite esse campo."),
            // cpf: Yup.string().required("Digite esse campo."),
            email: Yup.string().required("Digite esse campo."),
            cell_phone: Yup.string().required("Digite esse campo."),
            companie_name: Yup.string().required("Digite esse campo."),
            branches_id: Yup.string().required("Selecione uma opção."),
        }),
        onSubmit: (values) => {
            // console.log('values', values);
            setLoading(true);
            if (modalId > 0) {
                api.put('/api/invitations/update/' + modalId, values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success == true) {
                        showToastArray("", "Sucesso", "Sua solicitação foi atualizada com sucesso.");
                        setLoading(false);
                        reloadData();
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });
            } else {
                api.post('/api/invitations/save', values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success == true) {
                        showToastArray("", "Sucesso", "Sua solicitação foi enviada com sucesso.");
                        setLoading(false);
                        reloadData();
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });
            }
        },
    });

    useEffect(() => {
        LoadBranches();
        LoadEvents();
        if (modalId > 0) {
            LoadDataShow();
        }
    }, []);

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={title}
        >
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Row>
                    <Col sm="12">
                        <Row>

                            <Col sm="12">
                                <FormGroup floating>
                                    <Input
                                        name="events_id"
                                        type="select"
                                        onChange={(event) => { setFormData({ ...FormData, 'events_id': event.target.value }); }}
                                        value={FormData.events_id ? FormData.events_id : ''}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.events_id && validation.errors.events_id ? true : false
                                        }
                                    >
                                        <option value=""> Selecione... </option>
                                        {EventsData.map((row, index) => (
                                            <option key={index} value={row.id}>{row.name}</option>
                                        ))}
                                    </Input>
                                    {validation.touched.events_id && validation.errors.events_id ? (
                                        <FormFeedback type="invalid">{validation.errors.events_id}</FormFeedback>
                                    ) : null}
                                    <Label>
                                        Evento
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup floating>
                                    <Input
                                        name="name"
                                        type="text"
                                        autoComplete={false}
                                        maxLength={255}
                                        onChange={(event) => { setFormData({ ...FormData, 'name': event.target.value }); }}
                                        value={FormData.name ? FormData.name : ''}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.name && validation.errors.name ? true : false
                                        }
                                    />
                                    {validation.touched.name && validation.errors.name ? (
                                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                    ) : null}
                                    <Label>
                                        Nome completo
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup floating>
                                    <Input
                                        autoComplete={false}
                                        name="cpf"
                                        type="text"
                                        maxLength={255}
                                        onChange={(event) => { setFormData({ ...FormData, 'cpf': MaskCpf(event.target.value) }); }}
                                        value={FormData.cpf ? FormData.cpf : ''}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.cpf && validation.errors.cpf ? true : false
                                        }
                                    />
                                    {validation.touched.cpf && validation.errors.cpf ? (
                                        <FormFeedback type="invalid">{validation.errors.cpf}</FormFeedback>
                                    ) : null}
                                    <Label>
                                        CPF
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup floating>
                                    <Input
                                        autoComplete={false}
                                        name="email"
                                        type="email"
                                        maxLength={255}
                                        onChange={(event) => { setFormData({ ...FormData, 'email': event.target.value }); }}
                                        value={FormData.email ? FormData.email : ''}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.email && validation.errors.email ? true : false
                                        }
                                    />
                                    {validation.touched.email && validation.errors.email ? (
                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                    ) : null}
                                    <Label>
                                        E-mail
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup floating>
                                    <Input
                                        autoComplete={false}
                                        name="cell_phone"
                                        type="text"
                                        maxLength={255}
                                        onChange={(event) => { setFormData({ ...FormData, 'cell_phone': MaskCellPhone(event.target.value) }); }}
                                        value={FormData.cell_phone ? FormData.cell_phone : ''}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.cell_phone && validation.errors.cell_phone ? true : false
                                        }
                                    />
                                    {validation.touched.cell_phone && validation.errors.cell_phone ? (
                                        <FormFeedback type="invalid">{validation.errors.cell_phone}</FormFeedback>
                                    ) : null}
                                    <Label>
                                        Celular
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup floating>
                                    <Input
                                        autoComplete={false}
                                        name="companie_name"
                                        type="text"
                                        maxLength={255}
                                        onChange={(event) => { setFormData({ ...FormData, 'companie_name': event.target.value }); }}
                                        value={FormData.companie_name ? FormData.companie_name : ''}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.companie_name && validation.errors.companie_name ? true : false
                                        }
                                    />
                                    {validation.touched.companie_name && validation.errors.companie_name ? (
                                        <FormFeedback type="invalid">{validation.errors.companie_name}</FormFeedback>
                                    ) : null}
                                    <Label>
                                        Empresa
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col lg={12}>
                                <FormGroup floating>
                                    <Input
                                        id="branches_id"
                                        className="branches_id"
                                        name="branches_id"
                                        type="select"
                                        onChange={(event) => {
                                            setFormData({ ...FormData, 'branches_id': event.target.value });
                                        }}
                                        value={FormData.branches_id ? FormData.branches_id : ''}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.branches_id && validation.errors.branches_id ? true : false
                                        }
                                    >
                                        {validation.touched.branches_id && validation.errors.branches_id ? (
                                            <FormFeedback type="invalid">{validation.errors.branches_id}</FormFeedback>
                                        ) : null}
                                        <option value=""> Selecione... </option>
                                        {BranchesData.map((row, index) => (
                                            <option key={index} value={row.id}>{row.name}</option>
                                        ))}
                                    </Input>
                                    <Label>
                                        Ramo
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-3 mb-2">
                    <Col sm="6">
                        {Loading ? (
                            <Spinner />
                        ) : (
                            <Button type="submit" color="success" disabled={Loading ? true : false}>
                                Agendar
                            </Button>
                        )}
                    </Col>
                    <Col xs="6" className="text-right pb-2">
                        <button type="button" className="btn btn-secondary" onClick={toggle} disabled={Loading ? true : false}>Fechar</button>
                    </Col>
                </Row>
            </Form>
        </ModalBase>
    );
}

ModalInvitations.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
