import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Input,
    FormGroup,
    Label,
} from "reactstrap";
import ModalBase from '../../../../../components/modal/modal';
import api from "../../../../../services/api";

export default function ModalScheduleMeetingsVer({
    modalId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [FormData, setFormData] = useState({});
    const [Loading, setLoading] = useState(true);

    const LoadDataShow = async => {
        setFormData({});
        if (modalId > 0) {
            try {
                api.get('/api/meetings/show/' + modalId).then(response => {
                    return response;
                }).then(data => {
                    setFormData(data.data.data);
                    setLoading(false);
                }).catch(error => {
                    // console.log(error);
                });
            } catch (error) {
                // console.log(error);
            }
        }
    };

    useEffect(() => {
        LoadDataShow();
    }, [modalId]);

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={title}
        >
            {/* modalId : {modalId} */}
            <Row>
                <Col sm="4">
                    <Row>
                        <Col md={12} className="text-center mt-2">
                            <div className="titulo-hr">
                                <span>Dados do solicitado</span>
                            </div>
                        </Col>
                        <Col sm="12">
                            <FormGroup floating>
                                <Input
                                    name="name"
                                    type="text"
                                    value={FormData.users_meeting ? FormData.users_meeting.name : ''}
                                    disabled
                                />
                                <Label>
                                    Nome
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm="12">
                            <FormGroup floating>
                                <Input
                                    name="text"
                                    type="text"
                                    value={FormData.users_meeting ? FormData.users_meeting.email : ''}
                                    disabled
                                />
                                <Label>
                                    Email
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm="12">
                            <FormGroup floating>
                                <Input
                                    name="cell_phone"
                                    type="text"
                                    value={FormData.users_meeting ? FormData.users_meeting.cell_phone : ''}
                                    disabled
                                />
                                <Label>
                                    Celular
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm="12">
                            <FormGroup floating>
                                <Input
                                    name="companie_name"
                                    type="text"
                                    value={FormData.users_meeting ? FormData.users_meeting.companie_name : ''}
                                    disabled
                                />
                                <Label>
                                    Empresa
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm="12">
                            <FormGroup floating>
                                <Input
                                    name="branches"
                                    type="text"
                                    value={FormData.users_meeting ? FormData.users_meeting.branches.name : ''}
                                    disabled
                                />
                                <Label>
                                    Ramo
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm="12">
                            <FormGroup floating>
                                <Input
                                    name="interesse"
                                    type="text"
                                    value={FormData.users_meeting ? FormData.users_meeting.list_partnerships_clients : ''}
                                    disabled
                                />
                                <Label>
                                    Interesse(s)
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="text-center ">
                            <div className="titulo-hr">
                                <span>Disponibilidade do solicitado</span>
                            </div>
                        </Col>
                        <Col sm="12" className="mb-4">

                            <Row>
                                <Col sm="12" className="mb-2">
                                    Segunda-feira
                                </Col>
                                <Col sm="12">
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup floating>
                                                <Input
                                                    type="text"
                                                    value={FormData.users_meeting ? FormData.users_meeting.time_i_1 : ''}
                                                    disabled
                                                />
                                                <Label>
                                                    Horário Inicial
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup floating>
                                                <Input
                                                    autoComplete={false}
                                                    type="text"
                                                    value={FormData.users_meeting ? FormData.users_meeting.time_f_1 : ''}
                                                    disabled
                                                />
                                                <Label>
                                                    Horário Final
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>

                                <Col sm="12" className="mb-2">
                                    Terça-feira
                                </Col>
                                <Col sm="12">
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup floating>
                                                <Input
                                                    autoComplete={false}
                                                    type="text"
                                                    value={FormData.users_meeting ? FormData.users_meeting.time_i_2 : ''}
                                                    disabled
                                                />
                                                <Label>
                                                    Horário Inicial
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup floating>
                                                <Input
                                                    autoComplete={false}
                                                    type="text"
                                                    value={FormData.users_meeting ? FormData.users_meeting.time_f_2 : ''}
                                                    disabled
                                                />
                                                <Label>
                                                    Horário Final
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>

                                <Col sm="12" className="mb-1">
                                    Quarta-feira
                                </Col>
                                <Col sm="12">
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup floating>
                                                <Input
                                                    autoComplete={false}
                                                    type="text"
                                                    value={FormData.users_meeting ? FormData.users_meeting.time_i_3 : ''}
                                                    disabled
                                                />
                                                <Label>
                                                    Horário Inicial
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup floating>
                                                <Input
                                                    autoComplete={false}
                                                    type="text"
                                                    value={FormData.users_meeting ? FormData.users_meeting.time_f_3 : ''}
                                                    disabled
                                                />
                                                <Label>
                                                    Horário Final
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" className="mb-1">
                                    Quinta-feira
                                </Col>
                                <Col sm="12">
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup floating>
                                                <Input
                                                    autoComplete={false}
                                                    type="text"
                                                    value={FormData.users_meeting ? FormData.users_meeting.time_i_4 : ''}
                                                    disabled
                                                />
                                                <Label>
                                                    Horário Inicial
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup floating>
                                                <Input
                                                    autoComplete={false}
                                                    type="text"
                                                    value={FormData.users_meeting ? FormData.users_meeting.time_f_4 : ''}
                                                    disabled
                                                />
                                                <Label>
                                                    Horário Final
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" className="mb-1">
                                    Sexta-feira
                                </Col>
                                <Col sm="12">
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup floating>
                                                <Input
                                                    autoComplete={false}
                                                    type="text"
                                                    value={FormData.users_meeting ? FormData.users_meeting.time_i_5 : ''}
                                                    disabled
                                                />
                                                <Label>
                                                    Horário Inicial
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup floating>
                                                <Input
                                                    autoComplete={false}
                                                    type="text"
                                                    value={FormData.users_meeting ? FormData.users_meeting.time_f_5 : ''}
                                                    disabled
                                                />
                                                <Label>
                                                    Horário Final
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </Col>
                    </Row>                    
                </Col>
                <Col sm="4">
                    <Row>
                        <Col md={12} className="text-center mt-2">
                            <div className="titulo-hr">
                                <span>Dados do solicitante</span>
                            </div>
                        </Col>
                        <Col sm="12">
                            <FormGroup floating>
                                <Input
                                    name="name"
                                    type="text"
                                    value={FormData.users ? FormData.users.name : ''}
                                    disabled
                                />
                                <Label>
                                    Nome
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm="12">
                            <FormGroup floating>
                                <Input
                                    name="text"
                                    type="text"
                                    value={FormData.users ? FormData.users.email : ''}
                                    disabled
                                />
                                <Label>
                                    Email
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm="12">
                            <FormGroup floating>
                                <Input
                                    name="cell_phone"
                                    type="text"
                                    value={FormData.users ? FormData.users.cell_phone : ''}
                                    disabled
                                />
                                <Label>
                                    Celular
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm="12">
                            <FormGroup floating>
                                <Input
                                    name="companie_name"
                                    type="text"
                                    value={FormData.users ? FormData.users.companie_name : ''}
                                    disabled
                                />
                                <Label>
                                    Empresa
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm="12">
                            <FormGroup floating>
                                <Input
                                    name="branches"
                                    type="text"
                                    value={FormData.users ? FormData.users.branches.name : ''}
                                    disabled
                                />
                                <Label>
                                    Ramo
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm="12">
                            <FormGroup floating>
                                <Input
                                    name="interesse"
                                    type="text"
                                    value={FormData.users ? FormData.users.list_partnerships_clients : ''}
                                    disabled
                                />
                                <Label>
                                    Interesse(s)
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="text-center ">
                            <div className="titulo-hr">
                                <span>Disponibilidade do solicitante</span>
                            </div>
                        </Col>
                        <Col sm="12" className="mb-4">

                            <Row>
                                <Col sm="12" className="mb-2">
                                    Segunda-feira
                                </Col>
                                <Col sm="12">
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup floating>
                                                <Input
                                                    type="text"
                                                    value={FormData.users ? FormData.users.time_i_1 : ''}
                                                    disabled
                                                />
                                                <Label>
                                                    Horário Inicial
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup floating>
                                                <Input
                                                    autoComplete={false}
                                                    type="text"
                                                    value={FormData.users ? FormData.users.time_f_1 : ''}
                                                    disabled
                                                />
                                                <Label>
                                                    Horário Final
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>

                                <Col sm="12" className="mb-2">
                                    Terça-feira
                                </Col>
                                <Col sm="12">
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup floating>
                                                <Input
                                                    autoComplete={false}
                                                    type="text"
                                                    value={FormData.users ? FormData.users.time_i_2 : ''}
                                                    disabled
                                                />
                                                <Label>
                                                    Horário Inicial
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup floating>
                                                <Input
                                                    autoComplete={false}
                                                    type="text"
                                                    value={FormData.users ? FormData.users.time_f_2 : ''}
                                                    disabled
                                                />
                                                <Label>
                                                    Horário Final
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>

                                <Col sm="12" className="mb-1">
                                    Quarta-feira
                                </Col>
                                <Col sm="12">
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup floating>
                                                <Input
                                                    autoComplete={false}
                                                    type="text"
                                                    value={FormData.users ? FormData.users.time_i_3 : ''}
                                                    disabled
                                                />
                                                <Label>
                                                    Horário Inicial
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup floating>
                                                <Input
                                                    autoComplete={false}
                                                    type="text"
                                                    value={FormData.users ? FormData.users.time_f_3 : ''}
                                                    disabled
                                                />
                                                <Label>
                                                    Horário Final
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" className="mb-1">
                                    Quinta-feira
                                </Col>
                                <Col sm="12">
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup floating>
                                                <Input
                                                    autoComplete={false}
                                                    type="text"
                                                    value={FormData.users ? FormData.users.time_i_4 : ''}
                                                    disabled
                                                />
                                                <Label>
                                                    Horário Inicial
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup floating>
                                                <Input
                                                    autoComplete={false}
                                                    type="text"
                                                    value={FormData.users ? FormData.users.time_f_4 : ''}
                                                    disabled
                                                />
                                                <Label>
                                                    Horário Final
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" className="mb-1">
                                    Sexta-feira
                                </Col>
                                <Col sm="12">
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup floating>
                                                <Input
                                                    autoComplete={false}
                                                    type="text"
                                                    value={FormData.users ? FormData.users.time_i_5 : ''}
                                                    disabled
                                                />
                                                <Label>
                                                    Horário Inicial
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup floating>
                                                <Input
                                                    autoComplete={false}
                                                    type="text"
                                                    value={FormData.users ? FormData.users.time_f_5 : ''}
                                                    disabled
                                                />
                                                <Label>
                                                    Horário Final
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </Col>
                    </Row> 
                </Col>
                <Col sm="4">
                    <Row>
                        <Col md={12} className="text-center mt-2">
                            <div className="titulo-hr">
                                <span>Dados da reunião</span>
                            </div>
                        </Col>
                        <Col sm="6">
                            <FormGroup floating>
                                <Input
                                    autoComplete={false}
                                    type="text"
                                    value={FormData.date ? FormData.date : ''}
                                    disabled
                                />
                                <Label>
                                    Data da reunião
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm="6">
                            <FormGroup floating>
                                <Input
                                    autoComplete={false}
                                    type="text"
                                    disabled
                                    value={FormData.time ? FormData.time : ''}
                                />
                                <Label>
                                    Horário da reunião
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm="12">
                            <FormGroup floating>
                                <Input
                                    autoComplete={false}
                                    type="text"
                                    disabled
                                    value={FormData.duration ? FormData.duration : ''}
                                />
                                <Label>
                                    Duração
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm="12">
                            <FormGroup floating>
                                <Input
                                    autoComplete={false}
                                    type="text"
                                    disabled
                                    value={FormData.format ? FormData.format : ''}
                                />
                                <Label>
                                    Formato
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm="12">
                            <FormGroup floating>
                                <Input
                                    autoComplete={false}
                                    name="subject"
                                    type="text"
                                    value={FormData.subject ? FormData.subject : ''}
                                    disabled
                                />
                                <Label>
                                    Assunto da reunião
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm="12">
                            <Label style={{ fontWeight: '700' }}>
                                Objetivo da reunião
                            </Label>
                            <div style={{ width: '100%', background: '#fff', padding: '14px 14px 0px 14px', border: '1px solid #f1f1f1', borderRadius: '6px' }}>
                                <p dangerouslySetInnerHTML={{ __html: FormData.description }} style={{ padding: '0px' }}></p>
                            </div>
                        </Col>
                        {FormData.note ? (
                            <Col sm="12">
                                <FormGroup floating>
                                    <Input
                                        className=""
                                        name="note"
                                        type="textarea"
                                        disabled
                                        style={{ height: '120px' }}
                                        value={FormData.note ? FormData.note : ''}
                                    />
                                    <Label>
                                        Motivo
                                    </Label>
                                </FormGroup>
                            </Col>
                        ) : ''}
                    </Row>
                </Col>
            </Row>

            <Row className="mt-3 mb-2">
                <Col sm="12" className="text-right pb-2">
                    <button type="button" className="btn btn-secondary" onClick={toggle} disabled={Loading ? true : false}>Fechar</button>
                </Col>
            </Row>

        </ModalBase>
    );
}

ModalScheduleMeetingsVer.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
