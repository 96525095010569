import React from "react";
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import { Link } from 'react-router-dom';
import Logo from '../../../assets/images/logo.png';
import ModalAddAdminMembersForm from "../../admin/members/members/modal/form";

const NewMember = props => {

    document.title = "Novo cadastro de membro | Empresários SA";

    function reloadData() {

    }

    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={12} lg={12} xl={12}>
                            <Card className="overflow-hidden">
                                <div className="bg-softbg-soft-primary">
                                    <Row>
                                        <Col xs={12} className="mb-1 mt-5 text-center">
                                            <img src={Logo} alt="" height="40px" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div className="p-2 pt-5">

                                        <div className="mb-3">
                                            <Row>
                                                <Col sm="12">
                                                    <h4 className="pb-3">Dados do membro</h4>
                                                    <ModalAddAdminMembersForm
                                                        reloadData={reloadData}
                                                        blockInputs={false}
                                                        toggle2={reloadData}
                                                        textButtonSubmit="Cadastrar"
                                                        disabledBtnClosed="Y"
                                                        disabledInputSituation="Y"
                                                    />
                                                </Col>
                                            </Row>
                                        </div>

                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>
                                    Voltar para tela de{" "}
                                    <Link to="login" className="font-weight-medium text-primary">
                                        Login
                                    </Link>{" "}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default NewMember;