import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CkeditorOptionsEditorConfiguration } from '../../../../../components/ckeditor-options';
import {
    Row,
    Col,
    Form,
    FormGroup,
    Button,
    FormFeedback,
    Label,
} from "reactstrap";
import ModalBase from '../../../../../components/modal/modal';
import * as Yup from "yup";
import { useFormik } from "formik";
import api from "../../../../../services/api";
import {
    showToastArray,
} from '../../../../../helpers/generals';

export default function ModalScheduleMeetingsStatus({
    modalId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
    statusValue,
    text,
    is_note
}) {

    const [FormData, setFormData] = useState({});
    const [Loading, setLoading] = useState(false);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            status: statusValue || '',
            note: FormData.note || '',
        },
        validationSchema: Yup.object({
            status: Yup.string().required("."),
        }),
        onSubmit: (values) => {
            // console.log('values', values);
            setLoading(true);
            if (modalId > 0) {
                api.put('/api/meetings/update/' + modalId, values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success == true) {
                        showToastArray("", "Sucesso", "Sua solicitação de agenda foi atualizada com sucesso.");
                        setLoading(false);
                        reloadData();
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });
            }
        },
    });

    useEffect(() => {

    }, []);

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={title}
        >
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            > 
                <Row>
                    <Col sm="12" className="text-center pt-4 pb-4">
                        <h6 style={{ color: 'red' }}>{text}</h6>
                    </Col>
                    {is_note ? (
                        <Col sm="12">
                            <FormGroup>
                                <Label>
                                    Motivo
                                </Label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={CkeditorOptionsEditorConfiguration}
                                    data={FormData.note ? FormData.note : ''}
                                    onReady={editor => {
                                        // Allows you to store the editor instance and use it later.
                                        // // console.log('11', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        // // console.log({ event, editor, data });
                                        setFormData({ ...FormData, 'note': (data) });
                                    }}
                                    onBlur={(event, editor) => {
                                        // // console.log('22', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        // // console.log('333', editor);
                                    }}
                                />
                                {validation.touched.note && validation.errors.note ? (
                                    <FormFeedback type="invalid">{validation.errors.note}</FormFeedback>
                                ) : null}
                            </FormGroup>  
                        </Col>
                    ) : ''}
                </Row>
                <Row className="mt-3 mb-2">
                    <Col xs="6">
                        <Button type="submit" color="success" disabled={Loading ? true : false}>
                            Sim
                        </Button>
                    </Col>
                    <Col sm="6" className="text-right pb-2">
                        <button type="button" className="btn btn-secondary" onClick={toggle} disabled={Loading ? true : false}>Não</button>
                    </Col>
                </Row>
            </Form>
        </ModalBase>
    );
}

ModalScheduleMeetingsStatus.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    statusValue: PropTypes.number,
    text: PropTypes.string.isRequired,
    is_note: PropTypes.bool.isRequired,
}
