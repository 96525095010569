import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CkeditorOptionsEditorConfiguration } from '../../../components/ckeditor-options';
import {
    Row,
    Col,
    Form,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Label,
} from "reactstrap";
import ModalBase from '../../../components/modal/modal';
import api from "../../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    formatarMilharReais,
} from '../../../helpers/mascarasParaCampos';
import {
    showToastArray,
} from '../../../helpers/generals';

export default function ModalPlans({
    modalId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [FormData, setFormData] = useState({});
    const [Loading, setLoading] = useState(false);
    const [Desc, setDesc] = useState();

    const LoadDataShow = async => {
        if (modalId > 0) {
            try {
                api.get('/api/plans/show/' + modalId).then(response => {
                    return response;
                }).then(data => {
                    if (data.data) {
                        
                        const formData = { ...FormData };
                        
                        if (data.data.data) {
                            
                            const item = data.data.data;  

                            formData.name = item.name;     
                            formData.is_active = item.is_active;  

                            if (item.value_month) formData.value_month = formatarMilharReais(item.value_month);   
                            if (item.value_quarterly) formData.value_quarterly = formatarMilharReais(item.value_quarterly);   
                            if (item.value_semester) formData.value_semester = formatarMilharReais(item.value_semester);   
                            if (item.value_year) formData.value_year = formatarMilharReais(item.value_year);   

                            setDesc(item.description);
                        }
                        setFormData(formData);
                        setLoading(false);
                    }                       
                }).catch(error => {
                    // console.log(error);
                });
            } catch (error) {
                // console.log(error);
            }
        }
    };

    useEffect(() => {
        LoadDataShow();
    }, []);  

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: FormData.name || '',
            description: Desc || '',
            value_month: FormData.value_month || '',
            value_quarterly: FormData.value_quarterly || '',
            value_semester: FormData.value_semester || '',
            value_year: FormData.value_year || '',
            is_active: FormData.is_active || '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Digite esse campo."),
            description: Yup.string().required("Digite esse campo."),

            value_month: Yup.string().when('is_active', {
                is: (is_active) => !FormData.value_quarterly && !FormData.value_semester && !FormData.value_year,
                then: Yup.string().required("Digite esse campo."),
                otherwise: Yup.string(),
            }),  
            value_quarterly: Yup.string().when('is_active', {
                is: (is_active) => !FormData.value_month && !FormData.value_semester && !FormData.value_year,
                then: Yup.string().required("Digite esse campo."),
                otherwise: Yup.string(),
            }),  
            value_semester: Yup.string().when('is_active', {
                is: (is_active) => !FormData.value_month && !FormData.value_quarterly && !FormData.value_year,
                then: Yup.string().required("Digite esse campo."),
                otherwise: Yup.string(),
            }),  
            value_year: Yup.string().when('is_active', {
                is: (is_active) => !FormData.value_month && !FormData.value_quarterly && !FormData.value_semester,
                then: Yup.string().required("Digite esse campo."),
                otherwise: Yup.string(),
            }),                                                  
            is_active: Yup.string().required("Selecione uma opção."),
        }),
        onSubmit: (values) => {
            setLoading(true);
            if (modalId) {
                api.put('/api/plans/update/' + modalId, values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success == true) {
                        showToastArray("", "Sucesso", "Cadastro atualizado com sucesso!");
                        setLoading(false);
                        reloadData();
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });
            } else {
                api.post('/api/plans/save', values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success == true) {
                        showToastArray("", "Sucesso", "Cadastro atualizado com sucesso!");
                        setLoading(false);
                        reloadData();
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });
            }
        },
    });

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={modalId > 0 ? 'Editar | ' + title : 'Adicionar | ' + title}
        >
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Row>

                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="is_active"
                                type="select"
                                placeholder="Situação"
                                onChange={(event) => { setFormData({ ...FormData, 'is_active': event.target.value }); }}
                                value={FormData.is_active ? FormData.is_active : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.is_active && validation.errors.is_active ? true : false
                                }
                            >
                                {validation.touched.is_active && validation.errors.is_active ? (
                                    <FormFeedback type="invalid">{validation.errors.is_active}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                <option value="1"> Ativo </option>
                                <option value="2"> Inativo  </option>
                            </Input>
                            <Label>
                                Situação
                            </Label>
                        </FormGroup>
                    </Col>

                    <Col md={12} className="text-center mt-2">
                        <div className="titulo-hr">
                            <span> Dados do registro </span>
                        </div>
                    </Col>
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="name"
                                type="text"
                                maxLength={255}
                                onChange={(event) => { setFormData({ ...FormData, 'name': event.target.value }); }}
                                value={FormData.name ? FormData.name : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.name && validation.errors.name ? true : false
                                }
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                            ) : null}
                            <Label>
                                Nome
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="3">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                disabled={ 
                                    (FormData.value_quarterly || FormData.value_semester || FormData.value_year) 
                                    && 
                                    (FormData.value_quarterly !== '0,00' && FormData.value_semester !== '0,00' && FormData.value_year !== '0,00') 
                                    ? true : false
                                }                                   
                                name="value_month"
                                type="text"
                                maxLength={255}
                                onChange={(event) => { 
                                    let value = formatarMilharReais(event.target.value)
                                    if (value === '0,00') value = '';
                                    setFormData({ ...FormData, 'value_month': value }); 
                                
                                }}                                    
                                value={FormData.value_month ? FormData.value_month : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.value_month && validation.errors.value_month ? true : false
                                }
                            />
                            {validation.touched.value_month && validation.errors.value_month ? (
                                <FormFeedback type="invalid">{validation.errors.value_month}</FormFeedback>
                            ) : null}
                            <Label>
                                Valor Mensal
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="3">
                        <FormGroup floating>
                            <Input
                                className=""
                                disabled={ 
                                    (FormData.value_month || FormData.value_semester || FormData.value_year) 
                                    && 
                                    (FormData.value_month !== '0,00' && FormData.value_semester !== '0,00' && FormData.value_year !== '0,00') 
                                    ? true : false
                                }                                
                                autoComplete={false}
                                name="value_quarterly"
                                type="text"
                                maxLength={255}
                                onChange={(event) => { 
                                    let value = formatarMilharReais(event.target.value)
                                    if (value === '0,00') value = '';
                                    setFormData({ ...FormData, 'value_quarterly': value }); 
                                
                                }}                                   
                                value={FormData.value_quarterly ? FormData.value_quarterly : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.value_quarterly && validation.errors.value_quarterly ? true : false
                                }
                            />
                            {validation.touched.value_quarterly && validation.errors.value_quarterly ? (
                                <FormFeedback type="invalid">{validation.errors.value_quarterly}</FormFeedback>
                            ) : null}
                            <Label>
                                Valor Trimestral
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="3">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}  
                                disabled={ 
                                    (FormData.value_month || FormData.value_quarterly || FormData.value_year) 
                                    && 
                                    (FormData.value_month !== '0,00' && FormData.value_quarterly !== '0,00' && FormData.value_year !== '0,00') 
                                    ? true : false
                                }                                                               
                                name="value_semester"
                                type="text"
                                maxLength={255}
                                onChange={(event) => { 
                                    let value = formatarMilharReais(event.target.value)
                                    if (value === '0,00') value = '';
                                    setFormData({ ...FormData, 'value_semester': value }); 
                                
                                }}                                
                                value={FormData.value_semester ? FormData.value_semester : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.value_semester && validation.errors.value_semester ? true : false
                                }
                            />
                            {validation.touched.value_semester && validation.errors.value_semester ? (
                                <FormFeedback type="invalid">{validation.errors.value_semester}</FormFeedback>
                            ) : null}
                            <Label>
                                Valor Semestral
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="3">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                disabled={ 
                                    (FormData.value_month || FormData.value_quarterly || FormData.value_semester) 
                                    && 
                                    (FormData.value_month !== '0,00' && FormData.value_quarterly !== '0,00' && FormData.value_semester !== '0,00') 
                                    ? true : false
                                }                                    
                                name="value_year"
                                type="text"
                                maxLength={255}
                                onChange={(event) => { 
                                    let value = formatarMilharReais(event.target.value)
                                    if (value === '0,00') value = '';
                                    setFormData({ ...FormData, 'value_year': value }); 
                                
                                }}
                                value={FormData.value_year ? FormData.value_year : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.value_year && validation.errors.value_year ? true : false
                                }
                            />
                            {validation.touched.value_year && validation.errors.value_year ? (
                                <FormFeedback type="invalid">{validation.errors.value_year}</FormFeedback>
                            ) : null}
                            <Label>
                                Valor Anual
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <FormGroup>
                            <Label>
                                Descrição
                            </Label>
                            <CKEditor
                                editor={ClassicEditor}
                                config={CkeditorOptionsEditorConfiguration}
                                data={Desc ? Desc : null}
                                onReady={editor => {
                                    // Allows you to store the editor instance and use it later.
                                    // const data = editor.getData();
                                    // // console.log({ editor, data });
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    // // console.log({ event, editor, data });
                                    setDesc(data);
                                }}
                                onBlur={(event, editor) => {
                                    // // console.log('22', editor);
                                }}
                                onFocus={(event, editor) => {
                                    // // console.log('333', editor);
                                }}
                            />
                            {validation.touched.description && validation.errors.description ? (
                                <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>                    
                </Row>
                <Row className="mt-3 mb-2">
                    <Col xs="6">
                        <Button type="submit" color="success" disabled={Loading ? true : false}>
                            {modalId > 0 ? 'Atualizar' : 'Adicionar'}
                        </Button>
                    </Col>
                    <Col xs="6" className="text-right pb-2">
                        <button type="button" className="btn btn-secondary" onClick={toggle} disabled={Loading ? true : false}>Fechar</button>
                    </Col>
                </Row>
            </Form>
        </ModalBase>
    );
}

ModalPlans.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
