import React, { useState, useEffect } from "react";
import {
  useNavigate,
} from 'react-router-dom';
import api from "../../../services/api";
import {
  Row,
  Col,
  Container,
  Button,
  Table,
  Spinner,
  Badge,
  FormGroup,
  Input,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ContentLoader from "react-content-loader";
import LayoutHeader from "../../../components/header";
import Modalclasses from "./modal";
import {
  DateToData,
  formatDataToDate, formatarMilharReais
} from '../../../helpers/mascarasParaCampos';
import SwalDeleteItem from "../../../helpers/swal";

const Marketingclasses = props => {

  const navigate = useNavigate();

  let NameModule = 'Aulas';

  document.title = NameModule + " | Empresários SA";

  const [Loading, setLoading] = useState(true);
  const [RowData, setRowData] = useState([]);

  const [FormData, setFormData] = useState({});

  const [Pages, setPages] = useState([]);
  const [PaginationTotalItems, setPaginationTotalItems] = useState(1);
  const [PaginationPageActual, setPaginationPageActual] = useState(1);

  const [DeleteOpen, setDeleteOpen] = useState(false);
  const [ModalOpen, setModalOpen] = useState(false);
  const [ModalId, setModalId] = useState(0);

  function LoadData(Page) {

    setLoading(true);
    setRowData([]);

    try {
      const Data = {
        "search": FormData.search || '',
      }
      setPaginationPageActual(Page);
      api.post('/api/classes/filter?page=' + Page, Data).then(response => {
        return response;
      }).then(data => {
        setRowData(data.data);
        setPaginationTotalItems(data.data.total);
        listItems(data.data.total, data.data.per_page);
        setLoading(false);
      }).catch(error => {
        // console.log(error);
      });
    } catch (error) {
      // console.log(error);
    }
  }

  useEffect(() => {
    LoadData(1);
  }, []);

  function openModal() {
    setModalOpen(true);
    setModalId(0);
  }

  function reloadData() {
    // console.log('reloadData')
    LoadData(1);
    setModalOpen(false);
    setDeleteOpen(false);
    setModalId(0);
  }

  function listItems(total, PorPage) {
    let totalPages = 1;
    let asdasdsa = [];
    if (PorPage < total) {
      totalPages = total / PorPage;
    }
    for (let a = 1; a < totalPages + 1; a++) {
      asdasdsa.push([a]);
    }
    setPages(asdasdsa);
  }

  function PaginationOpen(Page) {
    LoadData(Page);
  }

  return (
    <React.Fragment>

      <LayoutHeader isAdmin={true} show={true} />

      {ModalOpen ? (<Modalclasses
        toggle={() => setModalOpen(!ModalOpen)}
        isOpen={ModalOpen}
        modalId={ModalId}
        size="xl"
        title={NameModule}
        reloadData={reloadData}
      />) : ''}

      {DeleteOpen ? (<SwalDeleteItem
        PathApi="/api/classes/delete/"
        DeleteId={ModalId}
        title="Deletar"
        text="Você tem certeza que deseja deletar este dado?"
        isConfirmedTitle="Sucesso!"
        isConfirmedText="Dado deletado com sucesso."
        reloadData={reloadData}
      />) : ''}

      <div className="px-3 pt-1 pb-5">
        <Row className="pt-3910201290">

          <Col md={12} className="content-box">
            <Row>
              <Col md={12} className="text-center">
                <div className="titulo-hr">
                  <span>{NameModule}</span>
                </div>
              </Col>

              <Col lg={12}>
                <Row>
                  <Col lg={10}>
                    <FormGroup floating>
                      <Input
                        id="search"
                        className="search"
                        name="search"
                        type="search"
                        placeholder="Especilidade"
                        onChange={(event) => { setFormData({ ...FormData, 'search': event.target.value }); }}
                        value={FormData.search ? FormData.search : ''}
                      />
                      <Label>
                        Buscar
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg={1} className="text-center">
                    <Button
                      className="btnFilter"
                      onClick={() => {
                        LoadData();
                      }}
                    >
                      Filtrar
                    </Button>
                  </Col>
                  <Col lg={1} className="text-center">
                    <Button
                      className="btnFilter"
                      color="success"
                      onClick={() => {
                        openModal();
                      }}
                    >
                      Adicionar
                    </Button>
                  </Col>
                </Row>
              </Col>

            </Row>
          </Col>
          <Col md={12} className="content-box">
            <Row className="mb-3">
              <Col md={12}>
                <div className="boxes">
                  <Table responsive size="sm" className="table">
                    <thead>
                      <th width="1%"></th>
                      <th width="10%"> Data </th>
                      <th> Nome </th>
                      <th width="10%"> Vídeo </th>
                      <th width="10%"> Thumb </th>
                      <th> </th>
                      <th width="2%"> ID </th>
                      <th width="10%"> Cadastro </th>
                    </thead>
                    <tbody>

                      {Loading ? (
                        <tr>
                          <td colSpan={10} style={{ border: '0px' }}>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                              <Spinner color="info" className="mt-5 mb-2"></Spinner>
                              <br />
                              Carregando dados...
                            </div>
                          </td>
                        </tr>
                      ) : null}

                      {!Loading && RowData.results === 0 ? (
                        <tr>
                          <td colSpan={30} style={{ border: '0px', paddingTop: '30px', textAlign: 'center' }}>
                            Sem dados com esses filtros no momento.
                          </td>
                        </tr>
                      ) : null}

                      {RowData && !Loading ? (
                        RowData.data.map((row, index) => (
                          <tr key={index}>
                            <td style={{ width: '10px', textAlign: 'center' }}>
                              <UncontrolledDropdown
                                className="me-2"
                                direction="down"
                              >
                                <DropdownToggle
                                  caret
                                  color="dark"
                                  size="sm"
                                  style={{ padding: '1px 5px' }}
                                >
                                  Acões
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem header>
                                    Selecione uma ação
                                  </DropdownItem>
                                  <DropdownItem onClick={(event) => {
                                    setModalOpen(true);
                                    setModalId(row.id);
                                  }}>
                                    Ver / Editar
                                  </DropdownItem>
                                  <DropdownItem divider />
                                  <DropdownItem onClick={(event) => {
                                    setDeleteOpen(true);
                                    setModalId(row.id);
                                  }}>
                                    Deletar
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                            <td>
                              {row.date ? DateToData(row.date, "/") : '-'}
                            </td>
                            <td>
                              {row.name ? row.name : '-'}
                            </td>
                            <td>
                              {row.yotube_url ? (
                                <>
                                  <iframe width="100%" height="90" src={"https://www.youtube.com/embed/" + row.yotube_url} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </>
                              ) : '-'}                      
                            </td>
                            <td>
                              {row.thumb ? (
                                <>
                                  <a href={'https://api.empresariossa.com/storage/images/' + row.thumb} target="_blank" rel="noreferrer">
                                    <img src={'https://api.empresariossa.com/storage/images/' + row.thumb} width="100%" style={{ border: '2px solid #ccc', borderRadius: '8px' }} />
                                  </a>
                                </>
                              ) : '-'}
                            </td>
                            <td>
                              {row.is_active == 1 ? (
                                <Badge color="success">
                                  <i class='bx bx-like'></i>
                                </Badge>
                              ) : (
                                <Badge color="danger">
                                  <i class='bx bx-dislike'></i>
                                </Badge>
                              )}
                            </td>
                            <th scope="row" style={{ width: '30px' }} >
                              {row.id ? row.id : '-'}
                            </th>
                            <td>
                              {row.created_at ? row.created_at : '-'}
                            </td>
                          </tr>
                        ))
                      ) : null}

                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col md={9}>
                <div className="TablePagination">
                  Mostrando página <strong>{PaginationPageActual}</strong> de <strong>1</strong>. | Total de registros é de <strong>{PaginationTotalItems}</strong>.
                </div>
              </Col>
              <Col md={3}>
                <FormGroup row className="text-right">
                  <Label
                    for="exampleEmail"
                    sm={4}
                  >
                    Página
                  </Label>
                  <Col sm={8}>
                    <Input
                      className=""
                      autoComplete={false}
                      name="companie_taxation"
                      type="select"
                      placeholder="Tributação"
                      onChange={(event) => { PaginationOpen(event.target.value); }}
                    >
                      {Pages.map((row) => (
                        <option key={row} value={row} > {row} </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Marketingclasses;