import axios from "axios";

const currentUrl = window.location.host;
console.log('currentUrl', currentUrl);

let baseURL = '';

if (currentUrl === "localhost:3000" || currentUrl === "localhost:3001" || currentUrl === "localhost:3002") {
  baseURL = "https://api.empresariossa.com";
}

if (currentUrl === "homo.empresariossa.com") {
  baseURL = "https://homo.api.empresariossa.com";
}

if (currentUrl === "web.empresariossa.com" || currentUrl === "app.empresariossa.com") {
  baseURL = "https://api.empresariossa.com";
}

const token = localStorage.getItem("Token");

const api = axios.create({
  baseURL: baseURL,
  timeout: 6000,
  headers: { 'Authorization': 'Bearer ' + token }
});

const handleUnauthorizedError = (error) => {
  if (error.response && error.response.status === 401) {
    window.location.href = '/login';
  }
  throw error;
};

const get = async (url) => {
  try {
    const response = await api.get(url);
    return response;
  } catch (error) {
    handleUnauthorizedError(error);
    throw error;
  }
};

const post = async (url, data) => {
  try {
    const response = await api.post(url, data);
    return response;
  } catch (error) {
    handleUnauthorizedError(error);
    throw error;
  }
};

const put = async (url, data) => {
  try {
    const response = await api.put(url, data);
    return response;
  } catch (error) {
    handleUnauthorizedError(error);
    throw error;
  }
};

const patch = async (url, data) => {
  try {
    const response = await api.patch(url, data);
    return response;
  } catch (error) {
    handleUnauthorizedError(error);
    throw error;
  }
};

const remove = async (url) => {
  try {
    const response = await api.delete(url);
    return response;
  } catch (error) {
    handleUnauthorizedError(error);
    throw error;
  }
};

export default {
  get,
  post,
  put,
  patch,
  delete: remove,
};