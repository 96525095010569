import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Form,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Label,
    Table,
    Spinner,
} from "reactstrap";
import ModalBase from '../../../components/modal/modal';
import api from "../../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    showToastArray,
} from '../../../helpers/generals';
import { MaskCellPhone, MaskCep, MaskCpf } from '../../../helpers/mascarasParaCampos';

export default function ModalUnits({
    modalId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [FormData, setFormData] = useState({});
    const [UnitsData, setUnitsData] = useState([]);
    const [Loading, setLoading] = useState(false);

    const [RowData, setRowData] = useState([]);

    const [Pages, setPages] = useState([]);
    const [PaginationTotalItems, setPaginationTotalItems] = useState(1);
    const [PaginationPageActual, setPaginationPageActual] = useState(1);

    const [ModalOpen, setModalOpen] = useState('');
    const [ModalId, setModalId] = useState(0);

    function LoadUnits() {
        setUnitsData([]);
        api.get('/api/units/active').then(response => {
            return response.data
        }).then(data => {
            if (data.success) {
                setUnitsData(data.data);
            }
        }).catch(error => {
            // console.log(error);
        })
    };

    function LoadData(Page) {

        setLoading(true);
        setRowData([]);

        try {
            setPaginationPageActual(Page);
            api.post('/api/units/active').then(response => {
                return response;
            }).then(data => {
                setRowData(data.data);
                setLoading(false);
            }).catch(error => {
                // console.log(error);
            });
        } catch (error) {
            // console.log(error);
        }
    }

    useEffect(() => {
        if (modalId > 0) {
            LoadData(1);
        }
        LoadUnits();
    }, [modalId]);



    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            units_id: FormData.units_id || '',
            is_active: 1,
        },
        validationSchema: Yup.object({
            units_id: Yup.string().required("Selecione uma unidade."),
        }),
        onSubmit: (values) => {
            setLoading(true);
            if (modalId) {
                api.post('/api/users/units/save', values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success) {
                        showToastArray("", "Sucesso", "Unidade vinculada com sucesso!");
                        setLoading(false);
                        reloadData();
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });
            }
        }
    });

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={title}
        >
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Row>
                    <Col lg="10">
                        <FormGroup floating>
                            <Input
                                id="units_id"
                                name="units_id"
                                type="select"
                                onChange={(event) => {
                                    setFormData({ ...FormData, 'units_id': event.target.value });
                                }}
                                value={FormData.tyunits_idpe ? FormData.units_id : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.units_id && validation.errors.units_id ? true : false
                                }
                            >
                                {validation.touched.units_id && validation.errors.units_id ? (
                                    <FormFeedback type="invalid">{validation.errors.units_id}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                {UnitsData.map((row, index) => (
                                    <option key={index} value={row.slug}>{row.name}</option>
                                ))}
                            </Input>
                            <Label>
                                Unidade
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col lg="2">
                        <Button type="submit" color="success" disabled={Loading ? true : false} className="btnFilter">
                            Adicionar
                        </Button>
                    </Col>
                </Row>
            </Form>
            <Row className="mt-3 mb-2">
                <Col md={12} className="text-center mt-2">
                    <div className="titulo-hr">
                        <span> Relação de unidades vinculadas </span>
                    </div>
                </Col>
                <Col md={12} className="content-box">
                    <Row className="mb-3">
                        <Col md={12}>
                            <div className="boxes">
                                <Table responsive size="sm" className="table">
                                    <thead>
                                        <th> Unidade </th>
                                        <th> CPF </th>
                                        <th> Email </th>
                                        <th> Celular </th>
                                        <th width="5%"> Unid.(s) </th>
                                        <th width="2%"> ID </th>
                                        <th width="10%"> Cadastro </th>
                                    </thead>
                                    <tbody>

                                        {/* {Loading ? (
                                            <tr>
                                                <td colSpan={10} style={{ border: '0px' }}>
                                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                                        <Spinner color="info" className="mt-5 mb-2"></Spinner>
                                                        <br />
                                                        Carregando dados...
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : null}

                                        {!Loading && RowData?.results === 0 ? (
                                            <tr>
                                                <td colSpan={30} style={{ border: '0px', paddingTop: '30px', textAlign: 'center' }}>
                                                    Sem dados com esses filtros no momento.
                                                </td>
                                            </tr>
                                        ) : null} */}

                                        {/* {RowData && !Loading ? (
                                            RowData?.data.map((row, index) => (
                                                <tr key={index}>

                                                    <td>
                                                        {row.levels ? row.levels.name : '-'}
                                                    </td>
                                                    <td>
                                                        {row.name ? row.name : '-'}
                                                    </td>
                                                    <td>
                                                        {row.cpf ? MaskCpf(row.cpf) : '-'}
                                                    </td>
                                                    <td>
                                                        {row.email ? row.email : '-'}
                                                    </td>
                                                    <td>
                                                        {row.cell_phone ? MaskCellPhone(row.cell_phone) : '-'}
                                                    </td>
                                                    <td>
                                                        <Button
                                                            type="button"
                                                            color="dark"
                                                            onClick={(event) => {
                                                                setModalOpen('add_unidade');
                                                                setModalId(row.id);
                                                            }}
                                                            size="sm">
                                                            ver
                                                        </Button>
                                                    </td>
                                                    <th scope="row" style={{ width: '30px' }} >
                                                        {row.id ? row.id : '-'}
                                                    </th>
                                                    <td>
                                                        {row.created_at ? row.created_at : '-'}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : null} */}

                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </ModalBase>
    );
}

ModalUnits.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
