import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Form,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Label,
} from "reactstrap";
import ModalBase from '../../../components/modal/modal';
import api from "../../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    MaskCellPhone,
    MaskCep,
    MaskCnpj,
    MaskCpf,
    MaskPhone,
} from '../../../helpers/mascarasParaCampos';
import {
    showToastArray,
} from '../../../helpers/generals';

export default function ModalMarketingSponsors({
    modalId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [FormData, setFormData] = useState({});
    const [Loading, setLoading] = useState(false);

    const [CountriesData, setCountriesData] = useState([]);
    const [StatesData, setStatesData] = useState([]);
    const [CitiesData, setCitiesData] = useState([])

    const LoadCountries = async => {
        setCountriesData([]);
        setStatesData([]);
        setCitiesData([]);
        api.get('/api/countries/active').then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setCountriesData(data.data);
                setLoading(false);
            }
        }).catch(error => {
            // console.log(error);
        })
    };

    function LoadStates(id) {
        setStatesData([]);
        setCitiesData([]);
        api.get('/api/states/active/countries/' + id).then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setStatesData(data.data);
            }
        }).catch(error => {
            // console.log(error);
        })
    };

    function LoadCities(id) {
        setCitiesData([]);
        api.get('/api/cities/active/states/' + id).then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setCitiesData(data.data);
            }
        }).catch(error => {
            // console.log(error);
        })
    };

    const LoadDataShow = async => {
        if (modalId > 0) {
            try {
                api.get('/api/sponsors/show/' + modalId).then(response => {
                    return response;
                }).then(data => {
                    if (data.data.success) {

                        const formData = { ...FormData };

                        if (data.data.data) {

                            const item = data.data.data;

                            formData.type = item.type;
                            formData.name = item.name;
                            formData.cpf_cnpj = item.cpf_cnpj;

                            formData.cell_phone = item.cell_phone;
                            formData.phone = item.phone;
                            formData.email = item.email;
                            formData.website = item.website;

                            formData.contractor_name = item.contractor_name;
                            formData.contractor_cpf = item.contractor_cpf;
                            formData.contractor_email = item.contractor_email;
                            formData.contractor_cell_phone = item.contractor_cell_phone;
                
                            formData.financial_name = item.financial_name;
                            formData.financial_cpf = item.financial_cpf;
                            formData.financial_email = item.financial_email;
                            formData.financial_cell_phone = item.financial_cell_phone;

                            formData.address = item.address;
                            formData.number = item.number;
                            formData.complement = item.complement;
                            formData.district = item.district;
                            formData.zip_code = item.zip_code;

                            if (item.countries) {
                                formData.countries_id = item.countries_id;
                                LoadStates(item.countries_id);
                            }
                            if (item.states) {
                                formData.states_id = item.states_id;
                                LoadCities(item.states_id);
                            }
                            if (item.cities) formData.cities_id = item.cities_id;

                            formData.is_active = item.is_active;

                        }

                        setFormData(formData);
                        setLoading(false);

                    }
                }).catch(error => {
                    // console.log(error);
                });
            } catch (error) {
                // console.log(error);
            }
        }
    };

    useEffect(() => {
        LoadCountries();
        LoadDataShow();
    }, []);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            type: FormData.type || '',
            name: FormData.name || '',
            cpf_cnpj: FormData.cpf_cnpj || '',
            phone: FormData.phone || '',
            cell_phone: FormData.cell_phone || '',
            email: FormData.email || '',
            website: FormData.website || '',

            contractor_name: FormData.contractor_name || '',
            contractor_cpf: FormData.contractor_cpf || '',
            contractor_email: FormData.contractor_email || '',
            contractor_cell_phone: FormData.contractor_cell_phone|| '',

            financial_name: FormData.financial_name || '',
            financial_cpf: FormData.financial_cpf || '',
            financial_email: FormData.financial_email || '',
            financial_cell_phone: FormData.financial_cell_phone || '',

            address: FormData.address|| '',
            number: FormData.number || '',
            complement: FormData.complement || '',
            district: FormData.district || '',
            zip_code: FormData.zip_code || '',

            countries_id: FormData.countries_id || '',
            states_id: FormData.states_id || '',
            cities_id: FormData.cities_id || '',

            is_active: FormData.is_active || '',
        },
        validationSchema: Yup.object({
            type: Yup.string().required("Selecione uma opção."),
            name: Yup.string().required("Digite esse campo."),
            // cpf_cnpj: Yup.string().required("Digite esse campo."),
            // phone: Yup.string().required("Digite esse campo."),
            cell_phone: Yup.string().required("Digite esse campo."),
            email: Yup.string().required("Digite esse campo."),
            // website: Yup.string().required("Digite esse campo."),

            financial_name: Yup.string().required("Digite esse campo."),
            // financial_cpf: Yup.string().required("Digite esse campo."),
            financial_email: Yup.string().required("Digite esse campo."),
            financial_cell_phone: Yup.string().required("Digite esse campo."),

            contractor_name: Yup.string().required("Digite esse campo."),
            // contractor_cpf: Yup.string().required("Digite esse campo."),
            contractor_email: Yup.string().required("Digite esse campo."),
            contractor_cell_phone: Yup.string().required("Digite esse campo."),

            address: Yup.string().required("Digite esse campo."),
            number: Yup.string().required("Digite esse campo."),
            complement: Yup.string().required("Digite esse campo."),
            district: Yup.string().required("Digite esse campo."),
            zip_code: Yup.string().required("Digite esse campo."),            
            
            countries_id: Yup.string().required("Selecione uma opção."),
            states_id: Yup.string().required("Selecione uma opção."),
            cities_id: Yup.string().required("Selecione uma opção."),

            is_active: Yup.string().required("Selecione uma opção."),
        }),
        onSubmit: (values) => {
            setLoading(true);
            // console.log('modalId', modalId);
            if (modalId) {
                api.put('/api/sponsors/update/' + modalId, values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success == true) {
                        showToastArray("", "Sucesso", "Cadastro atualizado com sucesso!");
                        setLoading(false);
                        reloadData();
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });
            } else {
                api.post('/api/sponsors/save', values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success == true) {
                        showToastArray("", "Sucesso", "Cadastro atualizado com sucesso!");
                        setLoading(false);
                        reloadData();
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });
            }
        },
    });

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={modalId > 0 ? 'Editar | ' + title : 'Adicionar | ' + title}
        >
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Row>

                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="is_active"
                                type="select"
                                placeholder="Situação"
                                onChange={(event) => { setFormData({ ...FormData, 'is_active': event.target.value }); }}
                                value={FormData.is_active ? FormData.is_active : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.is_active && validation.errors.is_active ? true : false
                                }
                            >
                                {validation.touched.is_active && validation.errors.is_active ? (
                                    <FormFeedback type="invalid">{validation.errors.is_active}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                <option value="1"> Ativo </option>
                                <option value="2"> Inativo  </option>
                            </Input>
                            <Label>
                                Situação
                            </Label>
                        </FormGroup>
                    </Col>

                    <Col md={12} className="text-center mt-2">
                        <div className="titulo-hr">
                            <span> Dados do registro </span>
                        </div>
                    </Col>
                    <Col sm="2">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="type"
                                type="select"
                                onChange={(event) => {
                                    setFormData({ ...FormData, 'type': event.target.value, 'cpf_cnpj': null });
                                }}
                                value={FormData.type ? FormData.type : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.type && validation.errors.type ? true : false
                                }
                            >
                                {validation.touched.type && validation.errors.type ? (
                                    <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                <option value="1"> PJ </option>
                                <option value="2"> PF  </option>
                            </Input>
                            <Label>
                                Tipo
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="7">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="name"
                                type="text"
                                maxLength={255}
                                onChange={(event) => { setFormData({ ...FormData, 'name': event.target.value }); }}
                                value={FormData.name ? FormData.name : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.name && validation.errors.name ? true : false
                                }
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                            ) : null}
                            <Label>
                                Nome
                            </Label>
                        </FormGroup>
                    </Col>
                    {FormData.type == 1 ? (
                        <Col sm="3">
                            <FormGroup floating>
                                <Input
                                    autoComplete={false}
                                    name="cpf_cnpj"
                                    type="text"
                                    onChange={(event) => { setFormData({ ...FormData, 'cpf_cnpj': MaskCnpj(event.target.value) }); }}
                                    value={FormData.cpf_cnpj ? FormData.cpf_cnpj : ''}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                        validation.touched.cpf_cnpj && validation.errors.cpf_cnpj ? true : false
                                    }
                                />
                                {validation.touched.cpf_cnpj && validation.errors.cpf_cnpj ? (
                                    <FormFeedback type="invalid">{validation.errors.cpf_cnpj}</FormFeedback>
                                ) : null}
                                <Label>
                                    CNPJ
                                </Label>
                            </FormGroup>
                        </Col>
                    ) : null}
                    {FormData.type == 2 ? (
                        <Col sm="3">
                            <FormGroup floating>
                                <Input
                                    autoComplete={false}
                                    name="cpf_cnpj"
                                    type="text"
                                    onChange={(event) => { setFormData({ ...FormData, 'cpf_cnpj': MaskCpf(event.target.value) }); }}
                                    value={FormData.cpf_cnpj ? FormData.cpf_cnpj : ''}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                        validation.touched.cpf_cnpj && validation.errors.cpf_cnpj ? true : false
                                    }
                                />
                                {validation.touched.cpf_cnpj && validation.errors.cpf_cnpj ? (
                                    <FormFeedback type="invalid">{validation.errors.cpf_cnpj}</FormFeedback>
                                ) : null}
                                <Label>
                                    CPF
                                </Label>
                            </FormGroup>
                        </Col>
                    ) : null}

                    <Col md={12} className="text-center mt-2">
                        <div className="titulo-hr">
                            <span> Dados do contratador </span>
                        </div>
                    </Col>
                    <Col sm="9">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="contractor_name"
                                type="text"
                                maxLength={255}
                                onChange={(event) => { setFormData({ ...FormData, 'contractor_name': event.target.value }); }}
                                value={FormData.contractor_name ? FormData.contractor_name : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.contractor_name && validation.errors.contractor_name ? true : false
                                }
                            />
                            {validation.touched.contractor_name && validation.errors.contractor_name ? (
                                <FormFeedback type="invalid">{validation.errors.contractor_name}</FormFeedback>
                            ) : null}
                            <Label>
                                Nome (Contratador)
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="3">
                        <FormGroup floating>
                            <Input
                                autoComplete={false}
                                name="contractor_cpf"
                                type="text"
                                onChange={(event) => { setFormData({ ...FormData, 'contractor_cpf': MaskCpf(event.target.value) }); }}
                                value={FormData.contractor_cpf ? FormData.contractor_cpf : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.contractor_cpf && validation.errors.contractor_cpf ? true : false
                                }
                            />
                            {validation.touched.contractor_cpf && validation.errors.contractor_cpf ? (
                                <FormFeedback type="invalid">{validation.errors.contractor_cpf}</FormFeedback>
                            ) : null}
                            <Label>
                                CPF (Contratador)
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="9">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="contractor_email"
                                type="email"
                                maxLength={255}
                                onChange={(event) => { setFormData({ ...FormData, 'contractor_email': event.target.value }); }}
                                value={FormData.contractor_email ? FormData.contractor_email : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.contractor_email && validation.errors.contractor_email ? true : false
                                }
                            />
                            {validation.touched.contractor_email && validation.errors.contractor_email ? (
                                <FormFeedback type="invalid">{validation.errors.contractor_email}</FormFeedback>
                            ) : null}
                            <Label>
                                E-mail (Contratador)
                            </Label>
                        </FormGroup>
                    </Col>                    
                    <Col sm="3">
                        <FormGroup floating>
                            <Input
                                autoComplete={false}
                                name="contractor_cell_phone"
                                type="text"
                                onChange={(event) => { setFormData({ ...FormData, 'contractor_cell_phone': MaskCellPhone(event.target.value) }); }}
                                value={FormData.contractor_cell_phone ? FormData.contractor_cell_phone : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.contractor_cell_phone && validation.errors.contractor_cell_phone ? true : false
                                }
                            />
                            {validation.touched.contractor_cell_phone && validation.errors.contractor_cell_phone ? (
                                <FormFeedback type="invalid">{validation.errors.contractor_cell_phone}</FormFeedback>
                            ) : null}
                            <Label>
                                Celular (Contratador)
                            </Label>
                        </FormGroup>
                    </Col>

                    <Col md={12} className="text-center mt-2">
                        <div className="titulo-hr">
                            <span> Dados do financeiro </span>
                        </div>
                    </Col>   
                    <Col sm="9">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="financial_name"
                                type="text"
                                maxLength={255}
                                onChange={(event) => { setFormData({ ...FormData, 'financial_name': event.target.value }); }}
                                value={FormData.financial_name ? FormData.financial_name : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.financial_name && validation.errors.financial_name ? true : false
                                }
                            />
                            {validation.touched.financial_name && validation.errors.financial_name ? (
                                <FormFeedback type="invalid">{validation.errors.financial_name}</FormFeedback>
                            ) : null}
                            <Label>
                                Nome (Financeiro)
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="3">
                        <FormGroup floating>
                            <Input
                                autoComplete={false}
                                name="financial_cpf"
                                type="text"
                                onChange={(event) => { setFormData({ ...FormData, 'financial_cpf': MaskCpf(event.target.value) }); }}
                                value={FormData.financial_cpf ? FormData.financial_cpf : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.financial_cpf && validation.errors.financial_cpf ? true : false
                                }
                            />
                            {validation.touched.financial_cpf && validation.errors.financial_cpf ? (
                                <FormFeedback type="invalid">{validation.errors.financial_cpf}</FormFeedback>
                            ) : null}
                            <Label>
                                CPF (Financeiro)
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="9">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="financial_email"
                                type="email"
                                maxLength={255}
                                onChange={(event) => { setFormData({ ...FormData, 'financial_email': event.target.value }); }}
                                value={FormData.financial_email ? FormData.financial_email : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.financial_email && validation.errors.financial_email ? true : false
                                }
                            />
                            {validation.touched.financial_email && validation.errors.financial_email ? (
                                <FormFeedback type="invalid">{validation.errors.financial_email}</FormFeedback>
                            ) : null}
                            <Label>
                                E-mail (Financeiro)
                            </Label>
                        </FormGroup>
                    </Col>                    
                    <Col sm="3">
                        <FormGroup floating>
                            <Input
                                autoComplete={false}
                                name="financial_cell_phone"
                                type="text"
                                onChange={(event) => { setFormData({ ...FormData, 'financial_cell_phone': MaskCellPhone(event.target.value) }); }}
                                value={FormData.financial_cell_phone ? FormData.financial_cell_phone : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.financial_cell_phone && validation.errors.financial_cell_phone ? true : false
                                }
                            />
                            {validation.touched.financial_cell_phone && validation.errors.financial_cell_phone ? (
                                <FormFeedback type="invalid">{validation.errors.financial_cell_phone}</FormFeedback>
                            ) : null}
                            <Label>
                                Celular (Financeiro)
                            </Label>
                        </FormGroup>
                    </Col>                                     

                    <Col md={12} className="text-center mt-2">
                        <div className="titulo-hr">
                            <span> Dados de contato </span>
                        </div>
                    </Col>
                    <Col sm="3">
                        <FormGroup floating>
                            <Input
                                autoComplete={false}
                                name="phone"
                                type="text"
                                onChange={(event) => { setFormData({ ...FormData, 'phone': MaskPhone(event.target.value) }); }}
                                value={FormData.phone ? FormData.phone : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.phone && validation.errors.phone ? true : false
                                }
                            />
                            {validation.touched.phone && validation.errors.phone ? (
                                <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                            ) : null}
                            <Label>
                                Telefone
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="3">
                        <FormGroup floating>
                            <Input
                                autoComplete={false}
                                name="cell_phone"
                                type="text"
                                onChange={(event) => { setFormData({ ...FormData, 'cell_phone': MaskCellPhone(event.target.value) }); }}
                                value={FormData.cell_phone ? FormData.cell_phone : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.cell_phone && validation.errors.cell_phone ? true : false
                                }
                            />
                            {validation.touched.cell_phone && validation.errors.cell_phone ? (
                                <FormFeedback type="invalid">{validation.errors.cell_phone}</FormFeedback>
                            ) : null}
                            <Label>
                                Celular
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="email"
                                type="email"
                                maxLength={255}
                                onChange={(event) => { setFormData({ ...FormData, 'email': event.target.value }); }}
                                value={FormData.email ? FormData.email : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.email && validation.errors.email ? true : false
                                }
                            />
                            {validation.touched.email && validation.errors.email ? (
                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                            <Label>
                                E-mail
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="website"
                                type="text"
                                maxLength={255}
                                onChange={(event) => { setFormData({ ...FormData, 'website': event.target.value }); }}
                                value={FormData.website ? FormData.website : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.website && validation.errors.website ? true : false
                                }
                            />
                            {validation.touched.website && validation.errors.website ? (
                                <FormFeedback type="invalid">{validation.errors.website}</FormFeedback>
                            ) : null}
                            <Label>
                                Website
                            </Label>
                        </FormGroup>
                    </Col>

                    <Col md={12} className="text-center mt-2">
                        <div className="titulo-hr">
                            <span> Dados de endereço </span>
                        </div>
                    </Col>
                    <Col sm="9">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="address"
                                type="text"
                                maxLength={255}
                                onChange={(event) => { setFormData({ ...FormData, 'address': event.target.value }); }}
                                value={FormData.address ? FormData.address : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.address && validation.errors.address ? true : false
                                }
                            />
                            {validation.touched.address && validation.errors.address ? (
                                <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                            ) : null}
                            <Label>
                                Endereço
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="3">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="number"
                                type="text"
                                maxLength={25}
                                onChange={(event) => { setFormData({ ...FormData, 'number': event.target.value }); }}
                                value={FormData.number ? FormData.number : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.number && validation.errors.number ? true : false
                                }
                            />
                            {validation.touched.number && validation.errors.number ? (
                                <FormFeedback type="invalid">{validation.errors.number}</FormFeedback>
                            ) : null}
                            <Label>
                                Número
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="district"
                                type="text"
                                placeholder="Bairro"
                                onChange={(event) => { setFormData({ ...FormData, 'district': event.target.value }); }}
                                value={FormData.district ? FormData.district : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.district && validation.errors.district ? true : false
                                }
                            />
                            {validation.touched.district && validation.errors.district ? (
                                <FormFeedback type="invalid">{validation.errors.district}</FormFeedback>
                            ) : null}
                            <Label>
                                Bairro
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="complement"
                                type="text"
                                maxLength={25}
                                placeholder="Complemento"
                                onChange={(event) => { setFormData({ ...FormData, 'complement': event.target.value }); }}
                                value={FormData.complement ? FormData.complement : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.complement && validation.errors.complement ? true : false
                                }
                            />
                            {validation.touched.complement && validation.errors.complement ? (
                                <FormFeedback type="invalid">{validation.errors.complement}</FormFeedback>
                            ) : null}
                            <Label>
                                Complemento
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="zip_code"
                                type="text"
                                onChange={(event) => { setFormData({ ...FormData, 'zip_code': MaskCep(event.target.value) }); }}
                                value={FormData.zip_code ? FormData.zip_code : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.zip_code && validation.errors.zip_code ? true : false
                                }
                            />
                            {validation.touched.zip_code && validation.errors.zip_code ? (
                                <FormFeedback type="invalid">{validation.errors.zip_code}</FormFeedback>
                            ) : null}
                            <Label>
                                CEP
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col lg={4}>
                        <FormGroup floating>
                            <Input
                                id="countries_id"
                                className="countries_id"
                                name="countries_id"
                                type="select"
                                onChange={(event) => {
                                    setFormData({ ...FormData, 'countries_id': event.target.value });
                                    LoadStates(event.target.value);
                                }}
                                value={FormData.countries_id ? FormData.countries_id : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.countries_id && validation.errors.countries_id ? true : false
                                }
                            >
                                {validation.touched.countries_id && validation.errors.countries_id ? (
                                    <FormFeedback type="invalid">{validation.errors.countries_id}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                {CountriesData.map((row, index) => (
                                    <option key={index} value={row.id}>{row.name}</option>
                                ))}
                            </Input>
                            <Label>
                                País
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col lg={4}>
                        <FormGroup floating>
                            <Input
                                disabled={FormData.countries_id && CountriesData.length > 0 ? false : true}
                                id="states_id"
                                name="states_id"
                                type="select"
                                onChange={(event) => {
                                    setFormData({ ...FormData, 'states_id': event.target.value });
                                    LoadCities(event.target.value);
                                }}
                                value={FormData.states_id ? FormData.states_id : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.states_id && validation.errors.states_id ? true : false
                                }
                            >
                                {validation.touched.states_id && validation.errors.states_id ? (
                                    <FormFeedback type="invalid">{validation.errors.states_id}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                {StatesData.map((row, index) => (
                                    <option key={index} value={row.id}>{row.name}</option>
                                ))}
                            </Input>
                            <Label>
                                Estado
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col lg={4}>
                        <FormGroup floating>
                            <Input
                                disabled={FormData.states_id && StatesData.length > 0 ? false : true}
                                id="cities_id"
                                name="cities_id"
                                type="select"
                                onChange={(event) => { setFormData({ ...FormData, 'cities_id': event.target.value }); }}
                                value={FormData.cities_id ? FormData.cities_id : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.cities_id && validation.errors.cities_id ? true : false
                                }
                            >
                                {validation.touched.cities_id && validation.errors.cities_id ? (
                                    <FormFeedback type="invalid">{validation.errors.cities_id}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                {CitiesData.map((row, index) => (
                                    <option key={index} value={row.id}>{row.name}</option>
                                ))}
                            </Input>
                            <Label>
                                Cidade
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mt-3 mb-2">
                    <Col xs="6">
                        <Button type="submit" color="success" disabled={Loading ? true : false}>
                            {modalId > 0 ? 'Atualizar' : 'Adicionar'}
                        </Button>
                    </Col>
                    <Col xs="6" className="text-right pb-2">
                        <button type="button" className="btn btn-secondary" onClick={toggle} disabled={Loading ? true : false}>Fechar</button>
                    </Col>
                </Row>
            </Form>
        </ModalBase>
    );
}

ModalMarketingSponsors.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
